import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";

const QuickSearchInput: React.FC<{
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <InputGroup w={"fit-content"} minW={'200px'}>
      <Input
        maxWidth={"252px"}
        borderRadius={"500px"}
        placeholder={t("quick_search_place_holder")}
        focusBorderColor={"#202951"}
        onChange={onChange}
      />
      <InputLeftElement children={<BsSearch width={"16px"} />} />
    </InputGroup>
  );
};

export default QuickSearchInput;
