import { IResponse } from "../types";
import axios from "./";

const ResponseService = {
  saveResponse: async (newResponse: Partial<IResponse>) =>
    (await axios.post("response", newResponse)).data,

  getById: async (id: IResponse["id"]): Promise<IResponse> =>
    (await axios.get(`response/${id}`)).data,

  updateResponse: async (newResponse: Partial<IResponse>) =>
    (await axios.put(`response/${newResponse.id}`, newResponse)).data,

  delete: async (id: string) => (await axios.delete(`response/${id}`)).data,

  getResponsesBySubmiterAndSite: async (
    submitter: IResponse["submitter"],
    research_site_id: IResponse["research_site_id"]
  ): Promise<IResponse[]> =>
    (await axios.get(`response/${submitter}/${research_site_id}`)).data,
};

export default ResponseService;
