import { Center, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledTable, StyledTableContainer } from "../styles";
import { saveAs } from "file-saver";
import { format, isValid } from "date-fns";
import { enUS } from "date-fns/locale";
import useDeviceDetection from "../../../hooks/IsMobile";

const formatDate = (date: Date | undefined) => {
  if (date && isValid(date)) {
    return format(date, "dd MMM yyyy", { locale: enUS });
  }
  return "";
};

const HeadquartersChart: React.FC<{
  data: Array<Array<any>>;
  exportData: boolean;
  title?: string;
}> = ({ data, exportData, title }) => {
  const { t } = useTranslation();
  const { isDesktop } = useDeviceDetection();

  useEffect(() => {
    if (exportData) {
      handleExportData();
    }
  }, [exportData]);

  const handleExportData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      data
        .map((row, rowIndex) => {
          if (rowIndex === 0) {
            const headerOne = row.reduce(
              (acc: string, item: string, colIndex: number): string => {
                if (colIndex === 0) return "-";
                return `${acc},${item},${item}`;
              },
              ""
            );

            const headerTwo = row.reduce(
              (acc: string, item: string, colIndex: number): string => {
                if (colIndex === 0) return "-";
                return `${acc},Count,Sum`;
              },
              ""
            );

            return `${headerOne}\n${headerTwo}`;
          }

          return row.reduce(
            (acc: string, item: any, colIndex: number): string => {
              if (colIndex === 0) {
                return item;
              }
              return `${acc},${item.count},${item.sum}`;
            },
            ""
          );
        })
        .join("\n");

    const footer = totalTagCount?.reduce(
      (acc, _, index) =>
        index === 0
          ? t("report_grand_sumary")
          : `${acc}, ${totalTagCount[index]}, ${totalTagSum[index]}`,
      ""
    );

    saveAs(
      `${csvContent}\n${footer}`,
      `${title} -  ${formatDate(new Date())}.csv`
    );
  };

  const totalTagCount: number[] = data[0]?.map((_, indexCol): number => {
    if (indexCol == 0) return 0;

    return data.reduce(
      (acc, row, indexRow) =>
        indexRow == 0 ? 0 : acc + parseInt(row[indexCol].count),
      0
    );
  });

  const totalTagSum: number[] = data[0]?.map((_, indexCol): number => {
    if (indexCol == 0) return 0;

    return data.reduce(
      (acc, row, indexRow) =>
        indexRow == 0 ? 0 : acc + parseInt(row[indexCol].sum),
      0
    );
  });

  const renderHead = (row: any[]) => {
    return (
      <thead>
        <tr>
          {row?.map((col, indexCol) =>
            indexCol === 0 ? (
              <th rowSpan={2} style={{ textAlign: "left" }} key={indexCol}>
                Tags
              </th>
            ) : (
              <th colSpan={2} key={indexCol}>
                {col}
              </th>
            )
          )}
        </tr>
        <tr>
          {row?.map(
            (_, indexCol) =>
              indexCol !== 0 && (
                <>
                  <th>{t("report_tag_counter")}</th>
                  <th>{t("report_tag_sum")}</th>
                </>
              )
          )}
        </tr>
      </thead>
    );
  };

  const renderBody = (row: any[]) => (
    <tr>
      {row?.map((col: any, indexCol) =>
        indexCol === 0 ? (
          <td style={{ textAlign: "left" }}>{col}</td>
        ) : (
          <>
            <td>{col.count === "0" ? "-" : col.count}</td>
            <td>{col.sum === "0" ? "-" : col.sum}</td>
          </>
        )
      )}
    </tr>
  );

  const renderFooter = () => (
    <tfoot>
      <tr>
        {totalTagCount?.map((_, index) =>
          index === 0 ? (
            <td>{t("report_grand_sumary")}</td>
          ) : (
            <>
              <td>{totalTagCount[index]}</td>
              <td>{totalTagSum[index]}</td>
            </>
          )
        )}
      </tr>
    </tfoot>
  );

  return (
    <StyledTableContainer isDesktop={isDesktop}>
      {JSON.stringify(data) === `[[""]]` ? (
        <Center h={"400px"} w={"100%"}>
          <Text color={"black"} fontSize={"16px"} fontWeight={500}>
            {t("report_data_not_found")}
          </Text>
        </Center>
      ) : (
        <StyledTable style={{ width: "100%", textAlign: "center" }}>
          {renderHead(data[0])}

          <tbody>
            {data?.map(
              (row: any[], indexRow) => indexRow !== 0 && renderBody(row)
            )}
          </tbody>

          {renderFooter()}
        </StyledTable>
      )}
    </StyledTableContainer>
  );
};

export default HeadquartersChart;
