import { Button, Center, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { format, isValid } from "date-fns";
import { enUS } from "date-fns/locale";
import { Props } from "./types";
import { BsPlusLg } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const formatDate = (date: Date | undefined) => {
  if (date && isValid(date)) {
    return format(date, "dd MMM yyyy", { locale: enUS });
  }
  return "";
};

const AllCategoriesVisualizer: React.FC<Props> = ({
  categories,
  projects,
  onCreateCategory,
  onSelectCategory,
}) => {
  const { t } = useTranslation();
  return (
    <VStack w={"100%"} alignItems={"flex-start"} overflow={"auto"}>
      <Text fontWeight={600} fontSize={"24px"} color={"#000000"} mb={"24px"}>
        {t("category_all_categories_first_text")}
      </Text>

      <HStack
        w={"100%"}
        py={"11px !important"}
        borderBottom={"1px solid"}
        borderColor={"container_border_color"}
      >
        <Text fontWeight={500} fontSize={"16px"} flex={1} color={"#000000"}>
          {t("category_all_categories_column_project")}
        </Text>

        <Text fontWeight={500} fontSize={"16px"} flex={1} color={"#000000"}>
          {t("category_all_categories_column_name")}
        </Text>

        <Text fontWeight={500} fontSize={"16px"} flex={1} color={"#000000"}>
          {t("category_all_categories_column_created_at")}
        </Text>
      </HStack>

      {categories.map((category, index) => (
        <HStack
          py={"24px"}
          w={"100%"}
          borderBottom={"1px solid"}
          borderColor={
            categories.length === index + 1
              ? "transparent"
              : "container_border_color"
          }
          position={"relative"}
        >
          <Text fontWeight={400} fontSize={"16px"} flex={1} color={"#000000"}>
            {projects.find((project) => project?.id === category?.project_id)
              ?.name || ""}
          </Text>
          <Text fontWeight={400} fontSize={"16px"} flex={1} color={"#000000"}>
            {category.name}
          </Text>
          <Text fontWeight={400} fontSize={"16px"} flex={1} color={"#000000"}>
            {formatDate(new Date(category.createdAt))}
          </Text>

          <Button
            position={"absolute"}
            right={"8px"}
            height={"32px"}
            variant={"solid_filter"}
            onClick={() => onSelectCategory(category)}
          >
            {t("category_all_categories_button_edit")}
          </Button>
        </HStack>
      ))}
      <VStack w={"100%"} alignItems={"center"} py={"24px"} m={"0px !important"}>
        <VStack
          padding={"8px"}
          borderRadius={"12px"}
          border={"1px solid"}
          borderColor={"#F0F1F4"}
          cursor={"pointer"}
          onClick={() => onCreateCategory({})}
        >
          <Center
            w={"38px"}
            h={"38px"}
            borderRadius={"50%"}
            background={"#F0F1F4"}
          >
            <Icon as={BsPlusLg} />
          </Center>
          <Text fontWeight={500} fontSize={"16px"} flex={1} color={"#000000"}>
            {t("category_all_categories_button_add_more_categories")}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default AllCategoriesVisualizer;
