import styled from "styled-components";

export const StyledTableContainer = styled.div<{ isDesktop: boolean }>`
  display: flex;
  position: relative;
  max-width: ${(props) =>
    props.isDesktop ? "calc(100vw - 400px)" : "initial"};
  width: 100%;
  overflow: auto;
`;

export const StyledTable = styled.table`
    width: 100%;
    
    tr {
        height: 32px;
    }

    th {
        font-weight: 600;
    }

    th, td {
        border: 0.5px solid #F0F3F5;
        font-size: 14px;
        padding: 8px;
    }

    td {
        min-width: 100px;
    }

    tfoot {
        background-color: rgba(0, 0, 0, 0.1);
        font-weight
    }
`;
