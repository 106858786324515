import { Center, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfinitySpin } from "react-loader-spinner";
import { HeatTable } from "../../../components/HeatTable";
import PageTitle from "../../../components/pageTitle";
import { UserContext } from "../../../providers/contexts/user";
import ReportService from "../../../services/report.service";

const TagCloudPage: React.FC = () => {
  const { t } = useTranslation();
  const { projectFiltered, setProjectFiltered } = useContext(UserContext);
  const [reportValues, setReportValues] = useState<any[]>([]);
  const [selectedSiteTag, setSelectedSiteTag] = useState<any[]>([]);
  const [selectedResearch, setSelectedResearch] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const call = async () => {
    setIsLoading(true);
    const response = await ReportService.getTagCloud(selectedSiteTag, {
      project_id: projectFiltered,
      research_id: selectedResearch,
      question_id: selectedQuestion

    });
    setReportValues(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    call();
  }, [projectFiltered, selectedResearch, selectedSiteTag, selectedQuestion]);

  return (
    <VStack w={"100%"} height={"100%"}>
      <PageTitle />

      <Stack mt={"40px !important"} />
      <Stack position={"relative"} h={"100%"} w={"100%"}>
        <VStack
          border={"1px solid "}
          borderColor={"container_border_color"}
          background={"white"}
          padding={"24px"}
          borderRadius={"12px"}
          width={"100%"}
          position={"absolute"}
          h={"100%"}
        >
          {isLoading ? (
            <Center h={"400px"}>
              <VStack>
                <InfinitySpin color="#202951" />
                <Text color={"black"} fontSize={"16px"} fontWeight={500}>
                  {t("category_x_tag_loading")}
                </Text>
              </VStack>
            </Center>
          ) : (
            <HeatTable
              data={reportValues}
              onSelectProject={setProjectFiltered}
              selectedProject={projectFiltered}
              onSelectResearch={setSelectedResearch}
              selectedResearch={selectedResearch}
              onSelectSiteTag={setSelectedSiteTag}
              selectedSiteTag={selectedSiteTag}
              onSelectTag={setSelectedQuestion}
              selectedTag={selectedQuestion}
              title={'TagCloud'}
              isTagCloud
            />
          )}
        </VStack>
      </Stack>
    </VStack>
  );
};

export default TagCloudPage;
