import { Button, useToast, VStack, Center, Text, Box } from "@chakra-ui/react";
import { useState } from "react";
import PageTitle from "../../../components/pageTitle";
import TableWrapper from "../../../components/TableWrapper";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { InfinitySpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ResponseForm from "./ResponseForm";
import { IAttachment, IResponse } from "../../../types";
import ResponseService from "../../../services/response.service";
import AttachmentService from "../../../services/attachment.service";
import * as _ from "radash";

const ResponsesPage = () => {
  const { t } = useTranslation();
  const [currentResponse, setCurrentResponse] = useState<IResponse>();

  const fieldsAvaibles = [
    { value: "text", label: t("responses_fields_column_short_text") },
    { value: "research_site", label: "Site name" },
    { value: "submitter", label: t("responses_fields_column_submitter") },
    { value: "is_profund", label: t("responses_fields_column_is_profund") },
    { value: "priority", label: t("responses_fields_column_short_priority") },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const attachmentsToDelete =
        currentResponse?.attachments.filter(
          (attachment) =>
            !values.attachments.find(
              (newAttachment: IAttachment) => newAttachment.id === attachment.id
            )
        ) || [];

      await ResponseService.updateResponse({
        ..._.omit(values, [
          "research_site",
          "question_identifier",
          "attachments",
        ]),
        ...(values.avaibleResearchSites && {
          research_site_id: values.avaibleResearchSites.value,
        }),
        ...(values.questionSelected && {
          question_identifier_id: values.questionSelected.value,
        }),
        priority: values.priority.value,
      });

      await Promise.all(
        attachmentsToDelete.map(
          async (attachment) => await AttachmentService.delete(attachment.id)
        )
      );

      await Promise.all(
        values.attachments.map(async (attachment: IAttachment | Blob) => {
          if (attachment instanceof Blob) {
            const form = new FormData();
            form.append("file", attachment);
            await AttachmentService.saveAttachment(values.id, form);
          }
        })
      );
    } catch (err) {
      toast({
        title: t("responses_response_data_error_title"),
        description: t("responses_response_data_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setCurrentResponse(undefined);
    setIsLoading(false);
  };

  const onDelete = async (id: IResponse["id"]) => {
    setIsLoading(true);
    try {
      await ResponseService.delete(id);
    } catch (err) {
      toast({
        title: t("responses_response_data_error_title"),
        description: t("responses_response_data_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setCurrentResponse(undefined);
    setIsLoading(false);
  };

  return (
    <VStack w={"100%"} height={"100%"}>
      <PageTitle>
        {currentResponse && (
          <Button
            onClick={() => setCurrentResponse(undefined)}
            leftIcon={<HiOutlineArrowSmLeft />}
            variant={"outline"}
          >
            {t("projects_button_back")}
          </Button>
        )}
      </PageTitle>

      <VStack w={"100%"} height={"100%"} position={"relative"}>
        <Box
          w={"100%"}
          maxHeight={currentResponse ? 0 : "100%"}
          overflow={currentResponse ? "hidden" : "none"}
        >
          <TableWrapper
            endpoint="/response"
            name="Responses"
            fieldsAvaible={fieldsAvaibles}
            currentData={currentResponse}
            onEdit={setCurrentResponse}
          />
        </Box>

        {isLoading ? (
          <Center
            h={"600px"}
            w={"100%"}
            position={"absolute"}
            zIndex={"2"}
            background={"white"}
            border={"1px solid"}
            borderColor={"container_border_color"}
            borderRadius={"8px"}
          >
            <VStack>
              <InfinitySpin color="#202951" />
              <Text color={"black"} fontSize={"16px"} fontWeight={500}>
                {t("responses_handling_loading")}
              </Text>
            </VStack>
          </Center>
        ) : (
          <ResponseForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            currentResponse={currentResponse}
          />
        )}
      </VStack>
    </VStack>
  );
};

export default ResponsesPage;
