const ptTranslation = {
  page_in_development: "Esta página ainda está em desenvolvimento. =)",
  on_copy_qr_code_title: "codigo qr url copiado!",
  choose_a_project_label: "Escolha um projeto",
  loading: "Carregando...",

  /* Reports */
  category_x_tag_loading: "Espere, estamos carregando seu gráfico.",
  report_data_not_found: "Nenhum dado foi encontrado para esse gráfico.",
  report_tag_counter: "Tag (Cont)",
  report_tag_sum: "Tag (Ponderado)",
  report_grand_sumary: "Total",
  all_questions_placeholder: "Todas as questões",
  all_sites_placeholder: "Todos os sites deste projeto.",
  all_sites_descriptors_placeholder: "Todas as descrições de sites",

  /* Fields avaible filter */
  project_fields_column_name: "Nome",
  project_fields_column_active: "Está ativo?",
  project_fields_column_short_name: "Nome abreviado",

  responses_fields_column_submitter: "Apresentador",
  responses_fields_column_is_profund: "É profunda?",
  responses_fields_column_short_priority: "Priridade",
  responses_fields_column_short_text: "Resposta",

  table_label_not_found_data: "Nenhum dado encontrado.",
  table_button_export: "Exportar",
  table_wrapper_edit: "Editar",
  table_button_edit: "Editar",

  /* Base components */
  quick_search_place_holder: "Busca rápida",
  paginate_next: "Próximo",
  paginate_previous: "Anterior",

  /* Login Screen */
  login_screen_secondary_text: "Entre com suas credenciais em sua conta.",
  login_screen_email_placeholder: "Email",
  login_screen_password_placeholder: "Senha",
  login_screen_language_placeholder: "Linguagem",
  login_screen_button_placeholder: "Entrar",

  /* Nav Bar */
  nav_bar_dashboard: "Painel de controle",
  nav_bar_world_cafe: "WORLD CAFÉ",
  nav_bar_world_cafe_secondary: "World Café",
  nav_bar_projects: "Projetos",
  nav_bar_response_data: "Dados de resposta",
  nav_bar_categories: "Atribuir Categorias",
  nav_bar_reports: "RELATÓRIOS",
  nav_bar_reports_secondary: "Relatórios",
  nav_bar_tags: "Tags",
  nav_bar_categories_x_tags: "Categorias x Tags",
  nav_bar_site_descriptors_x_tags: "Site Descriptors x Tags",
  nav_bar_tag_cloud: "Tag Cloud",
  nav_bar_user: "",
  nav_bar_user_secondary: "Usuário",
  nav_bar_settings: "Ajustes",
  nav_bar_logout: "Sair",

  /* Projeto */
  projects_button_back: "Voltar",
  projects_button_create: "Criar projeto",
  projects_handling_loading: "Aguarde, estamos cuidando do seu projeto.",
  projects_form_button_delete: "Excluir projeto",
  projects_form_button_save: "Salvar alterações",

  projects_form_initial_data_label_input_name: "Nome",
  projects_form_initial_data_label_input_short_name: "Nome abreviado",
  projects_form_initial_data_label_input_short_coordinator: "Coordenador",
  projects_form_initial_data_label_input_short_status: "Ativo",
  projects_form_initial_data_label_region: "Nome do site",
  projects_research_site_site_placeholder_site_name: "Nome do site",
  projects_research_site_site_placeholder_coordinator: "Coordenador",
  projects_research_site_site_placeholder_address: "Endereço",
  projects_research_site_site_placeholder_region: "Site descriptors",
  projects_research_site_site_placeholder_language: "Linguagem",
  projects_form_initial_data_label_qr_code_copy_URL: "Copy URL",
  projects_form_initial_data_label_created: "Criado",
  projects_form_initial_data_label_site: "S. descriptors",
  projects_form_initial_data_label_qr_code:
    "Você precisa primeiro gerar o projeto e suas regiões para obter o QR Code.",
  projects_form_initial_data_label_project_color: "Cor do projeto",
  projects_form_initial_data_place_holder_input_name: "Nome do projeto",
  projects_form_initial_data_place_holder_input_coordinator:
    "Coordenador do projeto",
  projects_form_initial_data_place_holder_input_short_name:
    "Nome abreviado do projeto",
  projects_form_initial_data_button_yes: "Sim",
  projects_form_initial_data_button_no: "Não",

  projects_details_title_project_details: "Detalhes do projeto",
  projects_details_label_project_description: "Descrição",
  projects_details_label_project_notes: "Notas",

  projects_question_identifier_title_questions: "Perguntas",
  projects_question_identifier_button_create_question: "Criar pergunta",
  projects_question_identifier_label_column_question: "Pergunta",
  projects_question_identifier_button_edit_question: "Editar",
  projects_question_identifier_button_delete_question: "Excluir",
  projects_question_identifier_label_questions_not_found:
    "Nenhuma pergunta registrada até agora.",
  projects_question_identifier_label_questions_not_found_in_search:
    "Nenhuma pergunta encontrada para esta pesquisa.",
  projects_question_identifier_question_label_create_question: "Criar pergunta",
  projects_question_identifier_question_label_edit_question: "Editar pergunta",
  projects_question_identifier_question_label_no_languages_designed:
    "Nenhum idioma projetado até agora.",
  projects_question_identifier_question_label_add_language:
    "Adicionar em outro idioma",
  projects_question_identifier_question_label_add_first_language:
    "Adicione seu primeiro idioma",
  projects_research_site_title_site: "Sites de pesquisa",
  projects_research_site_button_create_site: "Criar site",
  projects_research_site_label_column_site: "Site",
  projects_research_site_label_column_lang: "Linguagem",
  projects_research_site_label_column_coordinator: "Coordenador",
  projetos_research_site_button_edit: "Editar",
  projects_research_site_button_delete: "Excluir",
  projects_research_site_label_not_found:
    "Nenhum site registrado até o momento.",
  projects_research_site_label_not_found_in_search:
    "Nenhum site encontrado para esta pesquisa.",

  projects_research_site_site_label_create_site: "Criar Site",
  projects_research_site_site_label_edit_site: "Editar site",
  projects_research_site_site_label_save: "Salvar",

  errors_empty_fields_title: "Erro de campos vazios.",
  errors_empty_fields_description:
    "Não é possível salvar uma resposta com campos vazios.",
  errors_add_language: "Você precisa adicionar pelo menos um idioma.",
  errors_name: "Você precisa adicionar pelo menos um nome.",
  errors_coordinator: "Você precisa adicionar pelo menos um coordenador.",
  errors_region: "Você precisa adicionar pelo menos uma região.",
  errors_address: "Você precisa adicionar pelo menos um endereço.",
  errors_language: "Você precisa adicionar pelo menos uma linguagem.",
  errors_empty_field: "Esse campo não pode ser vazio.",
  errors_different_passwords: "As senhas precisam ser iguais.",
  errors_invalid_email: "O E-mail não é válido.",

  /* Responses */
  responses_handling_loading: "Aguarde, estamos processando sua resposta.",
  responses_response_data_error_title: "Erro de resposta",
  responses_response_data_error_description:
    "Ocorreu um erro ao tentar processar a resposta.",

  responses_form_button_delete: "Excluir resposta",
  responses_form_button_save: "Salvar resposta",

  responses_response_details_project_label: "Projeto",
  responses_response_details_question_label: "Pergunta",
  responses_response_details_response_label: "Resposta",
  responses_response_details_notes_label: "Notas",
  responses_response_details_tags_label: "Etiquetas",

  responses_response_initial_data_priority_low: "Baixo",
  responses_response_initial_data_priority_medium: "Médio",
  responses_response_initial_data_priority_high: "Alto",

  responses_response_initial_data_label_project: "Projeto",
  responses_response_initial_data_label_submitter: "Enviador",
  responses_response_initial_data_label_site: "Site",
  responses_response_initial_data_label_priority: "Prioridade",
  responses_response_initial_data_label_is_profund: "Importante",

  responses_attachments_label_media: "Mídia",
  responses_attachments_label_add_image: "Adicionar imagem",
  responses_attachments_label_images: "imagens",

  /* Settings */
  table_user_error_title: "Erro de usuário",
  table_user_error_description:
    "Ocorreu um erro ao buscar os dados de usuário.",

  password_changed_with_succes: "Senha alterada com sucesso.",
  password_changed_with_error: "A Senha antiga está errada.",

  user_name: "Nome",
  user_email: "Email",
  user_created_at: "Criado em",
  user_create: "Criar Usuário",

  edit_user_option_user: "Usuário",
  edit_user_option_admin: "Administrador",

  edit_user_placeholder_name: "Nome",
  edit_user_placeholder_password: "Senha",
  edit_user_placeholder_repassword: "Confirmação de senha",
  edit_user_placeholder_email: "Email",
  edit_user_placeholder_profile_code: "Função",
  edit_user_placeholder_profile_is_active: "Está ativo?",

  edit_user_button_delete_user: "Deletar usuário",
  edit_user_button_update_user: "Atualizar usuário",
  edit_user_button_create_user: "Criar usuário",

  change_password_label_old_password: "Senha antiga",
  change_password_placeholder_old_password: "Senha",

  change_password_label_new_password: "Nova senha",
  change_password_placeholder_new_password: "Senha",

  change_password_text_min_caracters: "No minímo 8 caracters.",

  change_password_label_repassword: "Confirmação de senha",
  change_password_placeholder_repassword: "Senha",

  change_password_button_change: "Alterar senha",

  settings_nav_item_password: "Senha",
  settings_nav_item_users: "Usuários",

  /* Categories */
  category_create_success: "Uma nova categoria foi criada!",
  category_create_error: "Ocorreu um erro ao criar a categoria.",
  category_back_button: "Voltar",
  category_edit_category_button: "Editar categorias",

  category_table_column_project: "Questão",
  category_table_column_catName: "Nome da categoria",
  category_table_column_tag: "Tag",
  category_table_column_response: "Resposta",

  category_error_table_data_title: "Erro de categorias",
  category_error_table_data_description:
    "Ocorreu um erro ao obter os dados da categoria.",

  category_table_column_modal_text_response: "Resposta",
  category_table_column_modal_text_active_project: "Questão",
  category_table_column_modal_text_category_name: "Nome da categoria",

  category_error_table_data_response_title: "Erro de resposta.",
  category_error_table_data_response_description:
    "Erro interno do servidor ao tentar atualizar a resposta.",

  category_uncategorized: "Sem categoria",
  category_table_tag_title: "Tags",
  category_table_tag_placeholder: "Tags",

  category_table_tag_view_response: "Editar resposta",
  category_table_button_save: "Salvar",

  category_data_error_title: "Erro de categoria",
  category_data_error_description:
    "Erro interno do servidor ao tentar manipular categorias.",

  categorias_not_found: "Nenhuma categoria encontrada.",

  categories_category_view_empty_text: "Selecione uma categoria para ver aqui.",

  category_category_view_label_project: "Projeto",
  category_category_view_placeholder_project: "Projeto",

  category_category_view_label_name: "Nome",
  category_category_view_placeholder_name: "Nome da categoria",

  category_category_view_button_delete: "Excluir categoria",
  category_category_view_button_save: "Salvar Categoria",

  category_all_categories_first_text: "Categorias criadas",
  category_all_categories_column_project: "Projeto",
  category_all_categories_column_name: "Nome",
  category_all_categories_column_created_at: "Criado em",

  category_all_categories_button_edit: "Editar",
  category_all_categories_button_add_more_categories:
    "Adicionar mais uma categoria",

  category_all_categories_text_creation: "Criação de categoria",
  category_all_categories_text_edit: "Edição de categoria",
  category_table_tag_view_response_show_more: "Mostrar mais",
  category_table_tag_view_response_show_less: "Mostrar menos",
};

export default ptTranslation;
