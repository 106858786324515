import { HStack, VStack, Text, Input } from "@chakra-ui/react";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InitialDataFormValues, Props } from "./types";
import CustomFieldError from "../../../../../components/base/CustomFieldError";
import TextArea from "./TextArea";
import TagSelect from "../../../../../components/TagSelect";
import i18next from "../../../../../i18n";
import Select from "react-select";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: (base: any) => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    cursor: "pointer",
    minWidth: "102px",
    background: "white",
    width: "100%",
    borderRadius: "6px",
  }),
  container: (base: any) => ({
    ...base,
    width: "100%",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

type forcedProps = {
  control: Control<InitialDataFormValues, any>;
  values: () => InitialDataFormValues;
  defaultTags: Props["defaultTags"];
};

function capitalizeFirstLetter(string: string) {
  return (string.charAt(0).toUpperCase() + string.slice(1)).trim();
}

const ResponseDetail: React.FC<Props> = ({
  values,
  control,
  defaultTags,
}: forcedProps) => {
  const currentLanguage = i18next.languages[0];
  const correctQuestions: any[] = (
    values() as any
  ).question_identifier?.project?.questions_identifier?.map(
    (questionIdentifer: any) => ({
      ...questionIdentifer,
      content:
        questionIdentifer?.content?.filter(
          (question: any) => question?.lang === currentLanguage
        ) ||
        questionIdentifer?.content?.filter(
          (question: any) => question?.is_main
        ),
    })
  );

  const { t } = useTranslation();

  return (
    <HStack
      width={"100%"}
      spacing={"35px"}
      alignItems={"flex-start"}
      minHeight={"fit-content"}
      overflow={"auto"}
      padding={"24px"}
    >
      <VStack width={"100%"}>
        <Controller
          name={"questionSelected"}
          control={control}
          rules={{ required: true }}
          render={({
            field: { onBlur, onChange, value },
            formState: { errors },
          }) => (
            <HStack w={"100%"} alignItems={"center"} mb={"24px !important"}>
              <HStack minW={"100px"} mr={"32px !important"}>
                <CustomFieldError haveError={!!errors.text} />
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"black"}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                  ml={"auto !important"}
                >
                  {t("responses_response_details_question_label")}
                </Text>
              </HStack>

              <Select
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                options={correctQuestions?.map((IQuestion) => ({
                  value: IQuestion?.id,
                  label:
                    IQuestion?.content[0]?.title ||
                    "Not registred in your language",
                }))}
                onChange={(opt) => {
                  onChange(opt);
                }}
                styles={customStyles as any}
                placeholder={"Sorting"}
                isClearable={false}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={value}
              />
            </HStack>
          )}
        />

        <HStack alignItems={"center"} justifyContent={"flex-end"} w={"100%"}>
          <Controller
            name={"text"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <HStack w={"100%"} alignItems={"flex-start"}>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.text} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("responses_response_details_response_label")}
                  </Text>
                </HStack>
                <TextArea
                  placeholder={t(
                    "projects_form_initial_data_place_holder_input_name"
                  )}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </HStack>
            )}
          />
        </HStack>

        <Controller
          name={"notes"}
          control={control}
          rules={{ required: false }}
          render={({
            field: { onBlur, onChange, value },
            formState: { errors },
          }) => (
            <HStack w={"100%"} alignItems={"center"} mt={"24px !important"}>
              <HStack minW={"100px"} mr={"32px !important"}>
                <CustomFieldError haveError={!!errors.text} />
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"black"}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                  ml={"auto !important"}
                >
                  {t("responses_response_details_notes_label")}
                </Text>
              </HStack>
              <Input
                placeholder={t("responses_response_details_notes_label")}
                value={value || ""}
                onChange={onChange}
                onBlur={onBlur}
                height={"32px"}
                focusBorderColor={"#F0F3F5"}
              />
            </HStack>
          )}
        />
        <Controller
          name={"responseTags"}
          control={control}
          rules={{ required: true }}
          render={({
            field: { onBlur, onChange, value },
            formState: { errors },
          }) => (
            <HStack w={"100%"} alignItems={"center"} mt={"24px !important"}>
              <HStack minW={"100px"} mr={"32px !important"}>
                <CustomFieldError haveError={!!errors.text} />
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"black"}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                  ml={"auto !important"}
                >
                  {t("responses_response_details_tags_label")}
                </Text>
              </HStack>
              <TagSelect
                title={t("responses_response_details_tags_label")}
                isMulti
                options={defaultTags.map((val) => ({
                  label: val.name,
                  value: val.name,
                }))}
                placeholder={t("responses_response_details_tags_label")}
                value={value}
                onChange={(event) => {
                  onChange(
                    event.map(
                      (tag: { label: string; value: string; isOld: true }) => ({
                        label: capitalizeFirstLetter(tag.label),
                        value: tag.value,
                        isOld: tag.isOld,
                      })
                    )
                  );
                }}
              />
            </HStack>
          )}
        />
      </VStack>
    </HStack>
  );
};

export default ResponseDetail;
