import { HStack, Icon } from "@chakra-ui/react";
import Select from "react-select";

import React from "react";
import i18next, { resources } from "../../i18n";
import { BsGlobe } from "react-icons/bs";

const LanguageSelect: React.FC = () => {
  const resourcesList: any = Object.keys(resources).map((val) => ({
    value: val,
    label: val.toUpperCase(),
  }));
  const currentLanguage = i18next.languages[0];

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: "pointer",
      background: state.isFocused ? "#000000" : "transparent",
      color: state.isFocused ? "#ffffff" : "#000000",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      cursor: "pointer",
      borderColor: "none !important",
      borderWidth: "none !important",
      boxShadow: "none !important",
    }),
  };

  return (
    <HStack>
      <Icon as={BsGlobe} size={"20px"} />
      <Select
        styles={customStyles}
        defaultValue={{
          value: currentLanguage,
          label: currentLanguage.toUpperCase(),
        }}
        options={resourcesList}
        onChange={(event) => {
          i18next.changeLanguage(event?.value);
        }}
      />
    </HStack>
  );
};

export default LanguageSelect;
