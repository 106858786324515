import { HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import NavItems from "../navbar/consts";
import { Props } from "./types";

const PageTitle: React.FC<Props> = ({ title, children }: Props) => {
  const location = useLocation();
  const { t } = useTranslation();

  const navigationItems = NavItems.reduce((acc, item) => {
    if (location.pathname.includes(item.route)) {
      acc.push(item);

      if (item.subItems) {
        acc.push(
          item.subItems.find(
            (value) => item.route + value.route === location.pathname
          )
        );
      }
    }
    return acc;
  }, [] as any[]);

  return (
    <VStack w={"100%"} alignItems={"start"}>
      <HStack>
        {navigationItems.map((item: typeof NavItems[0], index) => (
          <Text
            key={index}
            fontWeight={400}
            fontSize={"16px"}
            color={"black_secondary"}
            margin={"0px !important"}
          >
            {item.secondaryLabel
              ? t(`nav_bar_${item.secondaryLabel}`)
              : t(`nav_bar_${item.label}`)}
            {navigationItems.length !== index + 1 && ` / `}
          </Text>
        ))}
      </HStack>
      <HStack w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Text fontWeight={600} fontSize={"40px"} color={"black"}>
          {title ||
            t(
              `nav_bar_${
                navigationItems.find(
                  (item, index) =>
                    navigationItems[index - 1]?.route + item.route ===
                    location.pathname
                )?.label
              }`
            )}
        </Text>
        {children}
      </HStack>
    </VStack>
  );
};

export default PageTitle;
