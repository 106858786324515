import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  Icon,
  Center,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Props } from "./types";
import QuestionForm from "./QuestionForm";
import { IQuestion } from "../../../../../../types";
import { TbPlus } from "react-icons/tb";
import { FiAlertCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { resources } from "../../../../../../i18n";

const avaibleLanguages = Object.keys(resources).map((key) => ({
  value: key,
  label: resources[key].label,
}));

const QuestionIdentifierForm: React.FC<Props> = ({
  currentQuestion,
  getValues,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [fieldsError, setFieldsError] = useState<
    | {
        input: boolean;
        select: boolean;
      }
    | undefined
  >(undefined);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: useMemo(() => {
      return { ...currentQuestion };
    }, [currentQuestion]),
  });

  useEffect(() => {
    if (currentQuestion) {
      reset(currentQuestion);

      if (JSON.stringify(currentQuestion) === "{}") {
        reset({});
      }
    }
  }, [currentQuestion, reset]);

  const thisSubmit = (values: any) => {
    values.content[0].is_main = true;
    values.content.forEach((content: IQuestion, index: number) => {
      if (index !== 0) values.content[index].is_main = false;
      if (values.id) values.content[index].identifier_id = values.id;

      if (!content.title || !content.lang) {
        toast({
          title: t("errors_empty_fields_title"),
          description: t("errors_empty_fields_description"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error(t("errors_empty_fields_title"));
      }
    });

    onSubmit(
      { ...values, ...(getValues()?.id && { project_id: getValues().id }) },
      (currentQuestion as any)?.index
    );
  };

  return (
    <Modal isOpen={!!currentQuestion} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {JSON.stringify(currentQuestion) === "{}"
            ? t("projects_question_identifier_question_label_create_question")
            : t("projects_question_identifier_question_label_edit_question")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Controller
            name={"content"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <VStack w={"100%"}>
                {value ? (
                  <>
                    {value?.map((question, index) => (
                      <QuestionForm
                        currentQuestion={{ ...question, index }}
                        isPrimary={value.length === index + 1}
                        fieldsError={fieldsError}
                        isFirst={index === 0}
                        onDelete={(index) => {
                          value.splice(index, 1);
                          onChange(value);
                        }}
                        avaibleLanguages={avaibleLanguages.filter(
                          (language) =>
                            !value.find(
                              (languageSelected, lIndex) =>
                                languageSelected.lang === language.value &&
                                question.lang !== languageSelected.lang
                            )
                        )}
                        handleChange={(newQuestion: IQuestion) => {
                          let hasError = {
                            select: fieldsError?.select,
                            input: fieldsError?.input,
                          };

                          if (newQuestion.title && fieldsError?.input) {
                            hasError = {
                              input: false,
                              select: hasError["select"],
                            };
                            setFieldsError(hasError as any);
                          }

                          if (newQuestion.lang && fieldsError?.select) {
                            hasError = {
                              input: hasError["input"],
                              select: false,
                            };
                            setFieldsError(hasError as any);
                          }

                          value[index] = newQuestion;
                          onChange(value);
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <HStack w={"100%"} alignItems={"center"}>
                    <Text
                      fontSize={"14px"}
                      fontWeight={500}
                      color={"black"}
                      m={"0px !important"}
                    >
                      {t(
                        "projects_question_identifier_question_label_no_languages_designed"
                      )}
                    </Text>
                  </HStack>
                )}

                <HStack
                  mt={"16px !important"}
                  w={"100%"}
                  alignItems={"center"}
                  onClick={() => {
                    const newArr = value || [];
                    let hasError = {
                      select: fieldsError?.select,
                      input: fieldsError?.select,
                    };
                    if (value && !newArr[newArr?.length - 1]?.title) {
                      hasError = {
                        input: true,
                        select: hasError["select"],
                      };
                      setFieldsError(hasError as any);
                    }

                    if (value && !newArr[newArr?.length - 1]?.lang) {
                      hasError = {
                        input: hasError["input"],
                        select: true,
                      };
                      setFieldsError(hasError as any);
                    }

                    if (value && (hasError["input"] || hasError["select"]))
                      return;

                    newArr?.push({} as any);
                    onChange(newArr);
                  }}
                  cursor={"pointer"}
                >
                  <Center
                    width={"32px"}
                    height={"32px"}
                    borderRadius={"16px"}
                    mr={"8px !important"}
                    background={"container_border_color"}
                  >
                    <Icon as={TbPlus} w={"14px"} />
                  </Center>

                  <Text
                    fontSize={"14px"}
                    fontWeight={400}
                    color={"black"}
                    m={"0px !important"}
                  >
                    {value
                      ? t(
                          "projects_question_identifier_question_label_add_language"
                        )
                      : t(
                          "projects_question_identifier_question_label_add_first_language"
                        )}
                  </Text>
                </HStack>

                {errors.content && (
                  <HStack mt={"16px !important"} w={"100%"}>
                    <Icon
                      as={FiAlertCircle}
                      color={"delete_color"}
                      w={"14px"}
                      mr={"8px !important"}
                    />
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"delete_color"}
                      m={"0px !important"}
                    >
                      {t("errors_add_language")}
                    </Text>
                  </HStack>
                )}
              </VStack>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit(thisSubmit)}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuestionIdentifierForm;
