const enTranslation = {
  page_in_development: "This page is still under development. =)",
  on_copy_qr_code_title: "Qrcode url copied!",
  choose_a_project_label: "Choose a project",
  loading: "Loading...",

  /* Reports */
  category_x_tag_loading: "Wait, we're loading your chart.",
  report_data_not_found: "No data was found for this chart.",
  report_tag_counter: "Tag (Count)",
  report_tag_sum: "Tag (Weighted)",
  report_grand_sumary: "Total",
  all_questions_placeholder: "All Questions",
  all_sites_placeholder: "All Sites to this project",
  all_sites_descriptors_placeholder: "All sites descriptors",

  /* Table wrapper */
  project_fields_column_name: "Name",
  project_fields_column_active: "Active",
  project_fields_column_short_name: "Short Name",

  responses_fields_column_submitter: "Submitter",
  responses_fields_column_is_profund: "Is profund",
  responses_fields_column_short_priority: "Priority",
  responses_fields_column_short_text: "Response",

  table_label_not_found_data: "No data found.",
  table_button_export: "Export",
  table_button_edit: "Edit",

  /* Base components */
  quick_search_place_holder: "Quick Search",
  paginate_next: "Next",
  paginate_previous: "Back",

  /* Login Screen */
  login_screen_secondary_text: "Enter your credentials to access your account.",
  login_screen_email_placeholder: "Email",
  login_screen_password_placeholder: "Password",
  login_screen_language_placeholder: "Language",
  login_screen_button_placeholder: "Login",

  /* Nav Bar */
  nav_bar_dashboard: "Dashboard",
  nav_bar_world_cafe: "WORLD CAFÉ",
  nav_bar_world_cafe_secondary: "World Café",
  nav_bar_projects: "Projects",
  nav_bar_response_data: "Response Data",
  nav_bar_categories: "Assign Categories",
  nav_bar_reports: "REPORTS",
  nav_bar_reports_secondary: "Reports",
  nav_bar_tags: "Tags",
  nav_bar_categories_x_tags: "Categories x Tags",
  nav_bar_site_descriptors_x_tags: "Site Descriptors x Tags",
  nav_bar_tag_cloud: "Tag Cloud",
  nav_bar_user: "",
  nav_bar_user_secondary: "User",
  nav_bar_settings: "Settings",
  nav_bar_logout: "Log out",

  /* Project */
  projects_button_back: "Back",
  projects_button_create: "Create project",
  projects_handling_loading: "Please wait, we are handling your project.",
  projects_form_button_delete: "Delete project",
  projects_form_button_save: "Save changes",

  projects_form_initial_data_label_input_name: "Name",
  projects_form_initial_data_label_input_short_name: "Short Name",
  projects_form_initial_data_label_input_short_coordinator: "Coordinator",
  projects_form_initial_data_label_input_short_status: "Active",
  projects_form_initial_data_label_region: "Site name",
  projects_research_site_site_placeholder_site_name: "Site name",
  projects_research_site_site_placeholder_coordinator: "Coordinator",
  projects_research_site_site_placeholder_address: "Address",
  projects_research_site_site_placeholder_region: "Site descriptors",
  projects_research_site_site_placeholder_language: "Language",

  projects_form_initial_data_label_qr_code_copy_URL: "Copy URL",
  projects_form_initial_data_label_created: "Created",
  projects_form_initial_data_label_site: "Site descriptors",
  projects_form_initial_data_label_qr_code:
    "You need to first generate the project and its regions to get Qr Code.",
  projects_form_initial_data_label_project_color: "Project Color",

  projects_form_initial_data_place_holder_input_name: "Project name",
  projects_form_initial_data_place_holder_input_coordinator:
    "Project coordinator",
  projects_form_initial_data_place_holder_input_short_name:
    "Project short name",
  projects_form_initial_data_button_yes: "Yes",
  projects_form_initial_data_button_no: "No",

  projects_details_title_project_details: "Project details",
  projects_details_label_project_description: "Description",
  projects_details_label_project_notes: "Notes",

  projects_question_identifier_title_questions: "Questions",
  projects_question_identifier_button_create_question: "Create question",
  projects_question_identifier_label_column_question: "Question",
  projects_question_identifier_button_edit_question: "Edit",
  projects_question_identifier_button_delete_question: "Delete",
  projects_question_identifier_label_questions_not_found:
    "No questions registered so far.",
  projects_question_identifier_label_questions_not_found_in_search:
    "No questions found for this search.",

  projects_question_identifier_question_label_create_question:
    "Create question",
  projects_question_identifier_question_label_edit_question: "Edit question",
  projects_question_identifier_question_label_no_languages_designed:
    "No languages designated so far.",
  projects_question_identifier_question_label_add_language:
    "Add in another language",
  projects_question_identifier_question_label_add_first_language:
    "Add your first language",

  projects_research_site_title_site: "Research sites",
  projects_research_site_button_create_site: "Create site",
  projects_research_site_label_column_site: "Site",
  projects_research_site_label_column_lang: "Language",
  projects_research_site_label_column_coordinator: "Coordinator",
  projects_research_site_button_edit: "Edit",
  projects_research_site_button_delete: "Delete",
  projects_research_site_label_not_found: "No sites registered so far.",
  projects_research_site_label_not_found_in_search:
    "No sites found for this search.",

  projects_research_site_site_label_create_site: "Create Site",
  projects_research_site_site_label_edit_site: "Edit site",
  projects_research_site_site_label_save: "Save",

  errors_empty_fields_title: "Empty fields error.",
  errors_empty_fields_description:
    "It is not possible to save an answer with empty fields.",
  errors_add_language: "You need to add at least one language.",
  errors_name: "You need to add at least one name.",
  errors_coordinator: "You need to add at least one coordinator.",
  errors_region: "You need to add at least one region.",
  errors_address: "You need to add at least one address.",
  errors_language: "You need to add at least one language.",
  errors_empty_field: "This field cannot be null.",
  errors_different_passwords: "The password needs to be equals.",
  errors_invalid_email: "The E-mail is invalid.",

  /* Responses */
  responses_handling_loading: "Please wait, we are handling your response.",
  responses_response_data_error_title: "Response error",
  responses_response_data_error_description:
    "An error occurred while trying to handle the response.",

  responses_form_button_delete: "Delete response",
  responses_form_button_save: "Save response",

  responses_response_details_project_label: "Project",
  responses_response_details_question_label: "Question",
  responses_response_details_response_label: "Response",
  responses_response_details_notes_label: "Notes",
  responses_response_details_tags_label: "Tags",

  responses_response_initial_data_priority_low: "Low",
  responses_response_initial_data_priority_medium: "Medium",
  responses_response_initial_data_priority_high: "High",

  responses_response_initial_data_label_project: "Project",
  responses_response_initial_data_label_submitter: "Submitter",
  responses_response_initial_data_label_site: "Site",
  responses_response_initial_data_label_priority: "Priority",
  responses_response_initial_data_label_is_profund: "Important",

  responses_attachments_label_media: "Media",
  responses_attachments_label_add_image: "Add image",
  responses_attachments_label_images: "images",

  /* Settings */
  table_user_error_title: "User Error",
  table_user_error_description:
    "An error occurred while trying to fetch the user data.",

  table_user_handling_error_title: "Error handling user",
  table_user_handling_error_description:
    "An error occurred while trying to handling the user.",

  password_changed_with_succes: "Password changed successfully!",
  password_changed_with_error: "Old password wrong",

  user_name: "Name",
  user_email: "Email",
  user_created_at: "Created at",
  user_create: "Create user",

  edit_user_option_user: "User",
  edit_user_option_admin: "Admin",

  edit_user_placeholder_name: "Name",
  edit_user_placeholder_password: "Password",
  edit_user_placeholder_repassword: "Confirm password",
  edit_user_placeholder_email: "Email",
  edit_user_placeholder_profile_code: "Function",
  edit_user_placeholder_profile_is_active: "Active",

  edit_user_button_delete_user: "Delete User",
  edit_user_button_update_user: "Update User",
  edit_user_button_create_user: "Create User",

  change_password_label_old_password: "Old password",
  change_password_placeholder_old_password: "Password",

  change_password_label_new_password: "New password",
  change_password_placeholder_new_password: "Password",

  change_password_text_min_caracters: "Minimum 8 characters",

  change_password_label_repassword: "Confirm password",
  change_password_placeholder_repassword: "Password",

  change_password_button_change: "Change password",

  settings_nav_item_password: "Password",
  settings_nav_item_users: "Users",

  /* Categories */
  category_create_success: "A new category has been created.",
  category_create_error: "An error occurred while creating the category.",

  category_back_button: "Back",
  category_edit_category_button: "Edit categories",

  category_table_column_project: "Question",
  category_table_column_catName: "Category name",
  category_table_column_tag: "Tag",
  category_table_column_response: "Response",

  category_error_table_data_title: "Categories Error",
  category_error_table_data_description:
    "An error occurred while getting the category data.",

  category_table_column_modal_text_response: "Response",
  category_table_column_modal_text_active_project: "Question",
  category_table_column_modal_text_category_name: "Category name",

  category_error_table_data_response_title: "Response error.",
  category_error_table_data_response_description:
    "Internal server error when try update response.",

  category_uncategorized: "Uncategorized",
  category_table_tag_title: "Tags",
  category_table_tag_placeholder: "Tags",

  category_table_tag_view_response: "Edit Response",
  category_table_button_save: "Save",

  category_data_error_title: "Category error",
  category_data_error_description:
    "Internal server error when try handling categories.",

  categories_not_found: "No categories found.",

  categories_category_view_empty_text: "Select a category to view here.",

  category_category_view_label_project: "Project",
  category_category_view_placeholder_project: "Project",

  category_category_view_label_name: "Name",
  category_category_view_placeholder_name: "Category name",

  category_category_view_button_delete: "Delete category",
  category_category_view_button_save: "Save Category",

  category_all_categories_first_text: "Created categories",
  category_all_categories_column_project: "Project",
  category_all_categories_column_name: "Name",
  category_all_categories_column_created_at: "Crated at",

  category_all_categories_button_edit: "Edit",
  category_all_categories_button_add_more_categories: "Add one more category",

  category_all_categories_text_creation: "Category creation",
  category_all_categories_text_edit: "Category editing",

  category_table_tag_view_response_show_more: "Show more",
  category_table_tag_view_response_show_less: "Show less",
};

export default enTranslation;
