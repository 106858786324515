import { Button, Center, HStack, Input, Text, VStack } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomFieldError from "../../../../../../components/base/CustomFieldError";
import Select from "react-select";
import { Props } from "./types";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: (base: any) => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    cursor: "pointer",
    minWidth: "102px",
    background: "white",
    width: "100%",
    borderRadius: "6px",
  }),
  container: (base: any) => ({
    ...base,
    width: "100%",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

const CategoryView: React.FC<Props> = ({
  currentCategory,
  onDeleteCategory,
  onSaveCategory,
  projects,
}) => {
  const { t } = useTranslation();
  const SelectProjectData = projects.map((project) => ({
    label: project.name,
    value: project.id,
  }));

  const { control, handleSubmit, reset } = useForm({
    defaultValues: useMemo(() => {
      return { ...currentCategory, projectSelected: {} };
    }, [currentCategory]),
  });

  useEffect(() => {
    if (currentCategory) {
      if ("id" in currentCategory) {
        reset({
          ...currentCategory,
          projectSelected: SelectProjectData.find(
            (selected) => selected.value === currentCategory.project_id
          ),
        });
      } else {
        reset({});
      }
    }
  }, [currentCategory, reset]);

  return (
    <VStack w={"100%"}>
      {!currentCategory ? (
        <Center>
          <Text>{t("categories_category_view_empty_text")}</Text>
        </Center>
      ) : (
        <VStack w={"100%"}>
          <Text fontWeight={600} fontSize={"24px"} color={"#000000"}>
            {"id" in currentCategory
              ? t("category_all_categories_text_edit")
              : t("category_all_categories_text_creation")}
          </Text>
          <HStack w={"100%"} mt={"24px !important"}>
            <Controller
              name={"projectSelected"}
              control={control}
              rules={{ required: false }}
              render={({
                field: { onBlur, onChange, value },
                formState: { errors },
              }) => (
                <>
                  <HStack minW={"100px"} mr={"32px !important"}>
                    <CustomFieldError haveError={!!errors.projectSelected} />
                    <Text
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"black"}
                      whiteSpace={"nowrap"}
                      textAlign={"right"}
                      ml={"auto !important"}
                    >
                      {t("category_category_view_label_project")}
                    </Text>
                  </HStack>

                  <Select
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    options={SelectProjectData}
                    onChange={onChange}
                    styles={customStyles as any}
                    placeholder={t(
                      "category_category_view_placeholder_project"
                    )}
                    isClearable={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={value}
                  />
                </>
              )}
            />
          </HStack>

          <HStack w={"100%"} mt={"24px !important"}>
            <Controller
              name={"name"}
              control={control}
              rules={{ required: true }}
              render={({
                field: { onBlur, onChange, value },
                formState: { errors },
              }) => (
                <>
                  <HStack minW={"100px"} mr={"32px !important"}>
                    <CustomFieldError haveError={!!errors.projectSelected} />
                    <Text
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"black"}
                      whiteSpace={"nowrap"}
                      textAlign={"right"}
                      ml={"auto !important"}
                    >
                      {t("category_category_view_label_name")}
                    </Text>
                  </HStack>
                  <Input
                    placeholder={t("category_category_view_placeholder_name")}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    height={"32px"}
                    focusBorderColor={"#F0F3F5"}
                  />
                </>
              )}
            />
          </HStack>
          <HStack mt={"32px !important"} spacing={"16px"}>
            {"id" in currentCategory && (
              <Button
                height={"32px"}
                variant={"solid_delete"}
                onClick={() => onDeleteCategory(currentCategory.id)}
              >
                {t("category_category_view_button_delete")}
              </Button>
            )}
            <Button height={"32px"} onClick={handleSubmit(onSaveCategory)}>
              {t("category_category_view_button_save")}
            </Button>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};

export default CategoryView;
