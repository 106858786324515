import styled from "styled-components";

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 232px;
  border: 1px solid #f0f3f5;
  border-radius: 4px;
  outline: none;
  resize: none;
  padding: 8px 16px;
`;

export default StyledTextArea;
