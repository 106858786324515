import { Button, Center, Text, VStack } from "@chakra-ui/react";
import LoggedOutHeader from "../../components/loggedOutHeader";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../components/base/Input";
import { useTranslation } from "react-i18next";
import { SelectWModal } from "../../components/base/Select";
import i18next, { resources } from "../../i18n";
import useDeviceDetection from "../../hooks/IsMobile";
import { useContext, useState } from "react";
import { UserContext } from "../../providers/contexts/user";
import { InfinitySpin } from "react-loader-spinner";

const LoginPage: React.FC = () => {
  const { login } = useContext(UserContext);
  const { isMobile } = useDeviceDetection();
  const { control, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const resourcesList: any = Object.keys(resources).map((val) => ({
    value: val,
    label: resources[val].label,
  }));

  const handleSubmitLogin = async (formValues: any) => {
    setIsLoading(true);
    await login({ email: formValues.email, password: formValues.password });
    setIsLoading(false);
  };

  return (
    <VStack
      width={"100%"}
      background={"white"}
      borderRadius={"16px"}
      height={"100%"}
    >
      <LoggedOutHeader />
      <VStack
        padding={isMobile ? "24px 18px" : "64px 80px"}
        width={"100%"}
        alignItems={"flex-start"}
        height={"100%"}
      >
        <Text fontSize={"28px"} fontWeight={600} color={"black"}>
          Log in
        </Text>
        <Text
          fontSize={"16px"}
          fontWeight={400}
          color={"black_secondary"}
          mb={"48px !important"}
        >
          {t("login_screen_secondary_text")}
        </Text>
        {isLoading ? (
          <Center flex={isMobile ? 1 : "initial"} width={"100%"}>
            <InfinitySpin width="200" color="#202951" />
          </Center>
        ) : (
          <VStack flex={isMobile ? 1 : "initial"} width={"100%"}>
            <Controller
              name={"email"}
              control={control}
              rules={{ required: true }}
              render={({
                field: { onBlur, onChange, value },
                formState: { errors },
              }) => (
                <Input
                  placeholder={t("login_screen_email_placeholder")}
                  hasError={!!errors.email}
                  errorMessage={""}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"56px"}
                />
              )}
            />

            <Controller
              name={"password"}
              control={control}
              rules={{ required: true }}
              render={({
                field: { onBlur, onChange, value },
                formState: { errors },
              }) => (
                <Input
                  type={"password"}
                  placeholder={t("login_screen_password_placeholder")}
                  hasError={!!errors.password}
                  errorMessage={""}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"56px"}
                  marginTop={"24px !important"}
                />
              )}
            />

            <SelectWModal
              mt={"24px !important"}
              itemSelected={{
                label: resourcesList.find(
                  (item: any) => item.value === i18next.languages[0]
                ).label,
                value: i18next.languages[0],
              }}
              items={resourcesList}
              SelectItem={(item) => {
                i18next.changeLanguage(item?.value);
              }}
              placeholder={t("login_screen_language_placeholder")}
            />
          </VStack>
        )}

        <Button
          onClick={handleSubmit(handleSubmitLogin)}
          width={"100%"}
          mt={"48px !important"}
        >
          {t("login_screen_button_placeholder")}
        </Button>
      </VStack>
    </VStack>
  );
};

export default LoginPage;
