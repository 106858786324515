import _axios from ".";
import { IProject } from "../types";

const ProjectService = {
  getProjectsWithFilter: (onlyActive: boolean = true) =>
    _axios.get(`project?isActive=${onlyActive}`),

  create: (newProject?: Partial<IProject>) =>
    _axios.post("project", newProject),

  update: (projectEdited?: Partial<IProject>) =>
    _axios.patch("project", projectEdited),

  delete: (projectId?: IProject["id"]) => _axios.delete(`project/${projectId}`),
};

export default ProjectService;
