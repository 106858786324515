import React from "react";
import { Props } from "./types";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";

const TagSelect: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: "pointer",
      background: state.isFocused ? "#202951" : "white",
      color: state.isFocused ? "white" : "#000000",
    }),
    control: (base: any) => ({
      display: "flex",
      border: "1px solid #F0F3F5",
      cursor: "pointer",
      minWidth: "102px",
      background: "white",
      width: "100%",
      borderRadius: "6px",
    }),
    valueContainer: (base: any) => ({
      ...base,
      flexWrap: "nowrap !important",
    }),
    container: (base: any) => ({
      ...base,
      width: "100%",
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };

  return (
    <CreatableSelect
      {...props}
      value={props.value}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      styles={customStyles}
    />
  );
};

export default TagSelect;
