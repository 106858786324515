import { Box, Center } from "@chakra-ui/react";
import React, { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import useDeviceDetection from "../hooks/IsMobile";
import { UserContext } from "../providers/contexts/user";

type Props = {
  children: ReactNode;
};

const LoggedOutRoute: React.FC<Props> = ({ children }: Props) => {
  const { user } = useContext(UserContext);
  const { isMobile } = useDeviceDetection();

  if (user) {
    return <Navigate to={`/overview/projects`} />;
  }

  return (
    <>
      {isMobile ? (
        children
      ) : (
        <Center height={"100%"} background={"#FAFAFA"}>
          <Box
            w={"518px"}
            background={"#FFFFFF"}
            borderRadius={"16px"}
            boxShadow="0px 0px 10px rgba(29, 34, 31, 0.1)"
          >
            {children}
          </Box>
        </Center>
      )}
    </>
  );
};

export default LoggedOutRoute;
