import {
  Button,
  Center,
  HStack,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ICategory } from "../../../../../types";
import TagSelect from "../../../../../components/TagSelect";
import { Props } from "./types";
import ResponseService from "../../../../../services/response.service";
import * as _ from "lodash";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import useDeviceDetection from "../../../../../hooks/IsMobile";
import CreatableSelect from "react-select/creatable";
import CategoryService from "../../../../../services/category.service";

function capitalizeFirstLetter(string: string) {
  return (string.charAt(0).toUpperCase() + string.slice(1)).trim();
}

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: (base: any) => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    cursor: "pointer",
    minWidth: "102px",
    background: "white",
    width: "100%",
    borderRadius: "6px",
  }),
  container: (base: any) => ({
    ...base,
    width: "100%",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

const RowForm: React.FC<Props> = ({
  setAvaibleCategories,
  populateResponses,
  avaibleCategories,
  responseData,
  avaibleTags,
  project_id,
}) => {
  const categoriesSelectData = (
    avaibleCategories[project_id as any] as ICategory[]
  )?.map((cat) => ({
    value: cat.id,
    label: cat.name,
  }));

  const tagSelectData = avaibleTags
    .filter((tag) => tag.project_id === project_id)
    .map((val) => ({
      label: val.name,
      value: val.name,
    }));

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [showAllResponse, setShowAllResponse] = useState(false);
  const { isDesktop } = useDeviceDetection();

  const onSubmit = async (formValues: any) => {
    setIsLoading(true);
    try {
      await ResponseService.updateResponse({
        ..._.omit(formValues, [
          "attachments",
          "newCategory",
          "question_identifier",
          "research_site",
          "category",
        ]),
        category_id: formValues?.newCategory?.value,
      });
    } catch (err) {
      toast({
        title: t("category_error_table_data_response_title"),
        description: t("category_error_table_data_response_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    populateResponses();
    setIsLoading(false);
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty, isSubmitted },
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        ...responseData,
        newCategory: {
          value: categoriesSelectData?.find(
            (cat) => cat?.value === responseData?.category_id
          )?.value,
          label:
            categoriesSelectData?.find(
              (cat) => cat?.value === responseData?.category_id
            )?.label || t("category_uncategorized"),
        },
        responseTags:
          responseData.responseTags?.map((responseTag: any) => ({
            value: responseTag.id,
            label: responseTag.tag.name,
            isOld: true,
          })) || [],
      };
    }, [responseData, categoriesSelectData]),
  });

  return (
    <HStack
      key={responseData.id}
      w={"100%"}
      padding={"20px 24px"}
      position={"relative"}
      alignItems={"center"}
    >
      {isLoading ? (
        <Center w={"100%"} maxH={"32px"}>
          <InfinitySpin color="#202951" />
        </Center>
      ) : (
        <HStack w={"100%"} alignItems={"flex-start"}>
          <VStack width={"40%"} overflow={"hidden"} alignItems={"flex-start"}>
            <VStack
              maxH={showAllResponse ? "100%" : "60px"}
              overflow={"hidden"}
              w={"100%"}
              alignItems={"flex-start"}
            >
              <Text
                maxW={"90%"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                margin={"0px !important"}
                fontSize={"14px"}
                fontWeight={500}
                color={"#5C5F70"}
              >
                {getValues().text}
              </Text>
            </VStack>
            <HStack mt={"14px !important"}>
              {((!isDesktop && getValues().text.length >= 100) ||
                (isDesktop && getValues().text.length >= 280)) && (
                <Button
                  maxW={"140px"}
                  height={"32px"}
                  variant={"solid_filter"}
                  onClick={() => setShowAllResponse(!showAllResponse)}
                >
                  {showAllResponse
                    ? t("category_table_tag_view_response_show_less")
                    : t("category_table_tag_view_response_show_more")}
                </Button>
              )}
              <Button
                maxW={"140px"}
                height={"32px"}
                variant={"solid_filter"}
                onClick={() => navigate(`/overview/response/${getValues().id}`)}
              >
                {t("category_table_tag_view_response")}
              </Button>

              {isDirty && !isSubmitted && (
                <Button
                  minW={"42px"}
                  height={"32px"}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("category_table_button_save")}
                </Button>
              )}
            </HStack>
          </VStack>

          <Controller
            name={"newCategory"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <Stack width={"30%"}>
                <Stack maxW={"80%"}>
                  <CreatableSelect
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    options={categoriesSelectData}
                    onChange={onChange}
                    styles={customStyles as any}
                    placeholder={"Sorting"}
                    isClearable={false}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={value}
                    onCreateOption={async (opt) => {
                      const oldValue = value;
                      onChange({ label: t("loading"), value: "" });
                      try {
                        const newCategory = (
                          await CategoryService.create({
                            name: opt,
                            project_id: project_id,
                          })
                        ).data;

                        setAvaibleCategories({
                          ...avaibleCategories,
                          [project_id]: [
                            ...avaibleCategories[project_id as any],
                            newCategory,
                          ],
                        });
                        onChange({
                          label: newCategory.name,
                          value: newCategory.id,
                        });
                        toast({
                          title: t("category_create_success"),
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                      } catch (err) {
                        onChange(oldValue);
                        toast({
                          title: t("category_create_error"),
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                      }
                    }}
                  />
                </Stack>
              </Stack>
            )}
          />

          <Controller
            name={"responseTags"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <Stack width={"30%"}>
                <Stack maxW={"400px"}>
                  <TagSelect
                    title={t("category_table_tag_title")}
                    isMulti
                    options={tagSelectData}
                    placeholder={t("category_table_tag_placeholder")}
                    value={value}
                    onChange={(event) => {
                      onChange(
                        event.map(
                          (tag: {
                            label: string;
                            value: string;
                            isOld: true;
                          }) => ({
                            label: capitalizeFirstLetter(tag.label),
                            value: tag.value,
                            isOld: tag.isOld,
                          })
                        )
                      );
                    }}
                  />
                </Stack>
              </Stack>
            )}
          />
        </HStack>
      )}
    </HStack>
  );
};

export default RowForm;
