import { HStack, VStack, Text, Button, Stack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isNumber } from "radash";
import { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import QuickSearchInput from "../../../../../components/base/QuickSearchInput";
import i18next from "../../../../../i18n";
import { IQuestion_Identifier } from "../../../../../types";
import QuestionForm from "./QuestionIdentifierForm";
import { QuestionIdentifierFormValues, Props } from "./types";

type forcedProps = {
  control: Control<QuestionIdentifierFormValues, any>;
  values: () => any;
};

const Questions: React.FC<Props> = ({ control, values }: forcedProps) => {
  const currentLanguage = i18next.languages[0];
  const { t } = useTranslation();

  const [currentQuestion, setCurrentQuestion] = useState<
    (IQuestion_Identifier & { index?: number }) | undefined | {}
  >();

  const [avaibleQuestions, setAvaibleQuestions] = useState<
    IQuestion_Identifier[] | []
  >(values().questions_identifier);

  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if (searchFilter) {
      const avaible = values().questions_identifier?.filter(
        (identifier: IQuestion_Identifier) =>
          identifier.content.find(
            (question) =>
              question.title
                .toLowerCase()
                .includes(searchFilter?.toLowerCase()) &&
              question.lang === currentLanguage
          ) ||
          identifier.content.find(
            (question) =>
              question.title
                .toLowerCase()
                .includes(searchFilter?.toLowerCase()) && question.is_main
          )
      );
      setAvaibleQuestions(avaible);
    } else {
      setAvaibleQuestions(values().questions_identifier);
    }
  }, [searchFilter, currentLanguage, values, control._formValues]);

  return (
    <VStack width={"100%"}>
      <HStack
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"24px 24px 0px 24px"}
      >
        <Text
          fontSize={"24px"}
          fontWeight={600}
          color={"black"}
          whiteSpace={"nowrap"}
          textAlign={"left"}
        >
          {t("projects_question_identifier_title_questions")}
        </Text>
        <Button onClick={() => setCurrentQuestion({})}>
          {t("projects_question_identifier_button_create_question")}
        </Button>
      </HStack>

      <HStack
        w={"100%"}
        alignItems={"center"}
        justify={"flex-start"}
        padding={"24px"}
      >
        <QuickSearchInput
          onChange={(event) => setSearchFilter(event.target.value)}
        />
      </HStack>
      <HStack
        padding={"11px 24px"}
        w={"100%"}
        borderBottom={"1px solid"}
        borderColor={"#F0F3F5"}
      >
        <Text
          fontSize={"16px"}
          fontWeight={500}
          color={"black"}
          whiteSpace={"nowrap"}
          textAlign={"left"}
          flex={0.1}
        >
          No.
        </Text>
        <Text
          fontSize={"16px"}
          fontWeight={500}
          color={"black"}
          whiteSpace={"nowrap"}
          textAlign={"left"}
          flex={3}
        >
          {t("projects_question_identifier_label_column_question")}
        </Text>
      </HStack>

      <Controller
        name={"questions_identifier"}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <>
            {value?.length > 0 ? (
              <>
                {(searchFilter ? avaibleQuestions : value)?.length > 0 ? (
                  <>
                    {(searchFilter ? avaibleQuestions : value)?.map(
                      (questionIdentifier, index) => (
                        <motion.div
                          key={questionIdentifier.id}
                          style={{ width: "100%" }}
                          initial={{ height: 0 }}
                          animate={{ height: "fit-content" }}
                        >
                          <HStack
                            key={index}
                            width={"100%"}
                            borderBottom={"1px solid"}
                            borderColor={
                              value.length !== index + 1
                                ? "#F0F3F5"
                                : "transparent"
                            }
                            padding={"11px 24px"}
                            margin={"0px !important"}
                            position={"relative"}
                            maxW={"100%"}
                          >
                            <Text
                              fontSize={"14px"}
                              fontWeight={400}
                              color={"#455054"}
                              whiteSpace={"nowrap"}
                              textAlign={"left"}
                              flex={0.1}
                            >
                              {index + 1}
                            </Text>

                            <Stack flex={3}>
                              <Text
                                fontSize={"14px"}
                                fontWeight={400}
                                color={"black"}
                                whiteSpace={"nowrap"}
                                textAlign={"left"}
                                maxW={"700px"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                              >
                                {
                                  (
                                    questionIdentifier.content.find(
                                      (question) =>
                                        question.lang === currentLanguage
                                    ) ||
                                    questionIdentifier.content.find(
                                      (question) => question.is_main
                                    )
                                  )?.title
                                }
                              </Text>
                            </Stack>

                            <HStack
                              position={"absolute"}
                              right={"24px"}
                              spacing={"16px"}
                            >
                              <Button
                                height={"32px"}
                                variant={"solid_filter"}
                                onClick={() =>
                                  setCurrentQuestion({
                                    ...questionIdentifier,
                                    index,
                                  })
                                }
                              >
                                {t(
                                  "projects_question_identifier_button_edit_question"
                                )}
                              </Button>
                              <Button
                                height={"32px"}
                                variant={"solid_delete"}
                                onClick={() => {
                                  value.splice(index, 1);
                                  onChange(value);
                                }}
                              >
                                {t(
                                  "projects_question_identifier_button_delete_question"
                                )}
                              </Button>
                            </HStack>
                          </HStack>
                        </motion.div>
                      )
                    )}
                  </>
                ) : (
                  <HStack
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    padding={"11px 24px"}
                    margin={"0px !important"}
                  >
                    <Text
                      fontSize={"16px"}
                      fontWeight={400}
                      color={"black"}
                      whiteSpace={"nowrap"}
                      textAlign={"center"}
                    >
                      {t(
                        "projects_question_identifier_label_questions_not_found_in_search"
                      )}
                    </Text>
                  </HStack>
                )}
              </>
            ) : (
              <HStack
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
                padding={"11px 24px"}
                margin={"0px !important"}
              >
                <Text
                  fontSize={"16px"}
                  fontWeight={400}
                  color={errors.questions_identifier ? "delete_color" : "black"}
                  whiteSpace={"nowrap"}
                  textAlign={"center"}
                >
                  {t("projects_question_identifier_label_questions_not_found")}
                </Text>
              </HStack>
            )}

            <QuestionForm
              currentQuestion={currentQuestion}
              onClose={() => setCurrentQuestion(undefined)}
              getValues={values}
              onSubmit={(values, index) => {
                let b: any = [];
                (value || []).forEach((val) => b.push(val));
                if (isNumber(index)) {
                  b[index as number] = values;
                } else {
                  b.push(values);
                }

                onChange(b);
                setCurrentQuestion(undefined);
              }}
            />
          </>
        )}
      />
    </VStack>
  );
};

export default Questions;
