import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  semanticTokens: {
    colors: {
      primary: "#202951",
      white: "#FFFFFF",
      black: "#00121A",
      black_secondary: "#2B363B",
      placeholder_color: "#9A9CAB",
      navbar_color: "#363E62",
      navbar_placeholder_color: "#2A345E",
      container_border_color: "#F0F3F5",
      delete_color: "#D92626",
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "500px",
      },

      variants: {
        solid: {
          bg: "primary",
          color: "white",
          _hover: {
            bg: "button_solid_hover",
          },
        },
        outline: {
          bg: "transparent",
          color: "black",
          borderColor: "black",
        },

        solid_filter: {
          color: "black",
          bg: "container_border_color",
          border: "1px solid",
          borderColor: "container_border_color",
          fontWeight: 500,
          fontSize: "14px",
          _hover: {
            bg: "#dadee0",
          },
        },
        outline_filter: {
          color: "black",
          borderColor: "container_border_color !important",
          bg: "transparent",
          border: "1px solid",
          fontWeight: 500,
          fontSize: "14px",
          paddingLeft: "16px ",

          _hover: {
            bg: "#F5F5F5",
          },
        },

        solid_delete: {
          color: "delete_color",
          borderColor: "container_border_color !important",
          bg: "container_border_color",
          border: "1px solid",
          fontWeight: 500,
          fontSize: "14px",

          _hover: {
            bg: "#dadee0 !important",
          },
        },

        outline_delete: {
          color: "delete_color",
          bg: "transparent",
          borderColor: "container_border_color !important",
          border: "1px solid",
          fontWeight: 500,
          fontSize: "14px",
        },
      },
    },
  },
});

export default theme;
