import {
  Button,
  Center,
  HStack,
  Stack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InitialData from "./InitialData";
import Attachments from "./Attachments";
import ResponseDetail from "./ResponseDetails";
import { IAttachment, IResponse, ITag } from "../../types";
import TagService from "../../services/tag.service";
import FrameWrapper from "../../components/FrameWrapper";
import { InfinitySpin } from "react-loader-spinner";
import i18next from "../../i18n";
import { useNavigate, useParams } from "react-router-dom";
import ResponseService from "../../services/response.service";
import AttachmentService from "../../services/attachment.service";
import * as _ from "radash";
import { HiOutlineArrowSmLeft } from "react-icons/hi";

const ResponseForm: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const currentLanguage = i18next.languages[0];
  const [defaultTags, setDefaultTags] = useState<ITag[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [currentResponse, setCurrentResponse] = useState<IResponse>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      (async () => {
        setCurrentResponse(await ResponseService.getById(id));
        setIsLoading(false);
      })();
    }
  }, [id]);

  const { reset, handleSubmit, control, getValues, watch } = useForm({
    defaultValues: useMemo(() => {
      return {
        ...currentResponse,
        avaibleResearchSites: {},
        questionSelected: {},
      };
    }, [currentResponse]),
  });

  useEffect(() => {
    if (currentResponse) {
      reset({
        ...currentResponse,
        responseTags:
          currentResponse?.responseTags?.map((responseTag: any) => ({
            value: responseTag?.id,
            label: responseTag.tag.name,
            isOld: true,
          })) || [],

        avaibleResearchSites: {
          value: currentResponse?.research_site?.id,
          label: currentResponse?.research_site?.address,
        },

        questionSelected: {
          value: currentResponse?.question_identifier?.id,
          label:
            (
              currentResponse?.question_identifier?.content.find(
                (content) => content.lang === currentLanguage
              ) ||
              currentResponse?.question_identifier?.content.find(
                (content) => content.is_main
              )
            )?.title || "",
        },
      });
      if (!("id" in currentResponse)) {
        reset({});
      }
    }
  }, [currentResponse, reset]);

  useEffect(() => {
    const populateTags = async () => {
      setDefaultTags(await TagService.getDefaultTags());
    };
    populateTags();
  }, []);

  const toast = useToast();

  const onDelete = async (id: IResponse["id"]) => {
    setIsLoading(true);
    try {
      await ResponseService.delete(id);
    } catch (err) {
      toast({
        title: t("responses_response_data_error_title"),
        description: t("responses_response_data_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setCurrentResponse(undefined);
    setIsLoading(false);
    navigate(-1);
  };

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const attachmentsToDelete =
        currentResponse?.attachments.filter(
          (attachment) =>
            !values.attachments.find(
              (newAttachment: IAttachment) => newAttachment.id === attachment.id
            )
        ) || [];

      await ResponseService.updateResponse({
        ..._.omit(values, [
          "research_site",
          "question_identifier",
          "attachments",
        ]),
        ...(values.avaibleResearchSites && {
          research_site_id: values.avaibleResearchSites.value,
        }),
        ...(values.questionSelected && {
          question_identifier_id: values.questionSelected.value,
        }),
        priority: values.priority.value,
      });

      await Promise.all(
        attachmentsToDelete.map(
          async (attachment) => await AttachmentService.delete(attachment.id)
        )
      );

      await Promise.all(
        values.attachments.map(async (attachment: IAttachment | Blob) => {
          if (attachment instanceof Blob) {
            const form = new FormData();
            form.append("file", attachment);
            await AttachmentService.saveAttachment(values.id, form);
          }
        })
      );
    } catch (err) {
      toast({
        title: t("responses_response_data_error_title"),
        description: t("responses_response_data_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    navigate(-1);
    setCurrentResponse(undefined);
    setIsLoading(false);
  };

  return (
    <VStack
      flex={1}
      transition={"500ms all"}
      width={"100%"}
      zIndex={"2"}
      background={"#F9FBFB"}
    >
      {isLoading ? (
        <Center flex={1}>
          <InfinitySpin color="#202951" />
        </Center>
      ) : (
        <>
          <HStack w={"100%"} alignItems={"center"} mb={4}>
            <Button
              onClick={() => navigate(-1)}
              leftIcon={<HiOutlineArrowSmLeft />}
              variant={"outline"}
            >
              {t("projects_button_back")}
            </Button>
          </HStack>
          <HStack spacing={"16px"} width={"100%"} alignItems={"flex-start"}>
            <VStack flex={0.6} m={"0px !important"}>
              <FrameWrapper>
                <InitialData control={control} values={getValues} />
              </FrameWrapper>

              <Stack mt={"16px"} />
              <FrameWrapper>
                {defaultTags ? (
                  <ResponseDetail
                    defaultTags={defaultTags.filter(
                      (tag) =>
                        tag?.project_id ===
                        currentResponse?.question_identifier?.project_id
                    )}
                    control={control}
                    values={getValues}
                  />
                ) : (
                  <InfinitySpin width="200" color="#000000" />
                )}
              </FrameWrapper>
            </VStack>
            <Stack flex={0.4}>
              <FrameWrapper>
                <Attachments
                  watch={watch("attachments")}
                  control={control}
                  values={getValues}
                />
              </FrameWrapper>
            </Stack>
          </HStack>

          <HStack
            mt={"6px"}
            w={"100%"}
            justifyContent={"center"}
            spacing={"16px"}
          >
            {currentResponse && "id" in currentResponse && (
              <Button
                variant={"outline_delete"}
                onClick={() => onDelete(currentResponse?.id)}
              >
                {t("responses_form_button_delete")}
              </Button>
            )}

            <Button variant={"solid"} onClick={handleSubmit(onSubmit)}>
              {t("responses_form_button_save")}
            </Button>
          </HStack>
        </>
      )}
    </VStack>
  );
};

export default ResponseForm;
