import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Props } from "./types";
import { FiAlertCircle } from "react-icons/fi";
import { Input } from "../../../../../../components/base/Input";
import { SelectWModal } from "../../../../../../components/base/Select";
import { useTranslation } from "react-i18next";
import { resources } from "../../../../../../i18n";
import TagSelect from "../../../../../../components/TagSelect";

function capitalizeFirstLetter(string: string) {
  return (string.charAt(0).toUpperCase() + string.slice(1)).trim();
}

const ResearchSiteForm: React.FC<Props> = ({
  currentResearchSite,
  onClose,
  getValues,
  onSubmit,
  defaultSiteTags,
}) => {
  const avaibleLanguages = Object.keys(resources).map((key) => ({
    value: key,
    label: resources[key].label,
  }));
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: useMemo(() => {
      return { ...currentResearchSite };
    }, [currentResearchSite]),
  });

  useEffect(() => {
    if (currentResearchSite) {
      console.log(currentResearchSite);
      reset({
        ...currentResearchSite,
        research_site_tags:
          (currentResearchSite as any)?.research_site_tags?.map(
            (researchTag: any) => ({
              value: researchTag?.id || researchTag.value,
              label: researchTag?.site_tag?.name || researchTag.value,
              isOld: !!researchTag?.id,
            })
          ) || [],
      });

      if (JSON.stringify(currentResearchSite) === "{}") {
        reset({});
      }
    }
  }, [currentResearchSite, reset]);

  const thisSubmit = (values: any) => {
    console.log(values);
    onSubmit(
      { ...values, ...(getValues()?.id && { project_id: getValues().id }) },
      (currentResearchSite as any)?.index
    );
  };

  return (
    <Modal isOpen={!!currentResearchSite} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"}>
          {JSON.stringify(currentResearchSite) === "{}"
            ? t("projects_research_site_site_label_create_site")
            : t("projects_research_site_site_label_edit_site")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Controller
            name={"name"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <VStack w={"100%"}>
                <Input
                  m={"0px !important"}
                  placeholder={t(
                    "projects_research_site_site_placeholder_site_name"
                  )}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                {errors.name && (
                  <HStack mt={"16px !important"} w={"100%"}>
                    <Icon
                      as={FiAlertCircle}
                      color={"delete_color"}
                      w={"14px"}
                      mr={"8px !important"}
                    />
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"delete_color"}
                      m={"0px !important"}
                    >
                      {t("errors_name")}
                    </Text>
                  </HStack>
                )}
              </VStack>
            )}
          />
          <Controller
            name={"coordinator"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <VStack w={"100%"}>
                <Input
                  mt={"24px !important"}
                  placeholder={t(
                    "projects_research_site_site_placeholder_coordinator"
                  )}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                {errors.coordinator && (
                  <HStack mt={"16px !important"} w={"100%"}>
                    <Icon
                      as={FiAlertCircle}
                      color={"delete_color"}
                      w={"14px"}
                      mr={"8px !important"}
                    />
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"delete_color"}
                      m={"0px !important"}
                    >
                      {t("errors_coordinator")}
                    </Text>
                  </HStack>
                )}
              </VStack>
            )}
          />
          <Controller
            name={"address"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <VStack w={"100%"}>
                <Input
                  mt={"24px !important"}
                  placeholder={t(
                    "projects_research_site_site_placeholder_address"
                  )}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                {errors.address && (
                  <HStack mt={"16px !important"} w={"100%"}>
                    <Icon
                      as={FiAlertCircle}
                      color={"delete_color"}
                      w={"14px"}
                      mr={"8px !important"}
                    />
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"delete_color"}
                      m={"0px !important"}
                    >
                      {t("errors_address")}
                    </Text>
                  </HStack>
                )}
              </VStack>
            )}
          />

          <Controller
            name={"lang"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <VStack w={"100%"}>
                <SelectWModal
                  mt={"24px !important"}
                  items={avaibleLanguages}
                  placeholder={t(
                    "projects_research_site_site_placeholder_language"
                  )}
                  SelectItem={(event) => onChange(event.value)}
                  itemSelected={avaibleLanguages.find(
                    (avaible) => avaible.value === value
                  )}
                />

                {errors.lang && (
                  <HStack mt={"16px !important"} w={"100%"}>
                    <Icon
                      as={FiAlertCircle}
                      color={"delete_color"}
                      w={"14px"}
                      mr={"8px !important"}
                    />
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"delete_color"}
                      m={"0px !important"}
                    >
                      {t("errors_language")}
                    </Text>
                  </HStack>
                )}
              </VStack>
            )}
          />

          <Controller
            name={"research_site_tags"}
            control={control}
            rules={{ required: false }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <VStack w={"100%"} mt={"24px !important"}>
                <TagSelect
                  title={t("tag_text_modal_response_screen")}
                  isMulti
                  options={defaultSiteTags?.map((val) => ({
                    label: val?.name,
                    value: val?.name,
                  }))}
                  placeholder={"Site descriptors"}
                  value={value}
                  onChange={(event) => {
                    onChange(
                      event?.map(
                        (tag: {
                          label: string;
                          value: string;
                          isOld: true;
                        }) => ({
                          label: capitalizeFirstLetter(tag.label),
                          value: tag?.value,
                          isOld: tag?.isOld,
                        })
                      )
                    );
                  }}
                />
                {errors.address && (
                  <HStack mt={"16px !important"} w={"100%"}>
                    <Icon
                      as={FiAlertCircle}
                      color={"delete_color"}
                      w={"14px"}
                      mr={"8px !important"}
                    />
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"delete_color"}
                      m={"0px !important"}
                    >
                      {t("errors_address")}
                    </Text>
                  </HStack>
                )}
              </VStack>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit(thisSubmit)}
            width={"100%"}
          >
            {t("projects_research_site_site_label_save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ResearchSiteForm;
