import { HStack, VStack, Text, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isNumber } from "radash";
import { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import QuickSearchInput from "../../../../../components/base/QuickSearchInput";
import { resources } from "../../../../../i18n";
import { IResearchSite, ISiteTag } from "../../../../../types";
import ResearchSiteForm from "./ResearchSiteForm";
import { Props, ResearchSiteFormValues } from "./types";

type forcedProps = {
  control: Control<ResearchSiteFormValues, any>;
  values: () => any;
  defaultSiteTags?: ISiteTag[];
};

const ResearchSites: React.FC<Props> = ({
  control,
  values,
  defaultSiteTags,
}: forcedProps) => {
  const { t } = useTranslation();
  const [currentResearchSite, setCurrentResearchSite] = useState<
    (IResearchSite & { index?: number }) | undefined | {}
  >();

  const avaibleLanguages = Object.keys(resources).map((key) => ({
    value: key,
    label: resources[key].label,
  }));

  const [avaibleResearchSites, setAvaibleResearchSites] = useState<
    IResearchSite[] | []
  >(values().researchSites);

  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if (searchFilter) {
      const avaible = values().researchSites?.filter((site: IResearchSite) =>
        site.name.toLowerCase().includes(searchFilter.toLowerCase())
      );
      setAvaibleResearchSites(avaible);
    } else {
      setAvaibleResearchSites(values().researchSites);
    }
  }, [searchFilter, values, control._formValues]);

  return (
    <VStack width={"100%"}>
      <HStack
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"24px 24px 0px 24px"}
      >
        <Text
          fontSize={"24px"}
          fontWeight={600}
          color={"black"}
          whiteSpace={"nowrap"}
          textAlign={"left"}
        >
          {t("projects_research_site_title_site")}
        </Text>
        <Button onClick={() => setCurrentResearchSite({})}>
          {t("projects_research_site_button_create_site")}
        </Button>
      </HStack>

      <HStack
        w={"100%"}
        alignItems={"center"}
        justify={"flex-start"}
        padding={"24px"}
      >
        <QuickSearchInput
          onChange={(event) => setSearchFilter(event.target.value)}
        />
      </HStack>
      <HStack
        padding={"11px 24px"}
        w={"100%"}
        borderBottom={"1px solid"}
        borderColor={"#F0F3F5"}
      >
        <Text
          fontSize={"16px"}
          fontWeight={500}
          color={"black"}
          whiteSpace={"nowrap"}
          textAlign={"left"}
          flex={1.5}
        >
          {t("projects_research_site_label_column_site")}
        </Text>
        <Text
          fontSize={"16px"}
          fontWeight={500}
          color={"black"}
          whiteSpace={"nowrap"}
          textAlign={"left"}
          flex={0.4}
        >
          {t("projects_research_site_label_column_lang")}
        </Text>
        <Text
          fontSize={"16px"}
          fontWeight={500}
          color={"black"}
          whiteSpace={"nowrap"}
          textAlign={"left"}
          flex={1}
        >
          {t("projects_research_site_label_column_coordinator")}
        </Text>
      </HStack>

      <Controller
        name={"researchSites"}
        control={control}
        rules={{ required: true }}
        render={({
          field: { onBlur, onChange, value },
          formState: { errors },
        }) => (
          <>
            {value?.length > 0 ? (
              <>
                {(searchFilter ? avaibleResearchSites : value).length > 0 ? (
                  <>
                    {(searchFilter ? avaibleResearchSites : value)?.map(
                      (site, index) => (
                        <motion.div
                          key={site.id}
                          style={{ width: "100%" }}
                          initial={{ height: 0 }}
                          animate={{ height: "fit-content" }}
                          exit={{ height: 0 }}
                        >
                          <HStack
                            key={site.id}
                            width={"100%"}
                            borderBottom={"1px solid"}
                            borderColor={
                              value.length !== index + 1
                                ? "#F0F3F5"
                                : "transparent"
                            }
                            padding={"11px 24px"}
                            margin={"0px !important"}
                            position={"relative"}
                          >
                            <VStack
                              flex={1.5}
                              alignItems={"start"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                            >
                              <Text
                                fontSize={"14px"}
                                fontWeight={400}
                                color={"black"}
                                whiteSpace={"nowrap"}
                                textAlign={"left"}
                              >
                                {site.name}
                              </Text>
                              <Text
                                mt={"4px !important"}
                                fontSize={"14px"}
                                fontWeight={400}
                                color={"#455054"}
                                whiteSpace={"nowrap"}
                                textAlign={"left"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                maxW={"100%"}
                              >
                                {site.notes}
                              </Text>
                            </VStack>
                            <Text
                              flex={0.4}
                              fontSize={"14px"}
                              fontWeight={400}
                              color={"#455054"}
                              whiteSpace={"nowrap"}
                              textAlign={"left"}
                            >
                              {
                                avaibleLanguages.find(
                                  (lang) => lang.value === site.lang
                                )?.label
                              }
                            </Text>

                            <Text
                              flex={1}
                              fontSize={"14px"}
                              fontWeight={400}
                              color={"#455054"}
                              whiteSpace={"nowrap"}
                              textAlign={"left"}
                            >
                              {site.coordinator}
                            </Text>

                            <HStack
                              position={"absolute"}
                              right={"24px"}
                              spacing={"16px"}
                            >
                              <Button
                                height={"32px"}
                                variant={"solid_filter"}
                                onClick={() =>
                                  setCurrentResearchSite({ ...site, index })
                                }
                              >
                                {t("projects_research_site_button_edit")}
                              </Button>
                              <Button
                                height={"32px"}
                                variant={"solid_delete"}
                                onClick={() => {
                                  value.splice(index, 1);
                                  onChange(value);
                                }}
                              >
                                {t("projects_research_site_button_delete")}
                              </Button>
                            </HStack>
                          </HStack>
                        </motion.div>
                      )
                    )}
                  </>
                ) : (
                  <HStack
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    padding={"11px 24px"}
                    margin={"0px !important"}
                  >
                    <Text
                      fontSize={"16px"}
                      fontWeight={400}
                      color={"black"}
                      whiteSpace={"nowrap"}
                      textAlign={"center"}
                    >
                      {t("projects_research_site_label_not_found_in_search")}
                    </Text>
                  </HStack>
                )}
              </>
            ) : (
              <HStack
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
                padding={"11px 24px"}
                margin={"0px !important"}
              >
                <Text
                  fontSize={"16px"}
                  fontWeight={400}
                  color={errors.researchSites ? "delete_color" : "black"}
                  whiteSpace={"nowrap"}
                  textAlign={"center"}
                >
                  {t("projects_research_site_label_not_found")}
                </Text>
              </HStack>
            )}
            <ResearchSiteForm
              defaultSiteTags={defaultSiteTags || []}
              currentResearchSite={currentResearchSite}
              onClose={() => setCurrentResearchSite(undefined)}
              getValues={values}
              onSubmit={(values, index) => {
                let newResearchSite: any = [];
                (value || []).forEach((val) => newResearchSite.push(val));
                if (isNumber(index)) {
                  newResearchSite[index as number] = values;
                } else {
                  newResearchSite.push(values);
                }

                onChange(newResearchSite);
                setCurrentResearchSite(undefined);
              }}
            />
          </>
        )}
      />
    </VStack>
  );
};

export default ResearchSites;
