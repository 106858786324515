import { HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { ReactNode, useContext, useState } from "react";
import { UserContext } from "../../providers/contexts/user";
import LanguageSelect from "../languageSelect";
import profileDefault from "../../assets/images/profileDefault.png";
import useDeviceDetection from "../../hooks/IsMobile";
import { HiMenuAlt2 } from "react-icons/hi";
import Navbar from "../navbar";

type Props = {
  children: ReactNode;
};

const LoggedInHeader: React.FC<Props> = ({ children }: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { isDesktop } = useDeviceDetection();
  const { user } = useContext(UserContext);

  return (
    <VStack flex={1} w={"100%"} height={"100%"}>
      <HStack w={"100%"} padding={"16px 32px"} justifyContent={"space-between"}>
        <HStack>
          {!isDesktop && (
            <Icon
              as={HiMenuAlt2}
              fontSize={"24px"}
              mr={2}
              onClick={() => setMenuIsOpen(!menuIsOpen)}
            />
          )}
          <Text
            fontWeight={500}
            fontSize={"24px"}
            color={"black"}
            fontFamily={`'Roboto Serif', serif`}
          >
            knowmad café administration
          </Text>
        </HStack>

        <HStack>
          <LanguageSelect />
          <HStack
            borderLeft={"1px solid #F4F5F5s"}
            height={"100%"}
            marginLeft={"16px !important"}
          >
            <Image
              marginLeft={"16px !important"}
              src={user?.image_url ? user.image_url : profileDefault}
              w={"32px"}
              h={"32px"}
              borderRadius={"16px"}
              marginRight={"8px"}
            />
            <Text fontWeight={500} fontSize={"14px"} color={"black"}>
              {user?.name}
            </Text>
          </HStack>
        </HStack>
      </HStack>
      <VStack
        transition={"400ms all"}
        overflow={"hidden"}
        height={"100vh"}
        maxW={menuIsOpen ? "100%" : 0}
        position={"absolute"}
        zIndex={1000}
        left={0}
        top={0}
        margin={"0px !important"}
      >
        <Navbar onClickItem={() => setMenuIsOpen(false)}>
          <></>
        </Navbar>
      </VStack>
      <VStack
        w={"100%"}
        height={"100%"}
        background={"#F9FBFB"}
        padding={"44px 64px 44px 36px"}
        overflow={"scroll"}
        maxH={"calc(100vh - 78px)"}
        maxW={!isDesktop ? "100%" : "calc(100vw - 250px)"}
        position={"relative"}
      >
        {children}
      </VStack>
    </VStack>
  );
};

export default LoggedInHeader;
