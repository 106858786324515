import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import FrameWrapper from "../../../components/FrameWrapper";
import PageTitle from "../../../components/pageTitle";
import UserService from "../../../services/user.service";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import QuickSearchInput from "../../../components/base/QuickSearchInput";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { format, isValid } from "date-fns";
import { enUS } from "date-fns/locale";
import { InfinitySpin } from "react-loader-spinner";
import Paginate from "../../../components/Paginate";
import { IUser } from "../../../types";
import EditUserForm from "./EditUserForm";
import ChangePasswordForm from "./ChangePasswordForm";
import { UserContext } from "../../../providers/contexts/user";

const formatDate = (date: Date | undefined) => {
  if (date && isValid(date)) {
    return format(date, "dd MMM yyyy", { locale: enUS });
  }
  return "";
};

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const InternalNavBar =
    user?.profile_code === "user"
      ? [
          {
            label: t("settings_nav_item_password"),
            value: "password",
            icon: RiLockPasswordLine,
          },
        ]
      : [
          {
            label: t("settings_nav_item_users"),
            value: "users",
            icon: AiOutlineUser,
          },
          {
            label: t("settings_nav_item_password"),
            value: "password",
            icon: RiLockPasswordLine,
          },
        ];

  const [navLocation, setNavLocation] = useState(InternalNavBar[0].value);
  const [currentUser, setCurrentUser] = useState<IUser | undefined | {}>();
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState<any>(null);
  const [usersData, setUsersData] = useState<any[]>();
  const [filter, setFilter] = useState("");
  const [isLoadingUserUpdate, setIsLoadingUserUpdate] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const toast = useToast();

  const populateData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = (
        await UserService.getUserData(
          `?limit=${5}&offset=${page * 5}&filter=${filter}`
        )
      ).data;
      setUsersData(data.result);
      setTotal(data.total);
    } catch (err) {
      toast({
        title: t("table_user_error_title"),
        description: t("table_user_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  }, [filter, page, navLocation]);

  useEffect(() => {
    populateData();
  }, [populateData]);

  const handleInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => setFilter(value), 300));
  };

  const handleExportData = async () => {
    try {
      const dataToExport = (await UserService.getUserData(""))?.data?.result;

      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(dataToExport),
        "Relatório"
      );

      XLSX.writeFile(wb, `Users - ${formatDate(new Date())}.xlsx`);
    } catch (err) {
      toast({
        title: t("table_user_error_title"),
        description: t("table_user_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSubmitNewUser = async (formValues: IUser) => {
    setIsLoadingUserUpdate(true);
    try {
      if ("id" in formValues) {
        await UserService.update({
          ...formValues,
          profile_code: (formValues as any).profile_code.value,
        });
      } else {
        await UserService.create({
          ...formValues,
          profile_code: (formValues as any).profile_code.value,
        });
      }
    } catch (err) {
      toast({
        title: t("table_user_handling_error_title"),
        description: t("table_user_handling_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    populateData();
    setCurrentUser(undefined);
    setIsLoadingUserUpdate(false);
  };

  const handleChangePasswordForm = async (formValues: {
    oldPassword: string;
    newPassword: string;
    rePassword: string;
  }) => {
    try {
      await UserService.changePassword(formValues, user?.id || "");

      toast({
        title: t("password_changed_with_succes"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: t("password_changed_with_error"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteUser = async (id: IUser["id"]) => {
    setIsLoadingUserUpdate(true);
    try {
      await UserService.delete(id);
    } catch (err) {
      toast({
        title: t("table_user_handling_error_title"),
        description: t("table_user_handling_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    populateData();
    setCurrentUser(undefined);
    setIsLoadingUserUpdate(false);
  };

  return (
    <VStack w={"100%"} height={"100%"}>
      <PageTitle>
        {navLocation === "users" && (
          <Button onClick={() => setCurrentUser({})} variant={"solid"}>
            {t("user_create")}
          </Button>
        )}
      </PageTitle>

      <Stack w={"100%"} mt={"40px !important"}>
        <FrameWrapper padding="0px">
          <HStack w={"100%"}>
            <VStack minW={"160px"} h={"100%"} padding={"24px"}>
              {InternalNavBar.map((item, index) => {
                const isActive = navLocation === item.value;
                const isLast = InternalNavBar.length === index + 1;

                return (
                  <HStack
                    onClick={() => setNavLocation(item.value)}
                    w={"100%"}
                    marginTop={"0px !important"}
                    marginBottom={isLast ? "12px !important" : "0px !important"}
                    transition={"300ms all"}
                    cursor={"pointer"}
                    borderRadius={"8px"}
                  >
                    {isActive && (
                      <Stack
                        width={"4px"}
                        height={"24px"}
                        borderRadius={"4px"}
                        background={"primary"}
                        color={"primary"}
                      />
                    )}

                    <HStack padding={"10px 0px"} ml={isActive ? "0px" : "12px"}>
                      <Icon
                        as={(item as any).icon}
                        color={isActive ? "primary" : "placeholder_color"}
                      />
                      <Text
                        ml={"14px !important"}
                        fontWeight={500}
                        fontSize={"14px"}
                        color={isActive ? "primary" : "placeholder_color"}
                      >
                        {item.label}
                      </Text>
                    </HStack>
                  </HStack>
                );
              })}
            </VStack>
            <VStack w={"100%"} h={"100%"}>
              {navLocation === "users" ? (
                <>
                  <HStack p={"24px"} w={"100%"} alignItems={"start"}>
                    <QuickSearchInput
                      onChange={(event) => handleInputSearch(event)}
                    />
                    <Button
                      variant={"outline_filter"}
                      onClick={handleExportData}
                      ml={"8px"}
                    >
                      {t("table_button_export")}
                    </Button>
                  </HStack>
                  {isLoading ? (
                    <Center h={"200px"} w={"100%"}>
                      <InfinitySpin color="#202951" />
                    </Center>
                  ) : (
                    <>
                      <HStack
                        w={"100%"}
                        px={"24px"}
                        borderBottom={"1px solid"}
                        borderColor={"#F0F1F4"}
                        py={"11px"}
                        m={"0px !important"}
                      >
                        <Text
                          flex={1}
                          fontSize={"16px"}
                          fontWeight={500}
                          color={"black"}
                        >
                          {t("user_name")}
                        </Text>
                        <Text
                          flex={1}
                          fontSize={"16px"}
                          fontWeight={500}
                          color={"black"}
                        >
                          {t("user_email")}
                        </Text>
                        <Text
                          flex={1}
                          fontSize={"16px"}
                          fontWeight={500}
                          color={"black"}
                        >
                          {t("user_created_at")}
                        </Text>
                      </HStack>

                      {usersData && usersData?.length > 0 ? (
                        <>
                          {usersData.map((user, index) => (
                            <HStack
                              w={"100%"}
                              p={"24px"}
                              borderBottom={"1px solid"}
                              borderColor={
                                usersData.length === index + 1
                                  ? "transparent"
                                  : "#F0F1F4"
                              }
                              m={"0px !important"}
                              position={"relative"}
                            >
                              <Text
                                flex={1}
                                fontSize={"14px"}
                                fontWeight={400}
                                color={"#00041A"}
                              >
                                {user.name}
                              </Text>
                              <Text
                                flex={1}
                                fontSize={"14px"}
                                fontWeight={400}
                                color={"#00041A"}
                              >
                                {user.email}
                              </Text>
                              <Text
                                flex={1}
                                fontSize={"14px"}
                                fontWeight={400}
                                color={"#00041A"}
                              >
                                {formatDate(new Date(user.createdAt))}
                              </Text>

                              <Button
                                position={"absolute"}
                                right={"24px"}
                                height={"32px"}
                                variant={"solid_filter"}
                                onClick={() => setCurrentUser(user)}
                              >
                                {t("table_button_edit")}
                              </Button>
                            </HStack>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  <Box w={"100%"} p={"24px"}>
                    <Paginate
                      nextLabel={t("paginate_next")}
                      previousLabel={t("paginate_previous")}
                      onPageChange={(page) => setPage(page)}
                      pageCount={total / 5}
                    />
                  </Box>
                  {currentUser && (
                    <EditUserForm
                      currentUser={currentUser}
                      onSubmit={handleSubmitNewUser}
                      onClose={() => setCurrentUser(undefined)}
                      isLoading={isLoadingUserUpdate}
                      onDelete={handleDeleteUser}
                    />
                  )}
                </>
              ) : (
                <ChangePasswordForm onSubmit={handleChangePasswordForm} />
              )}
            </VStack>
          </HStack>
        </FrameWrapper>
      </Stack>
    </VStack>
  );
};

export default SettingsPage;
