import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/global/index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Router from "./Routes";
import Providers from "./providers";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  /*  remember add again React.StrictMode */
  <Providers>
    <Router />
  </Providers>
);

serviceWorkerRegistration.register();
