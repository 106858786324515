import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { InfinitySpin } from "react-loader-spinner";
import Select from "react-select";
import { Props } from "./types";
import TableService from "../../services/tables.service";
import { FiArrowUp } from "react-icons/fi";
import Paginate from "../Paginate";
import { motion } from "framer-motion";
import * as XLSX from "xlsx";
import * as _ from "radash";
import { format, isValid } from "date-fns";
import { enUS } from "date-fns/locale";
import QuickSearchInput from "../base/QuickSearchInput";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/contexts/user";
import { SelectWModal } from "../base/Select";
import {
  IAttachment,
  IProject,
  IQuestion_Identifier,
  IResponse,
  IResponseTags,
} from "../../types";
import i18next from "i18next";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: () => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    borderRadius: "500px",
    cursor: "pointer",
    minWidth: "160px",
    maxWidth: "200px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    flexWrap: "nowrap !important",
  }),
  singleValue: (base: any) => ({
    ...base,
    textAlign: "center",
  }),

  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    borderRadius: "500px",
  }),

  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

const defaultTransitionVariant = {
  visible: { opacity: 1, scale: 1 },
  hidden: { opacity: 0, scale: 0 },
};

const TableWrapper: React.FC<Props> = ({
  endpoint,
  fieldsAvaible,
  currentData,
  onEdit,
  name,
}) => {
  const { user, projects, projectFiltered, setProjectFiltered } =
    useContext(UserContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fieldsFilter, setFieldsFilter] = useState<typeof fieldsAvaible | []>([
    fieldsAvaible[0],
    fieldsAvaible[1],
  ]);
  const [sortFilter, setSortFilter] = useState<typeof fieldsAvaible[0] | null>({
    value: fieldsAvaible[0].value,
    label: fieldsAvaible[0].label,
  });
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const [questionSelected, setQuestionSelected] = useState<string>("");

  const [tableData, setTableData] = useState<any>();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");
  const [timer, setTimer] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const currentLanguage = i18next.languages[0];

  const toast = useToast();

  const formatDate = (date: Date | undefined) => {
    if (date && isValid(date)) {
      return format(date, "dd MMM yyyy", { locale: enUS });
    }
    return "";
  };

  const handleExportData = async () => {
    try {
      let dataToExport: any[] = (
        await TableService.getTableData(
          endpoint +
            `${user?.profile_code === "user" ? "?created_by=" + user.id : ""}`
        )
      )?.data?.result;

      if (endpoint === "/response") {
        dataToExport = dataToExport.map((data: IResponse) =>
          _.omit(
            {
              ...data,
              "is profund": data.is_profund,
              category: data?.category?.name || "Uncategorized",
              attachments: (data.attachments as any).reduce(
                (acc: string, item: IAttachment, _: number) =>
                  acc +
                  item?.content +
                  (data.attachments.length !== _ + 1 ? ", " : ""),
                ""
              ),
              "response tags": data?.responseTags.reduce(
                (acc: string, item: IResponseTags, _: number) =>
                  acc +
                  item?.tag?.name +
                  (data?.responseTags.length !== _ + 1 ? ", " : ""),
                ""
              ),
              "research site": data?.research_site?.name,
              "question text":
                data?.question_identifier?.content?.find(
                  (question: any) => question.lang === currentLanguage
                )?.title ||
                data?.question_identifier?.content?.find(
                  (question: any) => question?.is_main
                ),
              "response text": data.text,
            },
            [
              "research_site",
              "question_identifier",
              "responseTags",
              "text",
              "question_identifier_id",
              "category_id",
              "research_site_id",
              "is_profund",
            ]
          )
        );
      } else {
        dataToExport = dataToExport.map((data: IProject) =>
          _.omit(
            {
              ...data,
              "short name": data.short_name,
              "is active": data.is_active,
              "created by": data?.user?.name,
              "primary color": data.primary_collor,
              questions: (data.questions_identifier as any[]).reduce(
                (acc, item: IQuestion_Identifier, _) =>
                  acc +
                  (item?.content?.find(
                    (question) => question?.lang === currentLanguage
                  )?.title ||
                    item?.content?.find((question) => question?.is_main)
                      ?.title) +
                  (data.questions_identifier.length !== _ + 1 ? ", " : ""),
                ""
              ),
              "research sites": data.researchSites.reduce(
                (acc, item, _) =>
                  acc +
                  item.name +
                  (data.researchSites.length !== _ + 1 ? ", " : ""),
                ""
              ),
            },
            [
              "questions_identifier",
              "created_by",
              "user",
              "researchSites",
              "is_active",
              "short_name",
              "primary_collor",
            ]
          )
        );
      }

      console.log(dataToExport);

      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(dataToExport),
        "Relatório"
      );

      XLSX.writeFile(wb, `${name} - ${formatDate(new Date())}.xlsx`);
    } catch (err) {
      toast({
        title: "Table error",
        description: "Internal server error when try export data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const populateData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = (
        await TableService.getTableData(
          endpoint +
            `?limit=${20}&offset=${page * 20}&filter=${filter}&sort=${
              sortFilter?.value !== "research_site" ? sortFilter?.value : ""
            }${sortFilter?.value && "&order=" + sortOrder}${
              projectFiltered && "&project_id=" + projectFiltered
            }${questionSelected && "&question_id=" + questionSelected}${
              endpoint === "/project" && user?.profile_code === "user"
                ? "&created_by=" + user.id
                : ""
            }`
        )
      ).data;
      setTableData(data.result);
      setTotal(data.total);
    } catch (err) {
      toast({
        title: "Projects Error",
        description: "Internal server error when try get table.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  }, [
    toast,
    endpoint,
    filter,
    page,
    sortFilter,
    sortOrder,
    projectFiltered,
    questionSelected,
  ]);

  useEffect(() => {
    if (!currentData) {
      populateData();
    }
  }, [populateData, currentData]);

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, projectFiltered, questionSelected]);

  useEffect(() => {
    if (!fieldsFilter.find((field) => field.value === sortFilter?.value)) {
      setSortFilter({
        label: fieldsAvaible[0].label,
        value: fieldsAvaible[0].value,
      });
    }
  }, [fieldsFilter]);

  const handleInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => setFilter(value), 300));
  };

  const avaibleQuestions = [
    ...(
      (projects?.find((pr) => pr?.id === projectFiltered)
        ?.questions_identifier as IQuestion_Identifier[]) || []
    )?.reduce(
      (acc, item) => [
        ...acc,
        {
          value: item?.id,
          label:
            item.content.find((item) => item.lang === currentLanguage)?.title ||
            item.content.find((item) => item.is_main)?.title,
        },
      ],
      [] as any
    ),
    { value: "", label: "All questions" },
  ];

  return (
    <VStack mt={"40px !important"} width={"100%"} position={"relative"}>
      {!projectFiltered && name !== "Projects" && (
        <>
          <Box
            position={"absolute"}
            w={"100%"}
            h={"100%"}
            m={"0px !important"}
            justifyContent={"center"}
            display={"flex"}
            backdropFilter={"blur(3px)"}
            zIndex={10}
          />
          <Stack
            w={"100%"}
            h={"100%"}
            position={"absolute"}
            zIndex={11}
            alignItems={"center"}
          >
            <VStack
              minW={"400px"}
              padding={"32px"}
              background={"white"}
              w={"fit-content"}
              mt={"189px"}
              borderRadius={"8px"}
              justifyContent={"center"}
              border={"1px solid #9A9CAB"}
            >
              <Text
                color={"black"}
                fontSize={"16px"}
                fontWeight={500}
                mb={"0px !important"}
              >
                {t("choose_a_project_label")}
              </Text>

              <SelectWModal
                mt={"16px !important"}
                itemSelected={[
                  ...projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                  })),
                ].find((val) => val.value === projectFiltered)}
                items={[
                  ...projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                  })),
                ]}
                SelectItem={(item) => {
                  setProjectFiltered(item?.value || "");
                }}
                placeholder={t("nav_bar_projects")}
              />
            </VStack>
          </Stack>
        </>
      )}
      <VStack
        border={"1px solid"}
        borderColor={"container_border_color"}
        background={"white"}
        borderRadius={"12px"}
        width={"100%"}
      >
        <HStack
          padding={"24px"}
          width={"100%"}
          spacing={"8px"}
          overflow={"auto"}
          className={"custom-scroll"}
        >
          <QuickSearchInput onChange={(event) => handleInputSearch(event)} />
          {name !== "Projects" && (
            <>
              <Select
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                options={[
                  ...projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                  })),
                ]}
                onChange={(event) => {
                  setProjectFiltered(event?.value || "");
                  setQuestionSelected("");
                }}
                value={[
                  ...projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                  })),
                ].find((val) => val.value === projectFiltered)}
                styles={customStyles}
                placeholder={"Project"}
                isClearable={false}
              />

              <Select
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                options={avaibleQuestions}
                onChange={(event: any) => {
                  setQuestionSelected(event?.value || "");
                }}
                value={(avaibleQuestions || [])?.find(
                  (avaible: any) => avaible?.value === questionSelected
                )}
                styles={customStyles}
                placeholder={"Question"}
                isClearable={false}
              />
            </>
          )}

          <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            options={fieldsAvaible}
            onChange={(values) => {
              if (
                values.find((value) => value.value === fieldsAvaible[0].value)
              ) {
                setFieldsFilter((values as typeof fieldsAvaible) || []);
              } else {
                toast({
                  title: `You can't remove ${fieldsAvaible[0].label} column.`,
                  status: "warning",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }}
            isMulti
            styles={customStyles as any}
            placeholder={"Columns"}
            isClearable={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={fieldsFilter}
          />

          <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            options={fieldsFilter}
            onChange={(value) => {
              setSortFilter(value);
            }}
            styles={customStyles as any}
            placeholder={"Sorting"}
            isClearable={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={sortFilter}
          />

          <Button
            variant={"outline_filter"}
            padding={"4px !important"}
            onClick={() => setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")}
          >
            <Icon
              transition={"300ms all"}
              as={FiArrowUp}
              transform={sortOrder === "ASC" ? "" : "rotate(0.5turn)"}
            />
          </Button>

          <Button
            minW={"70px"}
            variant={"outline_filter"}
            onClick={handleExportData}
          >
            {t("table_button_export")}
          </Button>
        </HStack>

        <VStack width={"100%"}>
          {isLoading ? (
            <Center width={"100%"} minH={"397px"}>
              <InfinitySpin color="#202951" />
            </Center>
          ) : (
            <>
              {tableData && tableData?.length > 0 ? (
                <VStack w={"100%"}>
                  <HStack
                    width={"100%"}
                    padding={"11px 24px"}
                    borderBottom={"1px solid"}
                    borderColor={"container_border_color"}
                  >
                    {Object.keys(tableData[0]).map((key, index) => {
                      const thisField: any = fieldsFilter.find(
                        (field) => field.value === key
                      );

                      const fields = fieldsFilter.filter((field) =>
                        Object.keys(tableData[0]).includes(field.value)
                      );

                      return (
                        thisField && (
                          <motion.div
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={defaultTransitionVariant}
                            key={index}
                            style={{
                              width: `calc(100% / ${fields.length})`,
                              margin: "0px",
                            }}
                          >
                            <Text
                              color={"black"}
                              fontSize={"16px"}
                              fontWeight={500}
                            >
                              {thisField.label}
                            </Text>
                          </motion.div>
                        )
                      );
                    })}
                  </HStack>

                  <VStack w={"100%"}>
                    {tableData.map((data: any, index: number) => (
                      <HStack
                        key={index}
                        w={"100%"}
                        padding={"20px 24px"}
                        borderBottom={"1px solid"}
                        borderColor={
                          tableData.length !== index + 1
                            ? "container_border_color"
                            : "transparent"
                        }
                      >
                        <HStack w={"100%"} position={"relative"}>
                          {(Object.keys(data) as Array<keyof typeof data>).map(
                            (key, index) => {
                              const thisField = fieldsFilter.find(
                                (field) => field.value === key
                              );

                              const fields = fieldsFilter.filter((field) =>
                                Object.keys(data).includes(field.value)
                              );

                              return (
                                thisField && (
                                  <motion.div
                                    key={index}
                                    initial="hidden"
                                    animate="visible"
                                    variants={defaultTransitionVariant}
                                    style={{
                                      width: `calc(100% / ${fields.length})`,
                                    }}
                                  >
                                    <Text
                                      color={"black"}
                                      fontSize={"14px"}
                                      fontWeight={400}
                                      textAlign={"start"}
                                      w={"100%"}
                                      flexWrap={"nowrap"}
                                      whiteSpace={"nowrap"}
                                      maxW={
                                        fieldsAvaible.find(
                                          (field) => field.value === "submitter"
                                        )
                                          ? fieldsFilter.length === 1
                                            ? "800px"
                                            : "400px"
                                          : "100%"
                                      }
                                      overflow={"hidden"}
                                      textOverflow={"ellipsis"}
                                    >
                                      {typeof data[key] === "boolean"
                                        ? data[key]
                                          ? "Yes"
                                          : "No"
                                        : data[key]?.name
                                        ? data[key].name
                                        : data[key]}
                                    </Text>
                                  </motion.div>
                                )
                              );
                            }
                          )}
                          {onEdit && (
                            <Button
                              position={"absolute"}
                              right={0}
                              height={"32px"}
                              variant={"solid_filter"}
                              onClick={() => onEdit(data)}
                            >
                              {t("table_button_edit")}
                            </Button>
                          )}
                        </HStack>
                      </HStack>
                    ))}
                  </VStack>
                </VStack>
              ) : (
                <Center width={"100%"} minH={"320px"}>
                  <Text
                    color={"black"}
                    textAlign={"center"}
                    fontSize={"18px"}
                    fontWeight={500}
                  >
                    {t("table_label_not_found_data")}
                  </Text>
                </Center>
              )}
            </>
          )}
        </VStack>
      </VStack>

      <Box w={"100%"} mt={"18px !important"}>
        <Paginate
          nextLabel={t("paginate_next")}
          previousLabel={t("paginate_previous")}
          onPageChange={(page) => setPage(page)}
          pageCount={total / 20}
        />
      </Box>
    </VStack>
  );
};

export default TableWrapper;
