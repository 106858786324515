import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { InfinitySpin } from "react-loader-spinner";
import { Props } from "./types";
import { motion } from "framer-motion";
import * as _ from "radash";
import TableService from "../../../../services/tables.service";
import QuickSearchInput from "../../../../components/base/QuickSearchInput";
import Paginate from "../../../../components/Paginate";
import { useTranslation } from "react-i18next";
import {
  ICategory,
  IProject,
  IQuestion,
  IQuestion_Identifier,
  IResponse,
  IResponseTags,
  ITag,
} from "../../../../types";
import CategoryService from "../../../../services/category.service";
import TagService from "../../../../services/tag.service";
import RowForm from "./RowForm";
import { MdOutlineClose } from "react-icons/md";
import Select from "react-select";
import { UserContext } from "../../../../providers/contexts/user";
import { SelectWModal } from "../../../../components/base/Select";
import i18next from "i18next";
import { FiArrowUp } from "react-icons/fi";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: () => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    borderRadius: "500px",
    cursor: "pointer",
    minWidth: "230px",
    maxWidth: "300px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    flexWrap: "nowrap !important",
  }),
  singleValue: (base: any) => ({
    ...base,
    textAlign: "center",
  }),

  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    borderRadius: "500px",
  }),
};
export interface IResponseWithProject extends IResponse {
  question_identifier: IQuestion_Identifier & {
    project: IProject;
  };
  responseTags: IResponseTags[];
}

interface IQuestionIdentifierWithResponses extends IQuestion_Identifier {
  responses: IResponseWithProject[];
  content: IQuestion[];
}

const defaultTransitionVariant = {
  visible: { opacity: 1, scale: 1 },
  hidden: { opacity: 0, scale: 0 },
};

const CategoryTable: React.FC<Props> = ({ categoryHasEditted }) => {
  const { projects, projectFiltered, setProjectFiltered } =
    useContext(UserContext);
  const [responseToView, setResponseToView] = useState<
    IResponseWithProject | undefined
  >();
  const [avaibleCategories, setAvaibleCategories] = useState<any[]>([]);
  const [avaibleTags, setAvaibleTags] = useState<ITag[]>([]);
  const [questionSelected, setQuestionSelected] = useState<string>("");

  const [sortFilter, setSortFilter] = useState<any>({
    label: "Category",
    value: "category",
  });
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");

  const [data, setData] = useState<IQuestionIdentifierWithResponses[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timer, setTimer] = useState<any>(null);
  const [filter, setFilter] = useState("");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const toast = useToast();
  const currentLanguage = i18next.languages[0];

  const columns = [
    t("category_table_column_response"),
    t("category_table_column_catName"),
    t("category_table_column_tag"),
  ];

  const populateData = useCallback(async () => {
    setIsLoading(true);
    if (projectFiltered) {
      try {
        const data = (
          await TableService.getTableData(
            `/question/category?limit=${20}&offset=${
              page * 20
            }&filter=${filter}&project_id=${projectFiltered}&question_id=${questionSelected}&sort=${
              sortFilter?.value
            }${sortFilter?.value && "&order=" + sortOrder}`
          )
        ).data;
        setData(data.result);
        setTotal(data.total);

        const categories: ICategory[] = (
          await CategoryService.getAllCategories()
        ).data;

        const newCat = categories.reduce((acc: any, item) => {
          if (acc[item?.project_id]) {
            return {
              ...acc,
              [item?.project_id]: [...acc[item?.project_id], item],
            };
          }
          return { ...acc, [item?.project_id]: [item] };
        }, []);
        setAvaibleCategories(newCat);

        const tags = await TagService.getDefaultTags();
        setAvaibleTags(tags);
      } catch (err) {
        toast({
          title: t("category_error_table_data_title"),
          description: t("category_error_table_data_description"),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  }, [
    toast,
    filter,
    page,
    projectFiltered,
    questionSelected,
    sortFilter,
    sortOrder,
  ]);

  const handleInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => setFilter(value), 300));
  };

  useEffect(() => {
    if (!categoryHasEditted) {
      populateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    categoryHasEditted,
    projectFiltered,
    questionSelected,
    sortFilter,
    sortOrder,
    filter
  ]);

  const avaibleQuestions = [
    ...(
      (projects?.find((pr) => pr?.id === projectFiltered)
        ?.questions_identifier as IQuestion_Identifier[]) || []
    )?.reduce(
      (acc, item) => [
        ...acc,
        {
          value: item?.id,
          label:
            item.content.find((item) => item.lang === currentLanguage)?.title ||
            item.content.find((item) => item.is_main)?.title,
        },
      ],
      [] as any
    ),
    { value: "", label: "All questions" },
  ];

  return (
    <VStack mt={"40px !important"} width={"100%"} position={"relative"}>
      {!projectFiltered && (
        <>
          <Box
            position={"absolute"}
            w={"100%"}
            h={"100%"}
            m={"0px !important"}
            justifyContent={"center"}
            display={"flex"}
            backdropFilter={"blur(3px)"}
            zIndex={10}
          />
          <Stack
            w={"100%"}
            h={"100%"}
            position={"absolute"}
            zIndex={11}
            alignItems={"center"}
          >
            <VStack
              minW={"400px"}
              padding={"32px"}
              background={"white"}
              w={"fit-content"}
              mt={"189px"}
              borderRadius={"8px"}
              justifyContent={"center"}
              border={"1px solid #9A9CAB"}
            >
              <Text
                color={"black"}
                fontSize={"16px"}
                fontWeight={500}
                mb={"0px !important"}
              >
                {t("choose_a_project_label")}
              </Text>

              <SelectWModal
                mt={"16px !important"}
                itemSelected={[
                  ...projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                  })),
                ].find((val) => val.value === projectFiltered)}
                items={[
                  ...projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                  })),
                ]}
                SelectItem={(item) => {
                  setProjectFiltered(item?.value || "");
                }}
                placeholder={t("nav_bar_projects")}
              />
            </VStack>
          </Stack>
        </>
      )}

      <VStack
        border={"1px solid"}
        borderColor={"container_border_color"}
        background={"white"}
        borderRadius={"12px"}
        width={"100%"}
      >
        <HStack
          padding={"24px"}
          width={"100%"}
          spacing={"8px"}
          overflow={"auto"}
          className={"custom-scroll"}
        >
          <QuickSearchInput onChange={(event) => handleInputSearch(event)} />

          <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            options={projects?.map((project) => ({
              label: project?.name,
              value: project?.id,
            }))}
            value={{
              label: projects.find((pr) => pr.id === projectFiltered)?.name,
              value: projectFiltered,
            }}
            onChange={(event) => setProjectFiltered(event?.value || "")}
            styles={customStyles}
            placeholder={"Project"}
            isClearable={false}
          />

          <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            options={avaibleQuestions}
            onChange={(event: any) => {
              setQuestionSelected(event?.value || "");
            }}
            value={(avaibleQuestions || [])?.find(
              (avaible: any) => avaible?.value === questionSelected
            )}
            styles={customStyles}
            placeholder={"Question"}
            isClearable={false}
          />

          <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            options={[{ label: "Category", value: "category" }]}
            onChange={(value) => {
              setSortFilter(value);
            }}
            styles={customStyles as any}
            placeholder={"Sorting"}
            isClearable={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={sortFilter}
          />

          <Button
            variant={"outline_filter"}
            padding={"4px !important"}
            onClick={() => setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")}
          >
            <Icon
              transition={"300ms all"}
              as={FiArrowUp}
              transform={sortOrder === "ASC" ? "" : "rotate(0.5turn)"}
            />
          </Button>
        </HStack>

        <VStack width={"100%"}>
          {isLoading ? (
            <Center width={"100%"} minH={"397px"}>
              <InfinitySpin color="#202951" />
            </Center>
          ) : (
            <>
              {data && data?.length > 0 ? (
                <VStack
                  w={"100%"}
                  borderTop={"1px solid"}
                  borderColor={"container_border_color"}
                >
                  {data.map((question, index) => (
                    <VStack
                      w={"100%"}
                      alignItems={"flex-start"}
                      p={"24px"}
                      borderBottom={"1px solid"}
                      borderColor={
                        data.length - 1 === index
                          ? "transparent"
                          : "container_border_color"
                      }
                    >
                      <Text
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"#414558"}
                      >
                        {t("category_table_column_project")}
                      </Text>
                      <Text
                        fontSize={"16px"}
                        fontWeight={500}
                        color={"black"}
                        mt={"8px !important"}
                      >
                        {question.content.find(
                          (item) => item.lang === currentLanguage
                        )?.title ||
                          question.content.find((item) => item.is_main)?.title}
                      </Text>

                      {question.responses.length >= 1 ? (
                        <>
                          <HStack width={"100%"} px={"24px"}>
                            <HStack
                              w={"100%"}
                              borderBottom={"1px solid"}
                              borderColor={"container_border_color"}
                              py={"11px"}
                            >
                              {columns.map((column, index) => (
                                <motion.div
                                  initial="hidden"
                                  animate="visible"
                                  exit="hidden"
                                  variants={defaultTransitionVariant}
                                  key={index}
                                  style={{
                                    width:
                                      column ===
                                      t("category_table_column_response")
                                        ? "40%"
                                        : "30%",
                                  }}
                                >
                                  <Text
                                    color={"black"}
                                    fontSize={"16px"}
                                    fontWeight={500}
                                  >
                                    {column}
                                  </Text>
                                </motion.div>
                              ))}
                            </HStack>
                          </HStack>

                          {question.responses.map(
                            (response: IResponseWithProject) => (
                              <RowForm
                                setAvaibleCategories={setAvaibleCategories}
                                avaibleCategories={avaibleCategories}
                                project_id={question.project_id}
                                populateResponses={populateData}
                                avaibleTags={avaibleTags}
                                responseData={response}
                                key={response.id}
                              />
                            )
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </VStack>
                  ))}
                </VStack>
              ) : (
                <Center width={"100%"} minH={"320px"}>
                  <Text
                    color={"black"}
                    textAlign={"center"}
                    fontSize={"18px"}
                    fontWeight={500}
                  >
                    {t("table_label_not_found_data")}
                  </Text>
                </Center>
              )}
            </>
          )}
        </VStack>
      </VStack>

      <Box w={"100%"} mt={"18px !important"}>
        <Paginate
          nextLabel={t("paginate_next")}
          previousLabel={t("paginate_previous")}
          onPageChange={(page) => setPage(page)}
          pageCount={total / 20}
        />
      </Box>

      {responseToView && (
        <Center
          position={"fixed"}
          background={"rgba(0, 0, 0, .5)"}
          top={0}
          left={0}
          w={"100%"}
          h={"100%"}
          m={"0px !important"}
          zIndex={"5"}
        >
          <VStack background={"white"} borderRadius={"12px"} maxW={"568px"}>
            <HStack
              minW={"568px"}
              padding={"24px"}
              justifyContent={"space-between"}
            >
              <Text color={"#00041A"} fontSize={"24px"} fontWeight={"600"}>
                {t("category_table_column_modal_text_response")}
              </Text>

              <Icon
                onClick={() => setResponseToView(undefined)}
                as={MdOutlineClose}
                w={"16px"}
                cursor={"pointer"}
              />
            </HStack>

            <HStack
              px={"24px"}
              py={"8px"}
              spacing={"24px"}
              w={"100%"}
              alignItems={"flex-start"}
              m={"0px !important"}
              pt={"0px"}
            >
              <VStack alignItems={"flex-start"}>
                <Text color={"#00041A"} fontSize={"14px"} fontWeight={400}>
                  {t("category_table_column_modal_text_active_project")}
                </Text>

                <Text
                  mt={"8px !important"}
                  color={"#00041A"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {responseToView?.question_identifier?.project?.name}
                </Text>
              </VStack>

              <VStack alignItems={"flex-start"}>
                <Text color={"#00041A"} fontSize={"14px"} fontWeight={400}>
                  {t("category_table_column_modal_text_category_name")}
                </Text>

                <Text
                  mt={"8px !important"}
                  color={"#00041A"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {(responseToView as any)?.newCategory?.label}
                </Text>
              </VStack>
            </HStack>
            <Stack w={"100%"} p={"24px"}>
              <Text
                mt={"8px !important"}
                color={"#00041A"}
                fontSize={"16px"}
                fontWeight={400}
              >
                {responseToView?.text}
              </Text>
            </Stack>
          </VStack>
        </Center>
      )}
    </VStack>
  );
};

export default CategoryTable;
