import React from "react";
import { HStack, Icon, Stack, Text } from "@chakra-ui/react";

type Props = {
  icon: any;
  label: string;
  isActive: boolean;
  isLast: boolean;
  onClick?: () => void;
};

const ItemContent: React.FC<Props> = ({
  icon,
  label,
  isActive,
  isLast,
  onClick,
}: Props) => {
  return (
    <HStack
      onClick={onClick}
      w={"100%"}
      marginTop={"0px !important"}
      marginBottom={isLast ? "12px !important" : "0px !important"}
      transition={"300ms all"}
      _hover={{
        background: "navbar_placeholder_color",
      }}
      cursor={"pointer"}
      borderRadius={"8px"}
      background={isActive ? "navbar_color" : "transparent"}
    >
      {isActive && (
        <Stack
          width={"4px"}
          height={"24px"}
          borderRadius={"4px"}
          background={"white"}
          color={"white"}
        />
      )}

      <HStack padding={"10px 0px"} ml={isActive ? "0px" : "16px"}>
        <Icon as={icon} color={isActive ? "white" : "placeholder_color"} />
        <Text
          ml={"14px !important"}
          fontWeight={500}
          fontSize={"14px"}
          color={isActive ? "white" : "placeholder_color"}
          flexWrap={"nowrap"}
          whiteSpace={"nowrap"}
        >
          {label}
        </Text>
      </HStack>
    </HStack>
  );
};

export default ItemContent;
