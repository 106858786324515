import React, { ReactNode, useEffect, useState } from "react";
import storageService from "../../../services/storage.service";
import UserService from "../../../services/user.service";
import { Credentials } from "./types";
import { useToast } from "@chakra-ui/react";
import { IProject, ISiteTag, IUser } from "../../../types";
import ProjectService from "../../../services/project.service";
import SiteTagService from "../../../services/site_tag.service";

type AuthProps = {
  projectFiltered: IProject["id"];
  setProjectFiltered: (str: IProject["id"]) => void;

  user?: IUser;
  projects: IProject[] | [];
  defaultSiteTags: ISiteTag[] | [];
  refreshProjects: () => Promise<void> | void;
  login: (credentials: Credentials) => Promise<void> | void;
  logout: () => Promise<void> | void;
};

export const UserContext = React.createContext<AuthProps>({
  projectFiltered: "",
  setProjectFiltered: () => undefined,
  user: undefined,
  projects: [],
  defaultSiteTags: [],
  refreshProjects: () => undefined,
  login: () => undefined,
  logout: () => undefined,
});

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [projectFiltered, setProjectFiltered] = useState<string>("");
  const [projects, setProjects] = useState<IProject[] | []>([]);
  const [defaultSiteTags, setDefaultSiteTags] = useState<ISiteTag[]>([]);
  const [user, setUser] = useState<IUser>();
  const toast = useToast();

  const refreshProjects = async () => {
    let newProjects: IProject[] = [];
    const allProjects: IProject[] = (
      await ProjectService.getProjectsWithFilter(false)
    ).data.result;

    if (user?.profile_code === "user") {
      allProjects.forEach((newProject) => {
        if (newProject.created_by === user?.id) {
          newProjects.push(newProject);
        }
      });
    } else {
      newProjects = allProjects;
    }

    setProjects(newProjects);
    setDefaultSiteTags(await SiteTagService.getDefaultTags());
  };

  useEffect(() => {
    setUser(storageService.getUser());
  }, []);

  useEffect(() => {
    refreshProjects();
  }, [user]);

  const logout = async () => {
    try {
      setUser(undefined);
      storageService.cleanStorage();
    } catch (err) {
      throw new Error("");
    }
  };

  const login = async (credentials: Credentials) => {
    try {
      const response = await UserService.login(credentials);
      const user = response.data as IUser;
      if (!user) throw new Error("Wrong credentials");

      setUser(user);

      storageService.setUser(user);
      storageService.setAccessToken(response.headers.token);
    } catch (err) {
      toast({
        title: "Login Error",
        description: "Email or password wrong.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        projects,
        refreshProjects,
        defaultSiteTags,
        setProjectFiltered,
        projectFiltered,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
