import { Button, HStack, Input, Stack, Text, VStack } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Props } from "./types";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const ChangePasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const schema = yup
    .object({
      oldPassword: yup.string().required(t("errors_empty_field")),
      newPassword: yup.string().required(t("errors_empty_field")),
      rePassword: yup
        .string()
        .required(t("errors_empty_field"))
        .oneOf([yup.ref("newPassword")], t("errors_different_passwords")),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <VStack w={"100%"} padding={"24px"}>
      <VStack w={"100%"} mb={"16px"}>
        <Controller
          name="oldPassword"
          control={control}
          rules={{ required: true }}
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error },
          }) => (
            <VStack w={"100%"}>
              <HStack w={"100%"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"black"}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                  ml={"auto !important"}
                  minW={"140px"}
                  mr={"32px"}
                >
                  {t("change_password_label_old_password")}
                </Text>
                <Input
                  type={"password"}
                  placeholder={t("change_password_placeholder_old_password")}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"40px"}
                />
              </HStack>
              {errors.oldPassword && (
                <Text
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"error"}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                  ml={"auto !important"}
                >
                  {(errors as any).oldPassword?.message}
                </Text>
              )}
            </VStack>
          )}
        />

        <Controller
          name="newPassword"
          control={control}
          rules={{ required: true }}
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error },
          }) => (
            <HStack w={"100%"} mt={"16px !important"} alignItems={"flex-start"}>
              <Text
                fontSize={"16px"}
                fontWeight={600}
                color={"black"}
                whiteSpace={"nowrap"}
                textAlign={"right"}
                ml={"auto !important"}
                minW={"140px"}
                mr={"32px"}
                mt={"8px !important"}
              >
                {t("change_password_label_new_password")}
              </Text>
              <VStack w={"100%"} alignItems={"flex-start"}>
                <Input
                  type={"password"}
                  placeholder={t("change_password_placeholder_new_password")}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"40px"}
                />
                <HStack w={"100%"} alignItems={"center"} mt={"8px !important"}>
                  <Text fontSize={"14px"} color={"#414558"} fontWeight={400}>
                    {t("change_password_text_min_caracters")}
                  </Text>
                  {errors.newPassword && (
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"error"}
                      whiteSpace={"nowrap"}
                      textAlign={"right"}
                      ml={"auto !important"}
                    >
                      {(errors as any).newPassword?.message}
                    </Text>
                  )}
                </HStack>
              </VStack>
            </HStack>
          )}
        />

        <Controller
          name="rePassword"
          control={control}
          rules={{ required: true }}
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error },
          }) => (
            <VStack w={"100%"} mt={"16px !important"}>
              <HStack w={"100%"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"black"}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                  ml={"auto !important"}
                  minW={"140px"}
                  mr={"32px"}
                >
                  {t("change_password_label_repassword")}
                </Text>
                <Input
                  type={"password"}
                  placeholder={t("change_password_placeholder_repassword")}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"40px"}
                />
              </HStack>
              {errors.rePassword && (
                <Text
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"error"}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                  ml={"auto !important"}
                >
                  {(errors as any).rePassword?.message}
                </Text>
              )}
            </VStack>
          )}
        />
      </VStack>
      <Stack w={"100%"} alignItems={"flex-end"}>
        <Button onClick={handleSubmit(onSubmit)}>
          {t("change_password_button_change")}
        </Button>
      </Stack>
    </VStack>
  );
};

export default ChangePasswordForm;
