import { Button, HStack, Stack, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FrameWrapper from "../../../../components/FrameWrapper";
import { Props } from "./types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InitialData from "./InitialData";
import Attachments from "./Attachments";
import ResponseDetail from "./ResponseDetails";
import { ITag } from "../../../../types";
import TagService from "../../../../services/tag.service";
import { InfinitySpin } from "react-loader-spinner";
import i18next from "../../../../i18n";

const ResponseForm: React.FC<Props> = ({
  currentResponse,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [defaultTags, setDefaultTags] = useState<ITag[]>();
  const currentLanguage = i18next.languages[0];

  const { reset, handleSubmit, control, getValues, watch } = useForm({
    defaultValues: useMemo(() => {
      return {
        ...currentResponse,
        avaibleResearchSites: {},
        questionSelected: {},
      };
    }, [currentResponse]),
  });

  useEffect(() => {
    if (currentResponse) {
      reset({
        ...currentResponse,
        responseTags:
          currentResponse?.responseTags?.map((responseTag: any) => ({
            value: responseTag.id,
            label: responseTag.tag.name,
            isOld: true,
          })) || [],

        avaibleResearchSites: {
          value: currentResponse?.research_site?.id,
          label: currentResponse?.research_site?.address,
        },

        questionSelected: {
          value: currentResponse?.question_identifier.id,
          label:
            (
              currentResponse?.question_identifier.content.find(
                (content) => content.lang === currentLanguage
              ) ||
              currentResponse?.question_identifier.content.find(
                (content) => content.is_main
              )
            )?.title || "",
        },
      });
      if (!("id" in currentResponse)) {
        reset({});
      }
    }
  }, [currentResponse, reset]);

  useEffect(() => {
    const populateTags = async () => {
      setDefaultTags(await TagService.getDefaultTags());
    };
    populateTags();
  }, []);

  return (
    <VStack
      position={"absolute"}
      maxH={currentResponse ? "100%" : "0%"}
      overflow={currentResponse ? "auto" : "hidden"}
      transition={"500ms all"}
      width={"100%"}
      zIndex={"2"}
      background={"#F9FBFB"}
    >
      <HStack spacing={"16px"} width={"100%"} alignItems={"flex-start"}>
        <VStack flex={0.6} m={"0px !important"}>
          <FrameWrapper>
            <InitialData control={control} values={getValues} />
          </FrameWrapper>

          <Stack mt={"16px"} />
          <FrameWrapper>
            {defaultTags ? (
              <ResponseDetail
                defaultTags={defaultTags.filter(
                  (tag) =>
                    tag.project_id ===
                    currentResponse?.question_identifier.project_id
                )}
                control={control}
                values={getValues}
              />
            ) : (
              <InfinitySpin width="200" color="#000000" />
            )}
          </FrameWrapper>
        </VStack>
        <Stack flex={0.4}>
          <FrameWrapper>
            <Attachments
              watch={watch("attachments")}
              control={control}
              values={getValues}
            />
          </FrameWrapper>
        </Stack>
      </HStack>

      <HStack mt={"6px"} w={"100%"} justifyContent={"center"} spacing={"16px"}>
        {currentResponse && "id" in currentResponse && (
          <Button
            variant={"outline_delete"}
            onClick={() => onDelete(currentResponse.id)}
          >
            {t("responses_form_button_delete")}
          </Button>
        )}

        <Button variant={"solid"} onClick={handleSubmit(onSubmit)}>
          {t("responses_form_button_save")}
        </Button>
      </HStack>
    </VStack>
  );
};

export default ResponseForm;
