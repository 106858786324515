import {
  Box,
  Button,
  Center,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { UserContext } from "../../providers/contexts/user";
import Select from "react-select";
import {
  IQuestion_Identifier,
  IResearchSite,
  IResearchSiteTags,
} from "../../types";
import { TagCloud } from "react-tagcloud";
import HeadquartersChart from "./HeadquartersChart";
import { SelectWModal } from "../base/Select";
import i18next from "i18next";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: () => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    borderRadius: "500px",
    cursor: "pointer",
    minWidth: "230px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    flexWrap: "nowrap !important",
  }),
  singleValue: (base: any) => ({
    ...base,
    textAlign: "center",
  }),

  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    borderRadius: "500px",
  }),
};

export const HeatTable: React.FC<{
  data: Array<Array<any>>;
  onSelectProject?: (id: string) => void;
  onSelectResearch: (id: string) => void;
  onSelectSiteTag: (values: any[]) => void;
  onSelectTag: (id: string) => void;
  isTagCloud?: boolean;
  selectedProject: string;
  selectedSiteTag: any[];
  selectedResearch: string;
  selectedTag: string;
  title: string;
  showSiteDescriptors?: boolean;
}> = ({
  data,
  isTagCloud,
  onSelectProject,
  onSelectSiteTag,
  onSelectTag,
  selectedSiteTag,
  selectedProject,
  onSelectResearch,
  selectedResearch,
  selectedTag,
  title,
  showSiteDescriptors = false,
}) => {
  const { defaultSiteTags } = useContext(UserContext);
  const [exportData, setExport] = useState(false);
  const { projects } = useContext(UserContext);
  const { t } = useTranslation();
  const currentLanguage = i18next.languages[0];

  const avaibleQuestions = [
    ...(
      (projects?.find((pr) => pr?.id === selectedProject)
        ?.questions_identifier as IQuestion_Identifier[]) || []
    )?.reduce(
      (acc, item) => [
        ...acc,
        {
          value: item?.id,
          label:
            item.content.find((item) => item.lang === currentLanguage)?.title ||
            item.content.find((item) => item.is_main)?.title,
        },
      ],
      [] as any
    ),
    { value: "", label: "All questions" },
  ];

  return data && projects ? (
    <VStack maxH={"100%"} w={"100%"} h={"100%"} alignItems={"flex-start"}>
      {!selectedProject && (
        <>
          <Box
            position={"absolute"}
            w={"100%"}
            h={"100%"}
            m={"0px !important"}
            justifyContent={"center"}
            display={"flex"}
            backdropFilter={"blur(3px)"}
            zIndex={10}
          />
          <Stack
            w={"100%"}
            h={"100%"}
            position={"absolute"}
            zIndex={11}
            alignItems={"center"}
          >
            <VStack
              minW={"400px"}
              padding={"32px"}
              background={"white"}
              w={"fit-content"}
              mt={"189px"}
              borderRadius={"8px"}
              justifyContent={"center"}
              border={"1px solid #9A9CAB"}
            >
              <Text
                color={"black"}
                fontSize={"16px"}
                fontWeight={500}
                mb={"0px !important"}
              >
                {t("choose_a_project_label")}
              </Text>

              <SelectWModal
                mt={"16px !important"}
                itemSelected={[
                  ...projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                  })),
                ].find((val) => val.value === selectedProject)}
                items={[
                  ...projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                  })),
                ]}
                SelectItem={(item) => {
                  onSelectProject && onSelectProject(item?.value || "");
                }}
                placeholder={t("nav_bar_projects")}
              />
            </VStack>
          </Stack>
        </>
      )}

      <HStack
        spacing={"8px"}
        maxWidth={"100%"}
        minHeight={"60px"}
        overflow={"auto"}
        className={"custom-scroll"}
      >
        {!isTagCloud && (
          <Button
            minW={"70px"}
            variant={"outline_filter"}
            onClick={() => setExport(!exportData)}
          >
            {t("table_button_export")}
          </Button>
        )}

        <Select
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          options={[
            ...projects?.map((project) => ({
              label: project?.name,
              value: project?.id,
            })),
          ]}
          onChange={(event) => {
            onSelectProject && onSelectProject(event?.value || "");
            onSelectResearch("");
          }}
          value={[
            ...projects?.map((project) => ({
              label: project?.name,
              value: project?.id,
            })),
          ].find((val) => val.value === selectedProject)}
          styles={customStyles}
          placeholder={"Project"}
          isClearable={false}
        />

        <Select
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          options={[
            ...(
              (projects?.find((project) => project?.id === selectedProject)
                ?.researchSites as IResearchSite[]) || []
            )?.map((project) => ({
              label: project?.name,
              value: project?.id,
            })),
            { label: t("all_sites_placeholder") || "", value: "" },
          ]}
          onChange={(event) =>
            onSelectResearch && onSelectResearch(event?.value || "")
          }
          value={[
            ...(
              (projects?.find((project) => project?.id === selectedProject)
                ?.researchSites as IResearchSite[]) || []
            )?.map((project) => ({
              label: project?.name,
              value: project?.id,
            })),
            { label: t("all_sites_placeholder") || "", value: "" },
          ].find((val) => val.value === selectedResearch)}
          styles={customStyles}
          placeholder={"Site"}
          isClearable={false}
        />

        <Select
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          options={avaibleQuestions}
          onChange={(event: any) => {
            onSelectTag(event?.value || "");
          }}
          value={(avaibleQuestions || [])?.find(
            (avaible: any) => avaible?.value === selectedTag
          )}
          styles={customStyles}
          placeholder={"Question"}
          isClearable={false}
        />

        {showSiteDescriptors && (
          <Select
            options={
              selectedProject
                ? projects
                    .find((project) => project.id === selectedProject)
                    ?.researchSites.reduce(
                      (acc, item) => [
                        ...acc,
                        ...(item.research_site_tags as any[]).reduce(
                          (sacc: any[], sitem: IResearchSiteTags) => [
                            ...sacc,
                            {
                              value: sitem.site_tag.id,
                              label: sitem.site_tag.name,
                            },
                          ],
                          [] as any[]
                        ),
                      ],
                      [] as any[]
                    )
                : defaultSiteTags.map((tag) => ({
                    value: tag.id,
                    label: tag.name,
                  }))
            }
            isMulti
            placeholder={t("all_sites_descriptors_placeholder")}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            onChange={(val: any) => onSelectSiteTag(val)}
            styles={customStyles}
            value={selectedSiteTag}
          />
        )}
      </HStack>
      {isTagCloud ? (
        <Center w={"100%"} h={"100%"}>
          <TagCloud minSize={12} maxSize={35} tags={data} />
        </Center>
      ) : (
        <HeadquartersChart data={data} exportData={exportData} title={title} />
      )}
    </VStack>
  ) : (
    <></>
  );
};
