import {  Icon } from "@chakra-ui/react";
import { FiAlertCircle } from "react-icons/fi";
import { Props } from "./types";

const CustomFieldError: React.FC<Props> = ({ haveError }) => {
  return (
    <>
      {haveError && (
        <Icon
          as={FiAlertCircle}
          color={"delete_color"}
          w={"14px"}
          mr={"8px !important"}
        />
      )}
    </>
  );
};

export default CustomFieldError;
