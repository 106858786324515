import { HStack } from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import LoggedInHeader from "../components/loggedInHeader";
import Navbar from "../components/navbar";
import useDeviceDetection from "../hooks/IsMobile";
import { UserContext } from "../providers/contexts/user";

type Props = {
  children: ReactNode;
};

const LoggedRoute: React.FC<Props> = ({ children }: Props) => {
  const { user } = useContext(UserContext);
  const { isDesktop } = useDeviceDetection();

  if (!user) {
    return <Navigate to={"/login"} replace />;
  }

  return !isDesktop ? (
    <LoggedInHeader>{children}</LoggedInHeader>
  ) : (
    <Navbar>
      <LoggedInHeader>{children}</LoggedInHeader>
    </Navbar>
  );
};

export default LoggedRoute;
