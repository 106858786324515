import React, { ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import lightTheme from "../assets/theme/light";
import UserContextProvider from "./contexts/user";

type Props = {
  children: ReactNode;
};

const Providers = ({ children }: Props) => {
  return (
    <BrowserRouter>
      <ChakraProvider theme={lightTheme}>
        <UserContextProvider>{children}</UserContextProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default Providers;
