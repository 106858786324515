import { Button, HStack, Stack, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FrameWrapper from "../../../../components/FrameWrapper";
import InitialData from "./InitialData";
import { Props } from "./types";
import { useContext, useEffect, useMemo } from "react";
import ProjectDetails from "./ProjectDetails";
import Questions from "./Questions";
import ResearchSites from "./ResearchSites";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../../providers/contexts/user";

const ProjectForm: React.FC<Props> = ({
  currentProject,
  onSubmit,
  onDelete,
  saveButtonRef,
}) => {
  const { defaultSiteTags } = useContext(UserContext);
  const { t } = useTranslation();
  const { control, getValues, reset, handleSubmit, watch } = useForm({
    defaultValues: useMemo(() => {
      return { ...currentProject, qrCodeSite: {}, primary_collor: "#000000" };
    }, [currentProject]),
  });

  useEffect(() => {
    if (currentProject) {
      reset({
        ...currentProject,
        ...("researchSites" in currentProject &&
          currentProject.researchSites && {
            qrCodeSite: {
              label: currentProject?.researchSites[0].name,
              value: currentProject?.researchSites[0],
            },
          }),
      });
      if (!("id" in currentProject)) {
        reset({});
      }
    }
  }, [currentProject, reset]);

  return (
    <VStack
      position={"absolute"}
      maxH={currentProject ? "100%" : "0%"}
      overflow={currentProject ? "auto" : "hidden"}
      transition={"500ms all"}
      width={"100%"}
      zIndex={"2"}
      background={"#F9FBFB"}
    >
      <FrameWrapper padding="0px">
        <InitialData
          control={control}
          values={getValues()}
          watchQr={watch("qrCodeSite")}
        />
      </FrameWrapper>

      <Stack mt={"16px"} />
      <FrameWrapper>
        <ProjectDetails control={control} />
      </FrameWrapper>

      <Stack mt={"16px"} />
      <FrameWrapper padding="0px">
        <Questions control={control} values={getValues} />
      </FrameWrapper>

      <Stack mt={"16px"} />
      <FrameWrapper padding="0px">
        <ResearchSites
          control={control}
          values={getValues}
          defaultSiteTags={defaultSiteTags}
        />
      </FrameWrapper>

      <HStack mt={"6px"} w={"100%"} justifyContent={"center"} spacing={"16px"}>
        {currentProject && "id" in currentProject && (
          <Button
            variant={"outline_delete"}
            onClick={() => onDelete(currentProject.id)}
          >
            {t("projects_form_button_delete")}
          </Button>
        )}

        <Button
          variant={"solid"}
          onClick={handleSubmit(onSubmit)}
          ref={saveButtonRef}
          display={"none"}
        >
          {t("projects_form_button_save")}
        </Button>
      </HStack>
    </VStack>
  );
};

export default ProjectForm;
