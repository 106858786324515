import { Props } from "./types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Switch,
  HStack,
  Center,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { Input } from "../../../../components/base/Input";
import { SelectWModal } from "../../../../components/base/Select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InfinitySpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const EditUserForm: React.FC<Props> = ({
  currentUser,
  onSubmit,
  onClose,
  onDelete,
  isLoading,
}) => {
  const { t } = useTranslation();
  const schema =
    "id" in currentUser
      ? yup.object({
          name: yup.string().required(t("errors_empty_field")),
        })
      : yup.object({
          name: yup.string().required(t("errors_empty_field")),
          email: yup.string().email(t("errors_invalid_email")),
          password: yup.string().required(t("errors_empty_field")),
          rePassword: yup
            .string()
            .required(t("errors_empty_field"))
            .oneOf([yup.ref("password")], t("errors_different_passwords")),
        });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const profileCodeOptions = [
    { label: t("edit_user_option_user"), value: "user" },
    { label: t("edit_user_option_admin"), value: "admin" },
  ];

  useEffect(() => {
    if (currentUser) {
      if ("id" in currentUser) {
        reset({
          ...currentUser,
          password: "",
          profile_code: {
            value: currentUser.profile_code,
            label: profileCodeOptions.find(
              (profile) => profile.value === currentUser.profile_code
            )?.label,
          },
        });
      } else {
        reset({
          profile_code: profileCodeOptions[0],
          is_active: true,
        });
      }
    }
  }, [currentUser, reset]);

  return (
    <Modal isOpen={!!currentUser} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader w={"100%"} alignItems={"start"}>
          {"id" in currentUser ? "Edit user" : "Create user"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Center h={"200px"} w={"100%"}>
              <InfinitySpin color="#202951" />
            </Center>
          ) : (
            <>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onBlur, onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    type={"text"}
                    placeholder={t("edit_user_placeholder_name")}
                    hasError={!!errors.name}
                    errorMessage={(errors as any)?.name?.message}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    height={"56px"}
                  />
                )}
              />
              {!("id" in currentUser) && (
                <>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Input
                        mt={"16px !important"}
                        type={"password"}
                        placeholder={t("edit_user_placeholder_password")}
                        hasError={!!errors.password}
                        errorMessage={(errors as any)?.password?.message}
                        value={value || ""}
                        onChange={onChange}
                        onBlur={onBlur}
                        height={"56px"}
                      />
                    )}
                  />

                  <Controller
                    name="rePassword"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Input
                        mt={"16px !important"}
                        type={"password"}
                        placeholder={t("edit_user_placeholder_repassword")}
                        hasError={!!errors.rePassword}
                        errorMessage={(errors as any)?.rePassword?.message}
                        value={value || ""}
                        onChange={onChange}
                        onBlur={onBlur}
                        height={"56px"}
                      />
                    )}
                  />
                </>
              )}

              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <Input
                    mt={"16px !important"}
                    type={"text"}
                    placeholder={t("edit_user_placeholder_email")}
                    hasError={!!errors.email}
                    errorMessage={(errors as any)?.email?.message}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    height={"56px"}
                  />
                )}
              />

              <Controller
                name="profile_code"
                control={control}
                rules={{ required: true }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <SelectWModal
                    mt={"16px !important"}
                    itemSelected={value}
                    items={profileCodeOptions}
                    SelectItem={onChange}
                    placeholder={t("edit_user_placeholder_profile_code")}
                  />
                )}
              />

              <Controller
                name="is_active"
                control={control}
                rules={{ required: false }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <HStack alignItems={"center"} mt={"16px !important"}>
                    <Text
                      mr={"16px !important"}
                      fontSize={"16px"}
                      fontWeight={400}
                      color={"#00041A"}
                    >
                      {t("edit_user_placeholder_profile_is_active")}
                    </Text>
                    <Switch
                      isChecked={value}
                      onChange={() => onChange(!value)}
                      size="lg"
                    />
                  </HStack>
                )}
              />
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <>
            {!isLoading && (
              <>
                {"id" in currentUser && (
                  <Button
                    onClick={() => onDelete(currentUser.id)}
                    variant={"outline_delete"}
                    mr={"8px"}
                  >
                    {t("edit_user_button_delete_user")}
                  </Button>
                )}

                <Button onClick={handleSubmit(onSubmit)}>
                  {"id" in currentUser
                    ? t("edit_user_button_update_user")
                    : t("edit_user_button_create_user")}
                </Button>
              </>
            )}
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditUserForm;
