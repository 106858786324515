import {
  HStack,
  VStack,
  Text,
  Input,
  Stack,
  Button,
  Box,
  useClipboard,
  useToast,
  useTooltip,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InitialDataFormValues, Props } from "./types";
import Select from "react-select";
import { format, isValid } from "date-fns";
import { enUS } from "date-fns/locale";
import CustomFieldError from "../../../../../components/base/CustomFieldError";
import { QRCodeSVG } from "qrcode.react";
import { BlockPicker } from "react-color";
import { IResearchSite, IResearchSiteTags } from "../../../../../types";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: (base: any) => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    cursor: "pointer",
    minWidth: "102px",
    background: "white",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

const formatDate = (date: Date | undefined) => {
  if (date && isValid(date)) {
    return format(date, "dd MMM yyyy HH:mm", { locale: enUS });
  }
  return "";
};

type forcedProps = {
  control: Control<InitialDataFormValues, any>;
  values: Props["values"];
  watchQr: { label: string; value: IResearchSite };
};

const InitialData: React.FC<Props> = ({
  values,
  control,
  watchQr,
}: forcedProps) => {
  const { t } = useTranslation();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const { onCopy } = useClipboard(
    process.env.REACT_APP_TABLE_HOSTER_URL + watchQr?.value?.id
  );
  const toast = useToast();

  const onCopyQR = () => {
    onCopy();

    toast({
      title: t("on_copy_qr_code_title"),
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <HStack
      width={"100%"}
      spacing={"35px"}
      alignItems={"flex-start"}
      minHeight={"fit-content"}
      overflow={"auto"}
      padding={"24px"}
    >
      <VStack width={"100%"}>
        <HStack alignItems={"center"} justifyContent={"flex-end"} w={"100%"}>
          <Controller
            name={"name"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.name} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("projects_form_initial_data_place_holder_input_name")}
                  </Text>
                </HStack>
                <Input
                  placeholder={t(
                    "projects_form_initial_data_place_holder_input_name"
                  )}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"32px"}
                  focusBorderColor={"#F0F3F5"}
                />
              </>
            )}
          />
        </HStack>

        <HStack
          alignItems={"center"}
          justifyContent={"flex-end"}
          w={"100%"}
          mt={"24px !important"}
        >
          <Controller
            name={"short_name"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.short_name} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("projects_form_initial_data_label_input_short_name")}
                  </Text>
                </HStack>

                <Input
                  placeholder={t(
                    "projects_form_initial_data_place_holder_input_short_name"
                  )}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"32px"}
                  focusBorderColor={"#F0F3F5"}
                />
              </>
            )}
          />
        </HStack>

        <HStack
          alignItems={"center"}
          justifyContent={"flex-end"}
          w={"100%"}
          mt={"24px !important"}
        >
          <Controller
            name={"coordinator"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.coordinator} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t(
                      "projects_form_initial_data_label_input_short_coordinator"
                    )}
                  </Text>
                </HStack>
                <Input
                  placeholder={t(
                    "projects_form_initial_data_place_holder_input_coordinator"
                  )}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"32px"}
                  focusBorderColor={"#F0F3F5"}
                />
              </>
            )}
          />
        </HStack>

        <HStack
          alignItems={"center"}
          justifyContent={"flex-end"}
          w={"100%"}
          mt={"24px !important"}
        >
          <Controller
            name={"is_active"}
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.is_active} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("projects_form_initial_data_label_input_short_status")}
                  </Text>
                </HStack>

                <HStack spacing={"16px"} mr={"auto !important"}>
                  <Button
                    onClick={() => onChange(true)}
                    variant={value ? "solid" : "outline"}
                  >
                    {t("projects_form_initial_data_button_yes")}
                  </Button>
                  <Button
                    onClick={() => onChange(false)}
                    variant={value ? "outline" : "solid"}
                  >
                    {t("projects_form_initial_data_button_no")}
                  </Button>
                </HStack>
              </>
            )}
          />
        </HStack>
      </VStack>

      <VStack width={"100%"} alignItems={"center"}>
        <Controller
          name={"qrCodeSite"}
          control={control}
          render={({
            field: { value, onChange, onBlur },
            formState: { errors },
          }) => (
            <HStack
              width={"100%"}
              padding={"16px"}
              background={"container_border_color"}
              borderRadius={"12px"}
              justifyContent={"space-between"}
              w={"476px"}
            >
              {"id" in values ? (
                <>
                  <VStack
                    height={"100%"}
                    justifyContent={"flex-start"}
                    textOverflow={"ellipsis"}
                    maxW={"70%"}
                    overflow={"hidden"}
                  >
                    <HStack alignItems={"center"} w={"100%"}>
                      <Text
                        mr={"32px !important"}
                        fontSize={"16px"}
                        fontWeight={600}
                        color={"black"}
                        whiteSpace={"nowrap"}
                        minW={"100px"}
                        textAlign={"left"}
                      >
                        {t("projects_form_initial_data_label_region")}
                      </Text>
                      <Select
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        options={values.researchSites.map((value) => ({
                          label: value.name,
                          value: value,
                        }))}
                        onChange={onChange}
                        styles={customStyles as any}
                        placeholder={""}
                        isClearable={false}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        value={value}
                      />
                    </HStack>
                    <HStack
                      alignItems={"flex-start"}
                      w={"100%"}
                      mt={"24px !important"}
                    >
                      <Text
                        mr={"32px !important"}
                        fontSize={"16px"}
                        fontWeight={600}
                        color={"black"}
                        whiteSpace={"nowrap"}
                        minW={"100px"}
                        textAlign={"left"}
                      >
                        {t("projects_form_initial_data_label_created")}
                      </Text>
                      <Text
                        fontSize={"16px"}
                        fontWeight={400}
                        color={"black"}
                        whiteSpace={"nowrap"}
                        textAlign={"left"}
                      >
                        {formatDate(new Date(value?.value.createdAt || ""))}
                      </Text>
                    </HStack>
                    <HStack
                      alignItems={"flex-start"}
                      w={"100%"}
                      mt={"24px !important"}
                    >
                      <Text
                        textOverflow={"ellipsis"}
                        mr={"10px !important"}
                        fontSize={"16px"}
                        fontWeight={600}
                        color={"black"}
                        whiteSpace={"nowrap"}
                        minW={"100px"}
                        textAlign={"left"}
                      >
                        {t("projects_form_initial_data_label_site")}
                      </Text>
                      <Tooltip
                        cursor={"pointer"}
                        bg={"#FFFFFF"}
                        placement="top"
                        label={(
                          value?.value.research_site_tags as IResearchSiteTags[]
                        ).reduce(
                          (acc, item, index) =>
                            acc +
                            item.site_tag.name +
                            ((
                              value?.value
                                .research_site_tags as IResearchSiteTags[]
                            ).length !==
                            index + 1
                              ? ", "
                              : ""),
                          ""
                        )}
                      >
                        <Text
                          cursor={"pointer"}
                          fontSize={"16px"}
                          fontWeight={400}
                          color={"black"}
                          whiteSpace={"nowrap"}
                          textAlign={"left"}
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                        >
                          {(
                            value?.value
                              .research_site_tags as IResearchSiteTags[]
                          ).reduce(
                            (acc, item, index) =>
                              acc +
                              item.site_tag.name +
                              ((
                                value?.value
                                  .research_site_tags as IResearchSiteTags[]
                              ).length !==
                              index + 1
                                ? ", "
                                : ""),
                            ""
                          )}
                        </Text>
                      </Tooltip>
                    </HStack>
                  </VStack>
                  <VStack>
                    <Stack
                      width={"104px"}
                      height={"104px"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      background={"white"}
                      borderRadius={"8px"}
                      padding={"8px"}
                    >
                      <QRCodeSVG
                        value={`${process.env.REACT_APP_TABLE_HOSTER_URL}${value?.value?.id}`}
                      />
                    </Stack>

                    <HStack
                      w={"100%"}
                      background={"white"}
                      borderRadius={"8px"}
                      padding={"8px"}
                      justifyContent={"center"}
                      cursor={"pointer"}
                      onClick={onCopyQR}
                    >
                      <Text
                        textAlign={"center"}
                        fontSize={"12px"}
                        fontWeight={600}
                      >
                        {t("projects_form_initial_data_label_qr_code_copy_URL")}
                      </Text>
                    </HStack>
                  </VStack>
                </>
              ) : (
                <Text textAlign={"center"}>
                  {t("projects_form_initial_data_label_qr_code")}
                </Text>
              )}
            </HStack>
          )}
        />
        <HStack
          mt={"8px !important"}
          alignItems={"flex-start"}
          justifyContent={"center"}
          w={"100%"}
        >
          <Controller
            name={"primary_collor"}
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <HStack
                background={"container_border_color"}
                padding={"4px 16px"}
                borderRadius={"8px"}
              >
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.primary_collor} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("projects_form_initial_data_label_project_color")}
                  </Text>
                </HStack>

                <HStack w={"100%"} alignItems={"center"}>
                  <Box
                    width={"22px"}
                    height={"22px"}
                    borderRadius={"11px"}
                    bg={value || "#000000"}
                    border={"1px solid"}
                    borderColor={"container_border_color"}
                    mr={"8px !important"}
                    position={"relative"}
                    cursor={"pointer"}
                    onClick={() => setShowColorPicker(true)}
                  >
                    {showColorPicker && (
                      <Box
                        position={"absolute"}
                        bottom={"24px"}
                        left={"-10px"}
                        zIndex={9999}
                      >
                        <BlockPicker
                          onChangeComplete={() => setShowColorPicker(false)}
                          onChange={(event) => {
                            onChange(event.hex);
                          }}
                          color={value || "#000000"}
                          triangle={"hide"}
                        />
                      </Box>
                    )}
                  </Box>
                  <Text m={"0px !important"} fontWeight={500} fontSize={"14px"}>
                    {value || "#000000"}
                  </Text>
                </HStack>
              </HStack>
            )}
          />
        </HStack>
      </VStack>
    </HStack>
  );
};

export default InitialData;
