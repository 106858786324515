import { VStack } from "@chakra-ui/react";
import { Props } from "./types";

const FrameWrapper: React.FC<Props> = ({ children, padding = "24px" }) => {
  return (
    <VStack
      border={"1px solid "}
      borderColor={"container_border_color"}
      background={"white"}
      borderRadius={"12px"}
      width={"100%"}
      position={'relative'}
      padding={padding}
    >
      {children}
    </VStack>
  );
};

export default FrameWrapper;
