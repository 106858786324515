import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import LoginPage from "../pages/Login";
import CategoriesPage from "../pages/Overview/Categories";
import ProjectsPage from "../pages/Overview/Projects";
import ResponsesPage from "../pages/Overview/Responses";
import CategoryXTagPage from "../pages/Reports/CategoryXTags";
import RegionXTagPage from "../pages/Reports/RegionXTags";
import TagCloudPage from "../pages/Reports/TagCloud";
import TopTags from "../pages/Reports/TopTags";
import ResponseForm from "../pages/ResponseForm";
import SettingsPage from "../pages/User/Settings";
import LoggedOutRoute from "./logged-out-route";
import LoggedRoute from "./logged-route";

const Router = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <LoggedOutRoute>
            <LoginPage />
          </LoggedOutRoute>
        }
      />

      <Route
        path="/dashboard"
        element={
          <LoggedRoute>
            <Dashboard />
          </LoggedRoute>
        }
      />

      <Route
        path="/overview/projects"
        element={
          <LoggedRoute>
            <ProjectsPage />
          </LoggedRoute>
        }
      />

      <Route
        path="/overview/responses"
        element={
          <LoggedRoute>
            <ResponsesPage />
          </LoggedRoute>
        }
      />

      <Route
        path="/overview/response/:id"
        element={
          <LoggedRoute>
            <ResponseForm />
          </LoggedRoute>
        } 
      />

      <Route
        path="/overview/categories"
        element={
          <LoggedRoute>
            <CategoriesPage />
          </LoggedRoute>
        }
      />

      <Route
        path="/reports/tags"
        element={
          <LoggedRoute>
            <TopTags />
          </LoggedRoute>
        }
      />

      <Route
        path="/reports/tagcloud"
        element={
          <LoggedRoute>
            <TagCloudPage />
          </LoggedRoute>
        }
      />

      <Route
        path="/reports/categories"
        element={
          <LoggedRoute>
            <CategoryXTagPage />
          </LoggedRoute>
        }
      />

      <Route
        path="/reports/sitedescriptors"
        element={
          <LoggedRoute>
            <RegionXTagPage />
          </LoggedRoute>
        }
      />

      <Route
        path="/overview/responses"
        element={
          <LoggedRoute>
            <ResponsesPage />
          </LoggedRoute>
        }
      />

      <Route
        path="/user/settings"
        element={
          <LoggedRoute>
            <SettingsPage />
          </LoggedRoute>
        }
      />

      <Route path="*" element={<Navigate to={"/login"} replace />} />
    </Routes>
  );
};

export default Router;
