import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import enTranslation from "./langs/en";
import ptTranslation from "./langs/pt";
import esTranslation from "./langs/es";
import czTranslation from "./langs/cz";

export const resources: any = {
  en: { translation: enTranslation, label: "English" },
  pt: { translation: ptTranslation, label: "Portuguese" },
  es: { translation: esTranslation, label: "Spanish" },
  cz: { translation: czTranslation, label: "Czech" },
};

i18next.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export default i18next;
