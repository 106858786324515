import { HStack, VStack, Text, Input, Stack, Switch } from "@chakra-ui/react";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InitialDataFormValues, Props } from "./types";
import Select from "react-select";
import CustomFieldError from "../../../components/base/CustomFieldError";

type forcedProps = {
  control: Control<InitialDataFormValues, any>;
  values: () => InitialDataFormValues;
};

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: (base: any) => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    cursor: "pointer",
    minWidth: "102px",
    background: "white",
    width: "100%",
    borderRadius: "6px",
  }),
  container: (base: any) => ({
    ...base,
    width: "100%",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

const InitialData: React.FC<Props> = ({ values, control }: forcedProps) => {
  const { t } = useTranslation();

  const priority = [
    { value: "Low", label: t("responses_response_initial_data_priority_low") },
    {
      value: "Medium",
      label: t("responses_response_initial_data_priority_medium"),
    },
    {
      value: "High",
      label: t("responses_response_initial_data_priority_high"),
    },
  ];

  return (
    <HStack
      width={"100%"}
      spacing={"35px"}
      alignItems={"flex-start"}
      minHeight={"fit-content"}
      overflow={"auto"}
      padding={"24px"}
    >
      <VStack width={"100%"}>
        <HStack
          alignItems={"center"}
          justifyContent={"flex-end"}
          w={"100%"}
          mb={"24px !important"}
        >
          <Controller
            name={"projectRef"}
            control={control}
            rules={{ required: false }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.projectRef} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("responses_response_details_project_label")}
                  </Text>
                </HStack>

                <Input
                  placeholder={t(
                    "projects_form_initial_data_place_holder_input_short_name"
                  )}
                  value={values()?.question_identifier?.project?.name || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"32px"}
                  focusBorderColor={"#F0F3F5"}
                  isDisabled
                />
              </>
            )}
          />
        </HStack>

        <HStack
          alignItems={"center"}
          justifyContent={"flex-end"}
          w={"100%"}
          m={"0px !important"}
        >
          <Controller
            name={"submitter"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.submitter} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("responses_response_initial_data_label_submitter")}
                  </Text>
                </HStack>
                <Input
                  placeholder={t(
                    "projects_form_initial_data_place_holder_input_name"
                  )}
                  value={value || ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  height={"32px"}
                  focusBorderColor={"#F0F3F5"}
                />
              </>
            )}
          />
        </HStack>

        <HStack
          alignItems={"center"}
          justifyContent={"flex-end"}
          w={"100%"}
          mt={"24px !important"}
        >
          <Controller
            name={"avaibleResearchSites"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.research_site} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("responses_response_initial_data_label_site")}
                  </Text>
                </HStack>
                <Select
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={values()?.question_identifier?.project?.researchSites?.map(
                    (site: any) => ({ label: site.address, value: site.id })
                  )}
                  onChange={(opt) => {
                    onChange(opt);
                  }}
                  styles={customStyles as any}
                  placeholder={"Sorting"}
                  isClearable={false}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={value}
                />
              </>
            )}
          />
        </HStack>

        <HStack
          alignItems={"center"}
          justifyContent={"flex-end"}
          w={"100%"}
          mt={"24px !important"}
        >
          <Controller
            name={"priority"}
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.priority} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("responses_response_initial_data_label_priority")}
                  </Text>
                </HStack>

                <Select
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={priority}
                  onChange={onChange}
                  styles={customStyles as any}
                  placeholder={"Sorting"}
                  isClearable={false}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={priority.find((item) => item.value === value)}
                />
              </>
            )}
          />
        </HStack>

        <HStack
          alignItems={"center"}
          justifyContent={"flex-end"}
          w={"100%"}
          mt={"24px !important"}
        >
          <Controller
            name={"is_profund"}
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.is_profund} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("responses_response_initial_data_label_is_profund")}
                  </Text>
                </HStack>

                <Stack w={"100%"}>
                  <Switch
                    isChecked={value}
                    onChange={() => onChange(!value)}
                    size="lg"
                  />
                </Stack>
              </>
            )}
          />
        </HStack>
      </VStack>
    </HStack>
  );
};

export default InitialData;
