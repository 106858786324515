import _axios from ".";
import { Credentials } from "../providers/contexts/user/types";

const UserService = {
  login: (body: Credentials) => _axios.post("auth/login", body),

  create: (body: any) => _axios.post("user", body),

  update: (body: any) => _axios.put("user", body),

  delete: (id: any) => _axios.delete(`user/${id}`),

  changePassword: (body: any, id: string) => _axios.put(`user/password/${id}`, body),

  getUserData: (endpoint: string) => _axios.get(`user${endpoint}`),
};

export default UserService;
