import { VStack, Text, HStack } from "@chakra-ui/react";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomFieldError from "../../../../../components/base/CustomFieldError";
import TextArea from "./TextArea";
import { ProjectDetailsFormValues, Props } from "./types";

type forcedProps = {
  control: Control<ProjectDetailsFormValues, any>;
};

const ProjectDetails: React.FC<Props> = ({ control }: forcedProps) => {
  const { t } = useTranslation("");

  return (
    <VStack width={"100%"} alignItems={"start"}>
      <Text
        fontSize={"24px"}
        fontWeight={600}
        color={"black"}
        whiteSpace={"nowrap"}
        textAlign={"left"}
      >
        {t("projects_details_title_project_details")}
      </Text>
      <VStack w={"100%"} alignItems={"flex-end"} mt={"36px !important"}>
        <HStack w={"100%"} alignItems={"flex-start"}>
          <Controller
            name={"description"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.description} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("projects_details_label_project_description")}
                  </Text>
                </HStack>
                <TextArea onBlur={onBlur} onChange={onChange} value={value} />
              </>
            )}
          />
        </HStack>

        <HStack w={"100%"} alignItems={"flex-start"} mt={"24px"}>
          <Controller
            name={"notes"}
            control={control}
            rules={{ required: false }}
            render={({
              field: { onBlur, onChange, value },
              formState: { errors },
            }) => (
              <>
                <HStack minW={"100px"} mr={"32px !important"}>
                  <CustomFieldError haveError={!!errors.description} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"black"}
                    whiteSpace={"nowrap"}
                    textAlign={"right"}
                    ml={"auto !important"}
                  >
                    {t("projects_details_label_project_notes")}
                  </Text>
                </HStack>
                <TextArea onBlur={onBlur} onChange={onChange} value={value} />
              </>
            )}
          />
        </HStack>
      </VStack>
    </VStack>
  );
};

export default ProjectDetails;
