import {
  Button,
  useToast,
  VStack,
  Center,
  Text,
  Box,
  HStack,
} from "@chakra-ui/react";
import { useContext, useRef, useState } from "react";
import PageTitle from "../../../components/pageTitle";
import TableWrapper from "../../../components/TableWrapper";
import { IProject, IResearchSiteTags } from "../../../types";
import ProjectForm from "./ProjectForm";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import ProjectService from "../../../services/project.service";
import { InfinitySpin } from "react-loader-spinner";
import { UserContext } from "../../../providers/contexts/user";
import { useTranslation } from "react-i18next";

const ProjectsPage = () => {
  const { user, refreshProjects } = useContext(UserContext);
  const { t } = useTranslation();
  const [currentProject, setCurrentProject] = useState<
    IProject | {} | undefined
  >();

  const saveButtonRef = useRef<any>();

  const fieldsAvaibles = [
    { value: "name", label: t("project_fields_column_name") },
    { value: "is_active", label: t("project_fields_column_active") },
    { value: "short_name", label: t("project_fields_column_short_name") },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      if (values.id) {
        const newP = {
          ...values,
          researchSites: values?.researchSites?.map((site: any) => {
            if (
              site.research_site_tags.length > 0 &&
              "value" in
                (site.research_site_tags[0] as
                  | IResearchSiteTags
                  | { value: string; label: string })
            ) {
              return site;
            } else {
              return {
                ...site,
                research_site_tags: site?.research_site_tags?.map(
                  (research: any) => ({
                    value: research?.id || research.site_tag?.name,
                    label: research?.site_tag?.name,
                    isOld: true,
                  })
                ),
              };
            }
          }),
        };

        await ProjectService.update(newP);
      } else {
        await ProjectService.create({ ...values, created_by: user?.id });
      }
    } catch (err) {
      toast({
        title: "Projects Error",
        description: "An error occurred while trying to handle the project.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setCurrentProject(undefined);
    setIsLoading(false);
    refreshProjects();
  };

  const onDelete = async (id: IProject["id"]) => {
    setIsLoading(true);
    try {
      await ProjectService.delete(id);
    } catch (err) {
      toast({
        title: "Projects Error",
        description: "An error occurred while trying to handle the project.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setCurrentProject(undefined);
    setIsLoading(false);
  };

  return (
    <VStack w={"100%"} height={"100%"}>
      <PageTitle>
        {currentProject ? (
          <HStack>
            <Button
              onClick={() => setCurrentProject(undefined)}
              leftIcon={<HiOutlineArrowSmLeft />}
              variant={"outline"}
            >
              {t("projects_button_back")}
            </Button>

            <Button
              variant={"solid"}
              onClick={() => {
                saveButtonRef.current.click();
              }}
            >
              {t("projects_form_button_save")}
            </Button>
          </HStack>
        ) : (
          <Button onClick={() => setCurrentProject({})}>
            {t("projects_button_create")}
          </Button>
        )}
      </PageTitle>

      <VStack w={"100%"} height={"100%"} position={"relative"}>
        <Box
          w={"100%"}
          maxHeight={currentProject ? 0 : "100%"}
          overflow={currentProject ? "hidden" : "none"}
        >
          <TableWrapper
            endpoint="/project"
            name="Projects"
            fieldsAvaible={fieldsAvaibles}
            onEdit={setCurrentProject}
            currentData={currentProject}
          />
        </Box>

        {isLoading ? (
          <Center
            h={"600px"}
            w={"100%"}
            position={"absolute"}
            zIndex={"2"}
            background={"white"}
            border={"1px solid"}
            borderColor={"container_border_color"}
            borderRadius={"8px"}
          >
            <VStack>
              <InfinitySpin color="#202951" />
              <Text color={"black"} fontSize={"16px"} fontWeight={500}>
                {t("projects_handling_loading")}
              </Text>
            </VStack>
          </Center>
        ) : (
          <ProjectForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            currentProject={currentProject}
            saveButtonRef={saveButtonRef}
          />
        )}
      </VStack>
    </VStack>
  );
};

export default ProjectsPage;
