import { Box, Center, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfinitySpin } from "react-loader-spinner";
import FrameWrapper from "../../../../../components/FrameWrapper";
import CategoryService from "../../../../../services/category.service";
import ProjectService from "../../../../../services/project.service";
import { ICategory, IProject } from "../../../../../types";
import AllCategoriesVisualizer from "./AllCategories";
import CategoryView from "./CategoryView";
import { Props } from "./types";

const CategoryForm: React.FC<Props> = ({ showCategoryForm }) => {
  const { t } = useTranslation();
  const [currentCategory, setCurrentCategory] = useState<
    ICategory | undefined | {}
  >(undefined);

  const [AllCategories, setAllCategories] = useState<ICategory[]>([]);
  const [AllProjects, setAllProjects] = useState<IProject[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const populateNecessaryData = async () => {
    setIsLoading(true);
    try {
      const categories = (await CategoryService.getAllCategories())?.data;
      setAllCategories(categories);

      const projects = (await ProjectService.getProjectsWithFilter())?.data
        ?.result;
      setAllProjects(projects);
    } catch (err) {
      toast({
        title: t("category_data_error_title"),
        description: t("category_data_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (showCategoryForm) {
      populateNecessaryData();
    }
  }, [showCategoryForm]);

  const onSaveCategory = async (categoryValues: any) => {
    try {
      if ("id" in categoryValues) {
        await CategoryService.update(
          {
            ...categoryValues,
            project_id: categoryValues?.projectSelected?.value,
          },
          categoryValues.project_id !== categoryValues.projectSelected.value
            ? categoryValues.project_id
            : undefined
        );
      } else {
        await CategoryService.create({
          ...categoryValues,
          project_id: categoryValues?.projectSelected?.value,
        });
      }
    } catch (err) {
      toast({
        title: t("category_data_error_title"),
        description: t("category_data_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    populateNecessaryData();
  };

  const onDeleteCategory = async (id: string) => {
    try {
      await CategoryService.delete(id);
    } catch (err) {
      toast({
        title: t("category_data_error_title"),
        description: t("category_data_error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setCurrentCategory(undefined);
    populateNecessaryData();
  };

  return (
    <VStack
      position={"absolute"}
      maxH={showCategoryForm ? "100%" : "0%"}
      overflow={showCategoryForm ? "auto" : "hidden"}
      transition={"500ms all"}
      width={"100%"}
      zIndex={"2"}
      background={"#F9FBFB"}
    >
      <HStack w={"100%"} h={"100%"} alignItems={"flex-start"}>
        <Box flex={0.6}>
          <FrameWrapper>
            {isLoading ? (
              <Center w={"100%"} h={"300px"}>
                <InfinitySpin color="#202951" />
              </Center>
            ) : (
              <>
                {AllCategories && AllProjects ? (
                  <AllCategoriesVisualizer
                    onSelectCategory={(category) =>
                      setCurrentCategory(category)
                    }
                    onCreateCategory={() => setCurrentCategory({})}
                    projects={AllProjects}
                    categories={AllCategories}
                  />
                ) : (
                  <Text>{t("categories_not_found")}</Text>
                )}
              </>
            )}
          </FrameWrapper>
        </Box>

        <Box flex={0.4}>
          <FrameWrapper>
            <CategoryView
              projects={AllProjects}
              onSaveCategory={onSaveCategory}
              currentCategory={currentCategory}
              onDeleteCategory={onDeleteCategory}
            />
          </FrameWrapper>
        </Box>
      </HStack>
    </VStack>
  );
};

export default CategoryForm;
