import { Box, Button, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/pageTitle";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import CategoryTable from "./CategoryTableWrapper";
import CategoryForm from "./CategoryTableWrapper/CategoryForm";

const CategoriesPage: React.FC = () => {
  const [showEditCategories, setShowEditCategories] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <VStack w={"100%"} height={"100%"}>
      <PageTitle>
        {showEditCategories ? (
          <Button
            onClick={() => setShowEditCategories(false)}
            leftIcon={<HiOutlineArrowSmLeft />}
            variant={"outline"}
          >
            {t("category_back_button")}
          </Button>
        ) : (
          <Button onClick={() => setShowEditCategories(true)}>
            {t("category_edit_category_button")}
          </Button>
        )}
      </PageTitle>

      <VStack w={"100%"} height={"100%"} position={"relative"}>
        <Box
          w={"100%"}
          maxHeight={showEditCategories ? 0 : "100%"}
          overflow={showEditCategories ? "hidden" : "none"}
        >
          <CategoryTable categoryHasEditted={showEditCategories} />
        </Box>
        <CategoryForm showCategoryForm={showEditCategories} />
      </VStack>
    </VStack>
  );
};

export default CategoriesPage;
