import _axios from ".";

const ReportService = {
  getCategoryXTag: (site_descriptors: string[], query?: any) =>
    _axios.post(`report/categoryxtag`, { site_descriptors }, { params: query }),

  getRegionXTag: (site_descriptors: string[], query?: any) =>
    _axios.post(`report/regionxtag`, { site_descriptors }, { params: query }),

  getTopTags: (site_descriptors: string[], query?: any) =>
    _axios.post(`report/toptags`, { site_descriptors }, { params: query }),

  getTagCloud: (site_descriptors: string[], query?: any) =>
    _axios.post(`report/tagcloud`, { site_descriptors }, { params: query }),
};

export default ReportService;
