import { HStack, Image, Text } from "@chakra-ui/react";
import knowmadLogo from "../../assets/images/futures-logo.svg";
import useDeviceDetection from "../../hooks/IsMobile";

const LoggedOutHeader: React.FC = () => {
  const { isMobile } = useDeviceDetection();
  return (
    <HStack
      width={"100%"}
      padding={"18px 16px"}
      background={"primary"}
      justifyContent={"space-between"}
      borderRadius={isMobile ? "0" : "16px 16px 0px 0px"}
    >
      <Text
        fontWeight={500}
        fontSize={"24px"}
        color={"white"}
        fontFamily={`'Roboto Serif', serif`}
      >
        project administration
      </Text>
      <Image src={knowmadLogo} width={"74px"} />
    </HStack>
  );
};

export default LoggedOutHeader;
