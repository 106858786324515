import {
  HStack,
  VStack,
  Text,
  Button,
  Grid,
  GridItem,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InitialDataFormValues, Props } from "./types";

type forcedProps = {
  control: Control<InitialDataFormValues, any>;
  values: () => InitialDataFormValues;
  watch: any;
};

const Attachments: React.FC<Props> = ({
  control,
  values,
  watch,
}: forcedProps) => {
  const { t } = useTranslation();
  const [filesToRead, setFilesToRead] = useState(values().attachments || []);
  const handleClick = () => {
    const inputfile = document.getElementById("inputphoto") as HTMLInputElement;
    inputfile.click();
  };

  useEffect(() => {
    setFilesToRead(values().attachments);
    const fileReaders: any = [];
    let isCancel = false;
    if (values()?.attachments?.length) {
      const promises = values()?.attachments?.map((file) => {
        return new Promise((resolve, reject) => {
          if (file.content) {
            resolve(file);
          }

          const fileReader = new FileReader();
          fileReaders.push(fileReader);
          fileReader.onload = (e) => {
            const result = e.target?.result;
            if (result) {
              resolve(result);
            }
          };
          fileReader.onabort = () => {
            reject(new Error("File reading aborted"));
          };
          fileReader.onerror = () => {
            reject(new Error("Failed to read file"));
          };
          fileReader.readAsDataURL(file as any);
        });
      });
      Promise.all(promises)
        .then((images: any) => {
          if (!isCancel) {
            setFilesToRead(images);
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader: any) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [watch, values]);

  return (
    <VStack width={"100%"} alignItems={"flex-start"}>
      <HStack w={"100%"} justifyContent={"space-between"}>
        <Text
          fontSize={"24px"}
          fontWeight={600}
          color={"black"}
          whiteSpace={"nowrap"}
          textAlign={"right"}
        >
          {t("responses_attachments_label_media")}
        </Text>

        <Button onClick={handleClick}>
          {t("responses_attachments_label_add_image")}
        </Button>
      </HStack>

      <VStack mt={"36px !important"} w={"100%"} alignItems={"flex-start"}>
        <Controller
          name={"attachments"}
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <>
              <Text
                fontSize={"16px"}
                fontWeight={400}
                color={"#414558"}
                whiteSpace={"nowrap"}
                textAlign={"right"}
              >
                {value?.length} {t("responses_attachments_label_images")}
              </Text>

              <Grid w={"100%"} templateColumns={"1fr 1fr"} gap={"8px"}>
                {filesToRead?.map((attachment, index) => (
                  <GridItem w={"100%"} h={"108px"}>
                    <Image
                      w={"100%"}
                      h={"100%"}
                      objectFit={"cover"}
                      src={attachment?.content || (attachment as any)}
                      borderRadius={"12px"}
                    />
                  </GridItem>
                ))}
              </Grid>

              <input
                onChange={(e) => {
                  const file = (e as any)?.target?.files[0];
                  if (file) {
                    const newValue: any = value;
                    newValue.push(file);
                    onChange(newValue);
                  }
                }}
                id="inputphoto"
                style={{ display: "none" }}
                type="file"
                name="file"
                accept="image/*"
              />
            </>
          )}
        />
      </VStack>
    </VStack>
  );
};

export default Attachments;
