/* eslint-disable spaced-comment */
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";

type Props = {
  nextLabel: string;
  previousLabel: string;
  onPageChange: (page: number) => void;
  pageCount: number;
};

export const Paginate: React.FC<Props> = (props: Props) => {
  const { pageCount } = props;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        padding: 0,
        flex: 1,
        display: "flex",
        listStyle: "none",
      },
      previousLabel: {
        marginRight: "auto",
      },
      nextLabel: {
        marginLeft: "auto",
      },
      page: {
        cursor: "pointer",
        marginRight: "8px",
      },
      active: {
        color: "#9A9CAB",
      },
    })
  );

  const styles = useStyles();

  return (
    <ReactPaginate
      breakLabel="..."
      onPageChange={({ selected }) => props.onPageChange(selected)}
      nextClassName={styles.nextLabel}
      previousClassName={styles.previousLabel}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      containerClassName={styles.container}
      pageClassName={styles.page}
      activeClassName={styles.active}
      previousLabel={props.previousLabel}
      nextLabel={props.nextLabel}
    />
  );
};

export default Paginate;
