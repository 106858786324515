/* eslint-disable indent */
import React, { useState, useRef, useEffect } from "react";
import { Box, Icon, Text } from "@chakra-ui/react";
import {
  StyledInput,
  StyledBoxInput,
  StyledPlaceHolder,
  StyledCounter,
  StyledOutsideComponent,
  StyledIconContainer,
} from "./styles";
import { Props } from "./types";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export const Input: React.FC<Props> = (props: Props) => {
  const { type, hasError, errorMessage, maxCharacters } = props;
  const [inFocus, setInFocus] = useState(false);
  const [characterCounter, setCharacterCounter] = useState(0);
  const [inputType, setInputType] = useState(
    props.type === "password" ? props.type : "text"
  );

  const input = useRef<any>();
  const newPlaceHolder = props.placeholder;

  useEffect(() => {
    setInFocus(!!props.value);
  }, [props.value]);

  useEffect(() => {
    setInFocus(!!input.current.value);
  }, [input]);

  const statePlaceHolder = (value: any) => {
    setInFocus(!!value);
  };

  const adjustCharacters = (event: any) => {
    event.target.value = event.target.value.substr(0, maxCharacters);
    setCharacterCounter(event.target.value.length);
  };

  return (
    <StyledOutsideComponent {...props}>
      <StyledBoxInput>
        <StyledPlaceHolder
          inInput={inFocus}
          onClick={() => {
            input.current.focus();
          }}
        >
          <Text
            color={
              props.disabled
                ? "error"
                : hasError && inFocus
                ? "error"
                : inFocus
                ? "black"
                : "#4D4D4D"
            }
            fontSize={inFocus ? "12px" : "16px"}
            fontWeight={"400"}
          >
            {props.placeholder}
          </Text>
        </StyledPlaceHolder>
        {maxCharacters && (
          <StyledCounter>
            <Text
              color={"#4D4D4D"}
              variant={"Poppins"}
              fontSize={"12px"}
              fontWeight={"400"}
            >
              {characterCounter / maxCharacters}{" "}
            </Text>
          </StyledCounter>
        )}

        {type === "password" && (
          <StyledIconContainer>
            <Icon
              as={
                inputType === "password" ? AiOutlineEye : AiOutlineEyeInvisible
              }
              onClick={() => {
                setInputType(inputType === "password" ? "text" : "password");
              }}
              width={"16px !important"}
            />
          </StyledIconContainer>
        )}

        <StyledInput
          {...props}
          ref={input}
          type={inputType}
          placeholder={""}
          inInput={inFocus}
          disabled={props.disabled}
          readOnly={type === "date"}
          placeHolder={newPlaceHolder}
          onFocus={() => setInFocus(true)}
          value={props.value}
          onBlur={() => setInFocus(!!input.current.value)}
          onChange={(event: any) => {
            statePlaceHolder(event.target.value);
            maxCharacters && adjustCharacters(event);
          }}
          onKeyPress={(event: React.KeyboardEvent) => {
            if (event.key === "Enter" && props?.handlePressEnter) {
              props.handlePressEnter();
            }
          }}
        />
      </StyledBoxInput>

      {hasError && errorMessage && (
        <Box
          marginTop={"4px"}
          justifyContent={"left"}
          alignContent={"center"}
          width={"100%"}
        >
          <Text fontSize={"14px"} marginLeft={"4px"} color={"error"}>
            {errorMessage}
          </Text>
        </Box>
      )}
    </StyledOutsideComponent>
  );
};
