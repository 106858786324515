import React, { useState, useEffect, useRef } from "react";
import { Icon, Image } from "@chakra-ui/react";
import {
  StyledSelect,
  StyledPlaceHolder,
  StyledBoxSelect,
  StyledDropdown,
  StyledItem,
} from "./styles";
import { IoIosArrowDown } from "react-icons/io";

import { Props } from "./types";
import { Text } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import listenForOutsideClick from "./listen-for-outside-clicks";
import { useTranslation } from "react-i18next";

export const SelectWModal: React.FC<Props> = (props: Props) => {
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { t } = useTranslation();
  const { items, placeholder, SelectItem, itemSelected, hasError } = props;

  useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen));
  const renderItemDesktop = (item: any, index: number) => {
    const selectItem = props.convertItem ? props.convertItem(item) : item;

    return (
      <StyledItem
        key={index}
        isSelected={false}
        onClick={() => {
          setIsOpen(false);
          SelectItem(selectItem, index);
        }}
        id={`slci_${selectItem?.value?.toLowerCase().replaceAll(" ", "")}`}
      >
        {selectItem.src && (
          <Image
            src={selectItem.src}
            alt={selectItem.value || "Ícone de seleção"}
            width={"24px"}
            marginRight={"8px"}
          />
        )}
        <Text fontSize={"16px"} color={"#221F1D"}>
          {selectItem.label}
        </Text>
      </StyledItem>
    );
  };

  return (
    <StyledBoxSelect {...props} ref={menuRef}>
      <StyledSelect
        itemHasSelected={itemSelected}
        isOpen={isOpen}
        hasError={hasError}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <StyledPlaceHolder itemSelected={itemSelected}>
          <Box>
            {(props?.src || itemSelected?.src) && (
              <Image
                src={itemSelected.src || props.src}
                alt={placeholder || "Ícone de seleção"}
                width={"24px"}
                marginRight={"8px"}
              />
            )}

            <Box display={"flex"} flexDirection={"column"}>
              <Text
                fontSize={itemSelected ? "12px" : "16px"}
                color={itemSelected ? "#000000" : "#55555D"}
              >
                {placeholder || "Selecione"}
              </Text>

              {itemSelected && (
                <Text fontSize={"16px"} color={"#55555D"} id={props?.id}>
                  {itemSelected?.label || ""}
                </Text>
              )}
            </Box>
          </Box>
        </StyledPlaceHolder>
        <Icon
          as={IoIosArrowDown}
          size={"10px"}
          mr={"24px"}
          right={"0px"}
          top={"18px"}
          position={"absolute"}
          transform={isOpen ? "rotate(0.5turn)" : ""}
          transition={"300ms all"}
        />
        <StyledDropdown isOpen={isOpen}>
          {items.map(renderItemDesktop)}
        </StyledDropdown>
      </StyledSelect>

      {hasError && (
        <Box
          marginTop={"4px"}
          justifyContent={"left"}
          alignContent={"center"}
          width={"calc(100% + -16px)"}
        >
          <Text fontSize={"14px"} marginLeft={"4px"} color={"error"}>
            {t("required_field_error")}
          </Text>
        </Box>
      )}
    </StyledBoxSelect>
  );
};
