import { HStack, VStack, Text, Icon, Center } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Input } from "../../../../../../../components/base/Input";
import { Controller, useForm } from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";
import Select from "react-select";
import { Props } from "./types";
import { GrFormClose } from "react-icons/gr";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: "pointer",
    background: state.isFocused ? "#202951" : "white",
    color: state.isFocused ? "white" : "#000000",
  }),
  control: (base: any) => ({
    display: "flex",
    border: "1px solid #F0F3F5",
    cursor: "pointer",
    background: "white",
    width: "170px",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

const QuestionForm: React.FC<Props> = ({
  avaibleLanguages,
  currentQuestion,
  handleChange,
  fieldsError,
  isPrimary,
  onDelete,
  isFirst,
}) => {
  const { control, reset } = useForm({
    defaultValues: useMemo(() => {
      return { ...currentQuestion };
    }, [currentQuestion]),
  });

  useEffect(() => {
    if (currentQuestion) {
      reset(currentQuestion);

      if (!("id" in currentQuestion)) {
        reset({});
      }
    }
  }, [currentQuestion, reset]);

  return (
    <VStack w={"100%"}>
      {isPrimary ? (
        <VStack w={"100%"} mt={"24px !important"}>
          <Controller
            name={"lang"}
            control={control}
            rules={{ required: true }}
            render={({ field: { value }, formState: { errors } }) => (
              <VStack mb={"24px !important"} w={"100%"}>
                <HStack w={"100%"}>
                  <Text mr={"32px !important"}>Question language</Text>
                  <Select
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    options={avaibleLanguages}
                    onChange={(event) =>
                      handleChange({ ...currentQuestion, lang: event?.value })
                    }
                    styles={customStyles}
                    placeholder={"Language"}
                    isClearable={false}
                    value={avaibleLanguages.find(
                      (avaible) => avaible.value === value
                    )}
                  />

                  <Center
                    width={"36px"}
                    minH={"36px"}
                    borderRadius={"4px"}
                    ml={"auto !important"}
                    mr={"8px !important"}
                    cursor={"pointer"}
                    border={"1px solid"}
                    borderColor={"container_border_color"}
                    transition={"300ms all"}
                    _hover={{
                      bg: "container_border_color",
                    }}
                    onClick={() => onDelete(currentQuestion.index)}
                  >
                    <Icon as={GrFormClose} width={"20px"} />
                  </Center>
                </HStack>
                {fieldsError?.select && (
                  <HStack mt={"16px !important"} w={"100%"}>
                    <Icon
                      as={FiAlertCircle}
                      color={"delete_color"}
                      w={"14px"}
                      mr={"8px !important"}
                    />
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"delete_color"}
                      m={"0px !important"}
                    >
                      {"You need to add some language to the question."}
                    </Text>
                  </HStack>
                )}
              </VStack>
            )}
          />

          <Controller
            name={"title"}
            control={control}
            rules={{ required: true }}
            render={({ field: { value }, formState: { errors } }) => (
              <Input
                height={"104px"}
                m={"0px !important"}
                placeholder="Question"
                value={value || ""}
                onChange={(event) =>
                  handleChange({
                    ...currentQuestion,
                    title: event.target.value,
                  })
                }
              />
            )}
          />

          {fieldsError?.input && (
            <HStack mt={"16px !important"} w={"100%"}>
              <Icon
                as={FiAlertCircle}
                color={"delete_color"}
                w={"14px"}
                mr={"8px !important"}
              />
              <Text
                fontSize={"14px"}
                fontWeight={400}
                color={"delete_color"}
                m={"0px !important"}
              >
                {"You need to add some text to the question."}
              </Text>
            </HStack>
          )}
        </VStack>
      ) : (
        <VStack
          mt={isFirst ? "0px" : "24px !important"}
          width={"100%"}
          alignItems={"left"}
          paddingBottom={"24px"}
          borderBottom={"1px solid"}
          borderColor={"#F0F3F5"}
        >
          <Text
            fontSize={"12px"}
            fontWeight={400}
            color={"#2B363B"}
            m={"0px !important"}
          >
            Question
          </Text>

          {"title" in currentQuestion && (
            <Text
              fontSize={"12px"}
              fontWeight={400}
              color={"#2B363B"}
              m={"0px !important"}
            >
              {currentQuestion.title}
            </Text>
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default QuestionForm;
