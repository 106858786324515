const czTranslation = {
  page_in_development: "Tato stránka je stále ve vývoji. =)",
  on_copy_qr_code_title: "URL qr kódu zkopírována",
  choose_a_project_label: "Vyberte projekt",
  loading: "načítání...",

  /* Přehledy */
  category_x_tag_loading: "Počkejte, načítáme váš graf.",
  report_data_not_found: "Pro tento graf nebyla nalezena žádná data.",
  report_tag_counter: "Značka (počet)",
  report_tag_sum: "Značka (součet)",
  report_grand_sumary: "celkový",
  all_questions_placeholder: "všechny otázky",
  all_sites_placeholder: "Všechny weby tohoto projektu",
  all_sites_descriptors_placeholder: "Todas as descrições de sites",

  /* Obálka stolu */
  project_fields_column_name: "Název",
  project_fields_column_active: "Aktivní",
  project_fields_column_short_name: "Krátký název",

  responses_fields_column_submitter: "Odesilatel",
  responses_fields_column_is_profund: "Je hluboký",
  responses_fields_column_short_priority: "Priorita",
  responses_fields_column_short_text: "Odpověď",

  table_label_not_found_data: "Nebyla nalezena žádná data.",
  table_button_export: "Exportovat",
  table_button_edit: "Upravit",

  /* Základní komponenty */
  quick_search_place_holder: "Rychlé vyhledávání",
  paginate_next: "Další",
  paginate_previous: "Zpět",

  /* Přihlašovací obrazovka */
  login_screen_secondary_text:
    "Zadejte své přihlašovací údaje pro přístup k vašemu účtu.",
  login_screen_email_placeholder: "E-mailem",
  login_screen_password_placeholder: "Heslo",
  login_screen_language_placeholder: "Jazyk",
  login_screen_button_placeholder: "Přihlásit se",

  /* Navigační lišta */
  nav_bar_dashboard: "přístrojová deska",
  nav_bar_world_cafe: "SVĚTOVÁ KAVÁRNA",
  nav_bar_world_cafe_secondary: "Světová kavárna",
  nav_bar_projects: "Projekty",
  nav_bar_response_data: "Data odezvy",
  nav_bar_categories: "přiřadit kategorie",
  nav_bar_tags_and_tagged_data: "Značky a označená data",
  nav_bar_reports: "ZPRÁVY",
  nav_bar_reports_secondary: "Zprávy",
  nav_bar_tags: "Značky",
  nav_bar_categories_x_tags: "Kategorie x značky",
  nav_bar_site_descriptors_x_tags: "Deskriptory stránek x značky",
  nav_bar_tag_cloud: "štítek mrak",
  nav_bar_user: "",
  nav_bar_settings: "Nastavení",
  nav_bar_logout: "Odhlásit se",

  /* Projekt */
  projects_button_back: "Zpět",
  projects_button_create: "Vytvořit projekt",
  projects_handling_loading: "Počkejte prosím, zpracováváme váš projekt.",
  projects_form_button_delete: "Smazat projekt",
  project_form_button_save: "Uložit změny",

  project_form_initial_data_label_input_name: "Název",
  project_form_initial_data_label_input_short_name: "Krátký název",
  projects_form_initial_data_label_input_short_coordinator: "Koordinátor",
  projects_form_initial_data_label_input_short_status: "Aktivní",
  projects_form_initial_data_label_region: "jméno stránky",
  projects_form_initial_data_label_qr_code_copy_URL: "zkopírovat url",
  projects_research_site_site_placeholder_site_name: "Název webu",
  projects_research_site_site_placeholder_coordinator: "Koordinátor",
  projects_research_site_site_placeholder_address: "Adresa",
  projects_research_site_site_placeholder_region: "Region",
  projects_research_site_site_placeholder_language: "Jazyk",
  projects_form_initial_data_label_created: "Vytvořeno",
  projects_form_initial_data_label_site: "kraj",
  projects_form_initial_data_label_qr_code:
    "Abyste získali Qr kód, musíte nejprve vygenerovat projekt a jeho regiony.",
  projects_form_initial_data_label_project_color: "Barva projektu",

  projects_form_initial_data_place_holder_input_name: "Název projektu",
  projects_form_initial_data_place_holder_input_coordinator:
    "Koordinátor projektu",
  projects_form_initial_data_place_holder_input_short_name:
    "Krátký název projektu",
  projects_form_initial_data_button_yes: "Ano",
  projects_form_initial_data_button_no: "Ne",

  projects_details_title_project_details: "Podrobnosti projektu",
  projects_details_label_project_description: "Popis",
  projects_details_label_project_notes: "Poznámky",

  projects_question_identifier_title_questions: "Otázky",
  projects_question_identifier_button_create_question: "Vytvořit otázku",
  projects_question_identifier_label_column_question: "Otázka",
  projects_question_identifier_button_edit_question: "Upravit",
  projects_question_identifier_button_delete_question: "Smazat",
  projects_question_identifier_label_questions_not_found:
    "Zatím nejsou zaregistrovány žádné dotazy.",
  projects_question_identifier_label_questions_not_found_in_search:
    "Pro toto vyhledávání nebyly nalezeny žádné otázky.",

  projects_question_identifier_question_label_create_question:
    "Vytvořit otázku",
  projects_question_identifier_question_label_edit_question: "Upravit otázku",
  projects_question_identifier_question_label_no_languages_designed:
    "Dosud nebyly navrženy žádné jazyky.",
  projects_question_identifier_question_label_add_language:
    "Přidat v jiném jazyce",
  projects_question_identifier_question_label_add_first_language:
    "Přidat svůj první jazyk",

  projects_research_site_title_site: "Výzkumné weby",
  projects_research_site_button_create_site: "Vytvořit web",
  projects_research_site_label_column_site: "Web",
  projects_research_site_label_column_lang: "Jazyk",
  projects_research_site_label_column_coordinator: "Koordinátor",
  projects_research_site_button_edit: "Upravit",
  projects_research_site_button_delete: "Smazat",
  projects_research_site_label_not_found:
    "Zatím nejsou zaregistrovány žádné stránky.",
  projects_research_site_label_not_found_in_search:
    "Pro toto vyhledávání nebyly nalezeny žádné stránky.",

  projects_research_site_site_label_create_site: "Vytvořit web",
  projects_research_site_site_label_edit_site: "Upravit web",
  projects_research_site_site_label_save: "Uložit",

  errors_empty_fields_title: "Chyba prázdných polí.",
  errors_empty_fields_description:
    "Není možné uložit odpověď s prázdnými poli.",
  errors_add_language: "Musíte přidat alespoň jeden jazyk.",
  errors_name: "Musíte přidat alespoň jedno jméno.",
  errors_coordinator: "Musíte přidat alespoň jednoho koordinátora.",
  errors_region: "Musíte přidat alespoň jednu oblast.",
  errors_address: "Musíte přidat alespoň jednu adresu.",
  errors_language: "Musíte přidat alespoň jeden jazyk.",
  errors_empty_field: "Toto pole nemůže být prázdné.",
  errors_different_passwords: "Heslo se musí rovnat.",
  errors_invalid_email: "E-mail je neplatný.",

  /* Odpovědi */
  responses_handling_loading: "Počkejte prosím, zpracováváme vaši odpověď.",
  responses_response_data_error_title: "Chyba odpovědi",
  responses_response_data_error_description:
    "Při pokusu o zpracování odpovědi došlo k chybě.",

  responses_form_button_delete: "Smazat odpověď",
  responses_form_button_save: "Uložit odpověď",

  responses_response_details_project_label: "Projekt",
  responses_response_details_question_label: "Otázka",
  responses_response_details_response_label: "Odpověď",
  responses_response_details_notes_label: "Poznámky",
  responses_response_details_tags_label: "Značky",

  responses_response_initial_data_priority_low: "Nízká",
  responses_response_initial_data_priority_medium: "Střední",
  responses_response_initial_data_priority_high: "Vysoká",

  responses_response_initial_data_label_project: "Projekt",
  responses_response_initial_data_label_submitter: "Odesilatel",
  responses_response_initial_data_label_site: "Web",
  responses_response_initial_data_label_priority: "Priorita",
  responses_response_initial_data_label_is_profund: "Důležité",

  responses_attachments_label_media: "Média",
  responses_attachments_label_add_image: "Přidat obrázek",
  responses_attachments_label_images: "obrázky",

  /* Nastavení */
  table_user_error_title: "Chyba uživatele",
  table_user_error_description:
    "Při pokusu o načtení uživatelských dat došlo k chybě.",

  table_user_handling_error_title: "Uživatel při zpracování chyb",
  table_user_handling_error_description:
    "Při pokusu o manipulaci s uživatelem došlo k chybě.",

  password_changed_with_succes: "Heslo úspěšně změněno!",
  password_changed_with_error: "Staré heslo špatné",

  user_name: "Jméno",
  user_email: "E-mail",
  user_created_at: "Vytvořeno v",
  user_create: "Vytvořit uživatele",

  edit_user_option_user: "Uživatel",
  edit_user_option_admin: "Správce",

  edit_user_placeholder_name: "Jméno",
  edit_user_placeholder_password: "Heslo",
  edit_user_placeholder_repassword: "Potvrdit heslo",
  edit_user_placeholder_email: "E-mail",
  edit_user_placeholder_profile_code: "Funkce",
  edit_user_placeholder_profile_is_active: "Aktivní",

  edit_user_button_delete_user: "Smazat uživatele",
  edit_user_button_update_user: "Aktualizovat uživatele",
  edit_user_button_create_user: "Vytvořit uživatele",

  change_password_label_old_password: "Staré heslo",
  change_password_placeholder_old_password: "Heslo",

  change_password_label_new_password: "Nové heslo",
  change_password_placeholder_new_password: "Heslo",

  change_password_text_min_caracters: "Minimálně 8 znaků",

  change_password_label_repassword: "Potvrdit heslo",
  change_password_placeholder_repassword: "Heslo",

  change_password_button_change: "Změnit heslo",

  settings_nav_item_password: "Heslo",
  settings_nav_item_users: "Uživatelé",

  /* Kategorie */
  category_create_success: "Byla vytvořena nová kategorie!",
  category_create_error: "Při vytváření kategorie došlo k chybě.",
  category_back_button: "Zpět",
  category_edit_category_button: "Upravit kategorie",

  category_table_column_project: "Otázky",
  category_table_column_catName: "Název kategorie",
  category_table_column_tag: "Značka",
  category_table_column_response: "Odpověď",

  category_error_table_data_title: "Chyba kategorií",
  category_error_table_data_description:
    "Při získávání dat kategorie došlo k chybě.",

  category_table_column_modal_text_response: "Odpověď",
  category_table_column_modal_text_active_project: "Otázky",
  category_table_column_modal_text_category_name: "Název kategorie",

  category_error_table_data_response_title: "Chyba odpovědi.",
  category_error_table_data_response_description:
    "Interní chyba serveru při pokusu o odpověď na aktualizaci.",

  category_uncategorized: "Nekategorizováno",
  category_table_tag_title: "Značky",
  category_table_tag_placeholder: "Značky",

  category_table_tag_view_response: "Upravit odpověď",
  category_table_button_save: "Uložit",

  category_data_error_title: "Chyba kategorie",
  category_data_error_description:
    "Interní chyba serveru při pokusu o zpracování kategorií.",

  category_not_found: "Nebyly nalezeny žádné kategorie.",

  category_category_view_empty_text:
    "Zde vyberte kategorii, kterou chcete zobrazit.",

  category_category_view_label_project: "Projekt",
  category_category_view_placeholder_project: "Projekt",
  categories_category_view_empty_text:
    "Zde vyberte kategorii, kterou chcete zobrazit.",

  category_category_view_label_name: "Název",
  category_category_view_placeholder_name: "Název kategorie",

  category_category_view_button_delete: "Smazat kategorii",
  category_category_view_button_save: "Uložit kategorii",

  category_all_categories_first_text: "Vytvořené kategorie",
  category_all_categories_column_project: "Projekt",
  category_all_categories_column_name: "Název",
  category_all_categories_column_created_at: "Vytvořeno v",

  category_all_categories_button_edit: "Upravit",
  category_all_categories_button_add_more_categories:
    "Přidat ještě jednu kategorii",

  category_all_categories_text_creation: "Vytvoření kategorie",
  category_all_categories_text_edit: "Úpravy kategorií",
  category_table_tag_view_response_show_more: "zobrazit více",
  category_table_tag_view_response_show_less: "Ukaž méně",
};

export default czTranslation;
