import { HStack, VStack, Text, Image } from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ItemContent from "./ItemContent";
import NavItems from "./consts";
import knowmadLogo from "../../assets/images/futures-logo.svg";
import { UserContext } from "../../providers/contexts/user";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
  onClickItem?: () => void;
  onClickClose?: () => void;
};

const Navbar: React.FC<Props> = ({
  children,
  onClickClose,
  onClickItem,
}: Props) => {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const onClickNav = {
    LOGOUT: () => logout(),
  };

  return (
    <HStack w={"100%"} h={"100%"}>
      <VStack
        width={"255px"}
        background={"primary"}
        height={"100%"}
        padding={"24px 8px"}
      >
        <Image src={knowmadLogo} width={"122px"} mb={"48px"} />

        <VStack width={"100%"}>
          <>
            {NavItems.map((value, index) => {
              return (
                <HStack
                  key={index}
                  width={"100%"}
                  margin={"0px !important"}
                  borderRadius={"4px"}
                  onClick={() => {
                    onClickItem && onClickItem();
                    !value.subItems && navigate(value.route);
                  }}
                >
                  {value.subItems ? (
                    <VStack
                      width={"100%"}
                      borderTop={index !== 0 ? "1px solid white" : ""}
                      alignItems={"start"}
                      height={"100%"}
                    >
                      <Text
                        ml={"16px !important"}
                        mt={"16px !important"}
                        mb={"8px !important"}
                        fontWeight={600}
                        fontSize={"12px"}
                        color={
                          location.pathname.includes(value.route)
                            ? "white"
                            : "placeholder_color"
                        }
                        textAlign={"left"}
                        flexWrap={"nowrap"}
                        whiteSpace={"nowrap"}
                      >
                        {t(`nav_bar_${value.label}`)}
                      </Text>

                      {value.subItems.map((subItemValue, index) => (
                        <ItemContent
                          key={index}
                          icon={subItemValue.icon}
                          label={t(`nav_bar_${subItemValue.label}`)}
                          isActive={
                            value.route + subItemValue.route ===
                            location.pathname
                          }
                          isLast={value.subItems.length === index + 1}
                          onClick={() => {
                            onClickItem && onClickItem();
                            subItemValue.onClickID
                              ? onClickNav[
                                  subItemValue.onClickID as keyof typeof onClickNav
                                ]()
                              : navigate(value.route + subItemValue.route);
                          }}
                        />
                      ))}
                    </VStack>
                  ) : (
                    <ItemContent
                      icon={(value as any).icon}
                      label={t(`nav_bar_${value.label}`)}
                      isActive={value.route === location.pathname}
                      isLast={true}
                    />
                  )}
                </HStack>
              );
            })}
          </>
        </VStack>
      </VStack>
      <VStack
        flex={1}
        height={"100%"}
        margin={"0 !important"}
        justifyContent={"flex-start"}
      >
        {children}
      </VStack>
    </HStack>
  );
};

export default Navbar;
