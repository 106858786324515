import { Center, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <Center w={"100%"} h={"100%"}>
      <Text fontSize={"24px"} fontWeight={500} color={"black_secondary"}>
        {t("page_in_development")}
      </Text>
    </Center>
  );
};

export default Dashboard;
