import { MdOutlineLayers, MdChatBubbleOutline } from "react-icons/md";

import { RiMapPinLine, RiSettings3Line } from "react-icons/ri";
import { TbTag, TbLogout, TbClipboardText } from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { AiOutlineCloud } from "react-icons/ai";

const NavItems = [
  {
    label: "world_cafe",
    secondaryLabel: "world_cafe_secondary",
    route: "/overview",
    subItems: [
      {
        icon: MdOutlineLayers,
        label: "projects",
        route: "/projects",
        onClickID: "",
      },
      {
        icon: MdChatBubbleOutline,
        label: "response_data",
        route: "/responses",
      },
      {
        icon: TbClipboardText,
        label: "categories",
        route: "/categories",
      },
    ],
  },
  {
    label: "reports",
    secondaryLabel: "reports_secondary",
    route: "/reports",
    subItems: [
      {
        icon: TbTag,
        label: "tags",
        route: "/tags",
      },
      {
        icon: AiOutlineCloud,
        label: "tag_cloud",
        route: "/tagcloud",
      },
      {
        icon: BiCategory,
        label: "categories_x_tags",
        route: "/categories",
      },
      {
        icon: RiMapPinLine,
        label: "site_descriptors_x_tags",
        route: "/sitedescriptors",
      },
    ],
  },
  {
    label: "user",
    secondaryLabel: "user_secondary",
    route: "/user",
    subItems: [
      {
        icon: RiSettings3Line,
        label: "settings",
        route: "/settings",
      },
      {
        icon: TbLogout,
        label: "logout",
        route: "",
        onClickID: "LOGOUT",
      },
    ],
  },
];

export default NavItems;
