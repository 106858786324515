import _axios from ".";

const CategoryService = {
  getAllCategories: () => _axios.get("category"),

  create: (body: { name: string; project_id: string }) =>
    _axios.post("category", body),

  delete: (id: string) => _axios.delete(`category/${id}`),

  update: (
    newCategory: { id: string; project_id: string; name: string },
    isProjectUpdate: boolean
  ) => _axios.put(`category/${isProjectUpdate}`, newCategory),
};

export default CategoryService;
